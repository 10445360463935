import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';

function GoalsTab() {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
    const [tabsData, setTabsData] = useState(
        [
            {
                label: 'Individual Goals',
                content:
                    'Ut irure mollit nulla eiusmod excepteur laboris elit sit anim magna tempor excepteur labore nulla.',
            },
            {
                label: 'Group Goals',
                content:
                    'Fugiat dolor et quis in incididunt aute. Ullamco voluptate consectetur dolor officia sunt est dolor sint.',
            },
        ]
    )
    const [individualGoals, setIndividualGoals] = useState([]);
    const [groupGoals, setGroupGoals] = useState([]);
    const [individualGoalsLen, setIndividualGoalsLen] = useState(0);
    const [groupGoalsLen, setGroupGoalsLen] = useState(0);
    const tabsRef = useRef([]);

    const { goals } = useSelector(state => state.users);

    useEffect(() => {
        if (goals.length > 0) {
            let tempGoals = [...goals];
            let a = tempGoals.filter((e) => (!e.isShared || e.isShared === false));
            let b = tempGoals.filter((e) => (e.isShared && e.isShared === true));

            let inactiveGoals = a.filter(e => !e.goalStatus || e.goalStatus === null);
            let activeGoals = a.filter(e => (e.goalStatus && e.goalStatus === 'ACTIVE'));
            let initializedGoals = a.filter(e => (e.goalStatus && e.goalStatus === 'INITIALIZED'));
            let completedGoals = a.filter(e => (e.goalStatus && e.goalStatus === 'COMPLETED'));
            let cancelledGoals = a.filter(e => (e.goalStatus && e.goalStatus === 'CANCELLED'));

            let inactiveGroupGoals = b.filter(e => !e.goalStatus || e.goalStatus === null);
            let activeGroupGoals = b.filter(e => (e.goalStatus && e.goalStatus === 'ACTIVE'));
            let initializedGroupGoals = b.filter(e => (e.goalStatus && e.goalStatus === 'INITIALIZED'));
            let completedGroupGoals = b.filter(e => (e.goalStatus && e.goalStatus === 'COMPLETED'));
            let cancelledGroupGoals = b.filter(e => (e.goalStatus && e.goalStatus === 'CANCELLED'));
            
            setIndividualGoalsLen(a.length);
            setGroupGoalsLen(b.length);
            setIndividualGoals([
                {
                    title: "Inactive Goals",
                    data: inactiveGoals
                },
                {
                    title: "Active Goals",
                    data: activeGoals
                },
                {
                    title: "Initialized Goals",
                    data: initializedGoals
                },
                {
                    title: "Completed Goals",
                    data: completedGoals
                },
                {
                    title: "Cancelled Goals",
                    data: cancelledGoals
                }
            ])

            setGroupGoals([
                {
                    title: "Inactive Goals",
                    data: inactiveGroupGoals
                },
                {
                    title: "Active Goals",
                    data: activeGroupGoals
                },
                {
                    title: "Initialized Goals",
                    data: initializedGroupGoals
                },
                {
                    title: "Completed Goals",
                    data: completedGroupGoals
                },
                {
                    title: "Cancelled Goals",
                    data: cancelledGroupGoals
                }
            ])
        }
    }, [goals])


    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex];
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        }

        setTabPosition();
        window.addEventListener('resize', setTabPosition);

        return () => window.removeEventListener('resize', setTabPosition);
    }, [activeTabIndex]);

    return (
        <div>
            <div className="relative">
                <div className="flex space-x-4 border-b">
                    {tabsData.map((tab, idx) => {
                        return (
                            <div
                                key={idx}
                                ref={(el) => (tabsRef.current[idx] = el)}
                                className={`pt-2 pb-3 cursor-pointer outline-none ${idx !== 0 && "ml-4"}`}
                                onClick={() => setActiveTabIndex(idx)}
                            >
                                {tab.label} {' '} {idx === 0 ? individualGoalsLen : groupGoalsLen}
                            </div>
                        );
                    })}
                </div>
                <span
                    className="absolute bottom-0 block h-1 bg-greyBorder transition-all duration-300"
                    style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
                />
            </div>
            <div className="py-4">
                <div>{activeTabIndex === 0 ? (
                    <>
                        {
                            individualGoals.map((ele, idx) => {
                                return (
                                    <div className='flex flex-col overflow-x-auto flex-1 min-w-max mb-8' key={`goals_tab_${idx}`}>
                                        <p>{ele.title}</p>
                                        <div className='flex w-full h-14 items-center bg-tableHeading'>
                                            <div className="w-[10vw] ml-3">
                                                No
                                            </div>
                                            <div className="w-[15vw]">
                                                Image
                                            </div>
                                            <div className="w-[18vw]">
                                                Name
                                            </div>
                                            <div className="w-[18vw]">
                                                Length
                                            </div>
                                            <div className="w-[18vw]">
                                                Total Amount
                                            </div>
                                            <div className="w-[18vw]">
                                                Saved Amount
                                            </div>
                                            <div className="w-[18vw]">
                                                Installment Amt
                                            </div>
                                            <div className="w-[18vw]">
                                                Payment Date
                                            </div>
                                        </div>
                                        {ele.data.map((g, it) => {
                                            try {
                                                const {
                                                    image,
                                                    name,
                                                    length,
                                                    lengthDurationType,
                                                    totalGoalAmount,
                                                    savedAmount,
                                                    instalmentAmount,
                                                    paymentDate
                                                } = g

                                                return (
                                                    <>
                                                        <div style={{ background: "rgba(0, 0, 0, 0.11)", borderColor: "#828282", borderWidth: "0.5px 0px", borderStyle:"solid" }} className='flex w-full items-center text-sm'>
                                                            <div className="w-[10vw] ml-3">
                                                                {it + 1}
                                                            </div>
                                                            <div className="w-[15vw] flex items-center h-[90px]">
                                                                {image && (<img src={image} className='w-[90%] h-[80px]' />)}
                                                            </div>
                                                            <div className="w-[18vw]">
                                                                {name}
                                                            </div>
                                                            <div className="w-[18vw]">
                                                                {`${length} ${lengthDurationType}`}
                                                            </div>
                                                            <div className="w-[18vw]">
                                                                {totalGoalAmount}
                                                            </div>
                                                            <div className="w-[18vw]">
                                                                {savedAmount}
                                                            </div>
                                                            <div className="w-[18vw]">
                                                                {instalmentAmount.toFixed(2)}
                                                            </div>
                                                            <div className="w-[18vw]">
                                                                {new Date(paymentDate).toLocaleDateString()}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            } catch (error) {

                                            }
                                        })}
                                    </div>
                                )
                            })
                        }
                    </>
                ) : (
                        <>
                            {
                                groupGoals.map((ele, idx) => {
                                    return (
                                        <div className='flex flex-col overflow-x-auto flex-1 min-w-max mb-8' key={`goals_tab_${idx}`}>
                                            <p>{ele.title}</p>
                                            <div className='flex w-full h-14 items-center bg-tableHeading'>
                                                <div className="w-[10vw] ml-3">
                                                    No
                                                </div>
                                                <div className="w-[15vw]">
                                                    Image
                                                </div>
                                                <div className="w-[18vw]">
                                                    Name
                                                </div>
                                                <div className="w-[18vw]">
                                                    Length
                                                </div>
                                                <div className="w-[18vw]">
                                                    Total Amount
                                                </div>
                                                <div className="w-[18vw]">
                                                    Saved Amount
                                                </div>
                                                <div className="w-[18vw]">
                                                    Installment Amt
                                                </div>
                                                <div className="w-[18vw]">
                                                    Payment Date
                                                </div>
                                                <div className="w-[22vw]">
                                                    Group Goals Members
                                                </div>
                                            </div>
                                            {ele.data.map((g, it) => {
                                                try {
                                                    const {
                                                        image,
                                                        name,
                                                        length,
                                                        lengthDurationType,
                                                        totalGoalAmount,
                                                        savedAmount,
                                                        instalmentAmount,
                                                        paymentDate
                                                    } = g

                                                    return (
                                                        <>
                                                            <div style={{ background: "rgba(0, 0, 0, 0.11)", borderColor: "#828282", borderWidth: "0.5px 0px", borderStyle: "solid" }} className='flex w-full items-center text-sm'>
                                                                <div className="w-[10vw] ml-3">
                                                                    {it + 1}
                                                                </div>
                                                                <div className="w-[15vw] flex items-center h-[90px]">
                                                                    {image && (<img src={image} className='w-[90%] h-[80px]' />)}
                                                                </div>
                                                                <div className="w-[18vw]">
                                                                    {name}
                                                                </div>
                                                                <div className="w-[18vw]">
                                                                    {`${length} ${lengthDurationType}`}
                                                                </div>
                                                                <div className="w-[18vw]">
                                                                    {totalGoalAmount}
                                                                </div>
                                                                <div className="w-[18vw]">
                                                                    {savedAmount}
                                                                </div>
                                                                <div className="w-[18vw]">
                                                                    {instalmentAmount.toFixed(2)}
                                                                </div>
                                                                <div className="w-[18vw]">
                                                                    {new Date(paymentDate).toLocaleDateString()}
                                                                </div>
                                                                <div className='w-[22vw]'>
                                                                    
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                } catch (error) {

                                                }
                                            })}
                                        </div>
                                    )
                                })
                            }
                        </>
                )}</div>
            </div>
        </div>
    )
}

export default GoalsTab