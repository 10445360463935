import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { handleAPICall, checkStatusCode } from './APIUtils'
import { listMerchantProductCategorys } from '../graphql/queries'

import { fetchAllDataFromGraphQLQuery } from '../common/Utility'

const initialState = {
    merchantProductCategories: [],
    productCategoryLoading: false,
    productCategoryError: "",
    currentProductCategory: {}
}

export const listMerchantProductCategoryAPI = createAsyncThunk("merchantCat/listMerchantProductCat", async(_, thunkAPI) => {
    try {
        const data = await handleAPICall("merchantApi", "/merchant/product/category", {}, "GET");

        if (data.error && Object.keys(data.error).length > 0) {
            return thunkAPI.rejectWithValue(data.error || "Something went wrong!");
        }
        return data.success;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const createMerchantProductCategoryAPI = createAsyncThunk("merchantCat/createMerchantProductCat", async ({ body, callback = () => {} }, thunkAPI) => {
    try {
        const options = {
            body: body
        }
        const data = await handleAPICall("merchantApi", "/merchant/product/category", options, "POST");
        callback(data);

        if (data.error && Object.keys(data.error).length > 0) {
            return thunkAPI.rejectWithValue(data.error || "Something went wrong!");
        }
        return data.success;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateMerchantProductCategoryAPI = createAsyncThunk("merchantCat/updateMerchantProductCat", async({ body, callback = () => { } }, thunkAPI) => {
    try {
        const id = body["ID"];
        delete body.ID;
        const options = {
            body: body
        }
        const data = await handleAPICall("merchantApi", `/merchant/product/category/${id}`, options, "UPDATE");

        callback(data, true)
        if (data.error && Object.keys(data.error).length > 0) {
            return thunkAPI.rejectWithValue(data.error || "Something went wrong!");
        }
        return data.success.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const merchantProductCatSlice = createSlice({
    name: "merchantProductCategory",
    initialState,
    reducers: {
        saveCurrentProductCat: (state, action) => {
            state.currentProductCategory = action.payload;
        },
        setMerchantProductCatError: (state, action) => {
            state.productCategoryError = action.payload;
        },
        updateMerchantProductCat: (state, action) => {
            state.merchantProductCategories = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(listMerchantProductCategoryAPI.pending, (state) => {
            state.productCategoryLoading = true;
            state.productCategoryError = "";
            
        })
        builder.addCase(listMerchantProductCategoryAPI.fulfilled, (state, action) => {
            state.productCategoryLoading = false;
            state.productCategoryError = "";
            state.merchantProductCategories = action.payload.data;
            
        })
        builder.addCase(listMerchantProductCategoryAPI.rejected, (state, action) => {
            state.productCategoryLoading = false;
            
            try {
                state.productCategoryError = action.payload.error.response.data.error.message
            } catch (error) {
                state.productCategoryError = "Something went Wrong"
            }
        })
        builder.addCase(createMerchantProductCategoryAPI.pending, (state) => {
            state.productCategoryLoading = true;
            
        });
        builder.addCase(createMerchantProductCategoryAPI.fulfilled, (state, action) => {
            state.productCategoryLoading = false;
            state.productCategoryError = "";
            
            let oldArticles = [...state.merchantProductCategories];
            oldArticles.push(action.payload.data);
            state.merchantProductCategories = oldArticles;
        })
        builder.addCase(createMerchantProductCategoryAPI.rejected, (state, action) => {
            state.productCategoryLoading = false;
            
            try {
                state.productCategoryError = action.payload.error.response.data.error.message
            } catch (error) {
                state.productCategoryError = "Something went Wrong"
            }
        })

        builder.addCase(updateMerchantProductCategoryAPI.pending, (state) => {
            state.productCategoryLoading = false;
            state.productCategoryError = "";
        })

        builder.addCase(updateMerchantProductCategoryAPI.fulfilled, (state, action) => {
            state.productCategoryError = "";
            let index = -1;
            let oldArr = [...state.merchantProductCategories];
            for (let i = 0; i < oldArr.length; i++) {
                const element = oldArr[i];
                if (element.id === action.payload?.id) {
                    oldArr[i] = action.payload;
                    break;
                }
            }
            state.merchantProductCategories = oldArr;
            state.productCategoryLoading = false;
        })

        builder.addCase(updateMerchantProductCategoryAPI.rejected, (state, action) => {
            state.productCategoryLoading = false;
            try {
                state.productCategoryError = action.payload.error.response.data.error.message
            } catch (error) {
                state.productCategoryError = "Something went Wrong"
            }
        })
    }
})

export const { saveCurrentProductCat, setMerchantProductCatError, updateMerchantProductCat } = merchantProductCatSlice.actions;

export default merchantProductCatSlice.reducer

