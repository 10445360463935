import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUserListAPI, saveCurrentUser, updateUserList, getUserGoalsAPI } from '../../../api/userSlice';
import { Dialog, Transition } from '@headlessui/react';
import { utils, writeFileXLSX } from 'xlsx';
import { TailSpin } from 'react-loader-spinner'

import FilterDropdown from '../../../sharedComponents/FilterDropdown/FilterDropdown';
import Pagination from '../../../sharedComponents/Pagination/Pagination';
import BroadcastModal from './BroadcastModal';
import GoalsModal from './GoalsModal';
import InfoMenu from '../../../sharedComponents/InfoMenu/InfoMenu';

import filterLogo from '../../../assets/images/filterLogo.svg'
import downloadLogo from '../../../assets/images/downloadLogo.svg'
import upIcon from '../../../assets/images/upIcon.svg';
import chatIcon from '../../../assets/images/chatIcon.svg';

function Users() {
    const dispatch = useDispatch();

    const { userList, loading, error, userListLoading } = useSelector(state => state.users);

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openBroadcastModal, setOpenBroadcastModal] = useState(false);
    const [showInfoDropdown, setShowInfoDropdown] = useState(false);
    const [infoContent, setInfoContent] = useState("");
    const [mousePointer, setMousePointer] = useState({
        x: 0,
        y: 0
    });
    const [editFlag, setEditFlag] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [filterUsers, setFilterUsers] = useState([]);
    const [currentRecords, setCurrentRecords] = useState([]);
    const [ascSort, setAscSort] = useState([true, true, true, true, true, true]);
    const [filterFlag, setFilterFlag] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const tableHeadingClasses = "text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading"

    const tableCellClasses = "min-h-[40px] flex items-center justify-start truncate"

    useEffect(() => {
        fetchUserList();
    }, [])

    useEffect(() => {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        let oldUsers = [...userList];

        if (filterUsers.length > 0) {
            const newUsers = oldUsers.filter(handleFilter);
            if (newUsers.length <= recordsPerPage) {
                setCurrentRecords(oldUsers);
            } else {
                setCurrentRecords(newUsers.slice(indexOfFirstRecord, indexOfLastRecord))
            }
        } else {
            setCurrentRecords([...oldUsers.slice(indexOfFirstRecord, indexOfLastRecord)])
        }

    }, [currentPage, recordsPerPage, userListLoading, filterUsers, userList])


    const fetchUserList = () => {
        if (userList.length === 0) {
            dispatch(getUserListAPI());
        }
    }

    const fetchUserGoals = (userId) => {
        dispatch(getUserGoalsAPI({ id: userId }));
    }

    const convertToXLSX = async () => {
        try {
            let cleanData = [];
            const header = [
                'ID',
                'First Name',
                'Middle Name',
                'Last Name',
                'Email',
                'Mobile No',
                'User First Time Login Flag',
                'Profile Lock',
                'Completed Grow Questionnaire?',
                'Title',
                'Gender',
                'State',
                'City',
                'Street 1',
                'Street2',
                'Post Code',
                'Building Number',
                'Address House',
                'Landmark',
                'Date of Birth',
                'Country Code',
                'Bank Customer ID',
                'Bank Savings A/C Num',
                'Bank Savings Branch',
                'IFSC Code',
                'Savings T&C TS',
                'Savings T&C Acceptance',
                'Savings T&C IP',
                'KYC Status',
                'Debit Card Name',
                'Created At',
                'Updated At'
            ];
            userList.map((user) => {
                let temp = {};

                temp[header[0]] = user.id
                temp[header[1]] = user.UsrGivenName || ""
                temp[header[2]] = user.UsrMiddleName || ""
                temp[header[3]] = user.UsrFamilyName || ""
                temp[header[4]] = user.UsrEmail || ""
                temp[header[5]] = user.UsrMobileNo || ""
                temp[header[6]] =
                    user.UsrFirstTimeLoginFlag || false

                temp[header[7]] = user.ProfileLock || "-"
                temp[header[8]] = user.hasUserCompletedGrowQuestionnaire || false
                temp[header[9]] = user.Title || ""
                temp[header[10]] = user.Gender || ""
                temp[header[11]] = user.State || ""
                temp[header[12]] = user.City || ""
                temp[header[13]] = user.Street1 || ""
                temp[header[14]] = user.Street2 || ""
                temp[header[15]] = user.PostCode || ""
                temp[header[16]] = user.addressBuildingNumber || ""
                temp[header[17]] = user.addressHouse || ""
                temp[header[18]] = user.addressLandmark || ""
                temp[header[19]] = user.DateOfBirth || ""
                temp[header[20]] = user.CountryCode || ""
                temp[header[21]] = user.snplBankCustomerId || ""
                temp[header[22]] = user.snplBankSavingsAccountNumber || ""
                temp[header[23]] = user.snplBankSavingsBranch || ""
                temp[header[24]] = user.snplBankSavingsIFSCCode || ""
                temp[header[25]] = user.SavingsTermsAndConditionsTS ? new Date(user.SavingsTermsAndConditionsTS).toLocaleString() : ""
                temp[header[26]] = user.SavingsTermsAndConditionsAcceptance || false
                temp[header[27]] = user.SavingsTermsAndConditionsIP || ""
                temp[header[28]] = user.UsrKYCStatus || ""
                temp[header[29]] = user.snplDebitCardName || ""
                temp[header[30]] = user.createdAt ? new Date(user.createdAt).toLocaleString() : new Date().toLocaleString()
                temp[header[31]] = user.updatedAt ? new Date(user.updatedAt).toLocaleString() : new Date().toLocaleString()

                cleanData.push(temp);
            })
            const ws = utils.json_to_sheet(cleanData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "users");
            writeFileXLSX(wb, "user_data.xlsx");

        } catch (error) {
            console.log(error);
        }
    }

    const handleMousePointer = (x, y) => {
        const oldPt = mousePointer;
        let newCoord = {
            x: parseInt(x),
            y: parseInt(y)
        }
        setMousePointer(newCoord)
    }

    const handleShowInfo = (e, content) => {
        try {
            const element = e.target;
            const rect = element.getBoundingClientRect();
            const topPosition = rect.top + window.scrollY;
            const leftPosition = rect.left + window.scrollX;
            setInfoContent(content);
            handleMousePointer(leftPosition, topPosition);
            setShowInfoDropdown(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleCloseInfo = () => {
        setMousePointer({});
        setShowInfoDropdown(false);
        setInfoContent("");
    }

    const convertKYCToString = (o) => {
        if (o["Image"]) {
            delete o["Image"]
        }
        let s = "";
        let aadharDetails = "";
        try {
            if (o["AadhaarOutputDetails"].Data) {
                const details = o["AadhaarOutputDetails"].Data.Result?.DataFromAadhaar;
                aadharDetails += ` Aadhar Details: {
                    Name: ${details.Name}
                    MaskedAadharNum: ${details.MaskedAadhaarNumber}
                }
                `
            }
        } catch (error) {
            aadharDetails = {};
        }
        for (const p in o) {
            if (p === 'AadhaarOutputDetails') {
                s += aadharDetails;
            } else {
                s += `${p}: ${JSON.stringify(o[p], null, 2)} \n`
            }
            // console.log(p);
        }
        if (s.length === 0) {
            return "No Information Available"
        }
        return s;
    }

    const convertSNPLToString = (o) => {
        let s = "";
        for (const p in o) {
            s += `${p}: ${JSON.stringify(o[p], null, 2)} \n`
            // console.log(p);
        }
        if (s.length === 0) {
            return "No Information Available"
        }
        return s;
    }

    const sortUsers = () => {
        let oldSort = [true, true, true, true, true, true];
        if (ascSort[0]) {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                return a.id.localeCompare(b.id)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[0] = false;
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                return -1 * a.id.localeCompare(b.id)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[0] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortUsersByFirstName = () => {
        let oldSort = [true, true, true, true, true, true];
        if (ascSort[1]) {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrGivenName || "";
                let b1 = b?.UsrGivenName || "";
                return a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[1] = false;
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrGivenName || "";
                let b1 = b?.UsrGivenName || "";
                return -1 * a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[1] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortUsersByLastName = () => {
        let oldSort = [true, true, true, true, true, true];
        if (ascSort[2]) {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrFamilyName || "";
                let b1 = b?.UsrFamilyName || "";
                return a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[2] = false;
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrFamilyName || "";
                let b1 = b?.UsrFamilyName || "";
                return -1 * a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[2] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortUsersByEmail = () => {
        let oldSort = [true, true, true, true, true, true];
        if (ascSort[3]) {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrEmail || "";
                let b1 = b?.UsrEmail || "";
                return a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[3] = false;
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrEmail || "";
                let b1 = b?.UsrEmail || "";
                return -1 * a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[3] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortByDate = (flag = false) => {
        let oldSort = [true, true, true, true, true, true];
        if (flag ? ascSort[4] : ascSort[5]) {
            let oldUsers = [...userList];
            if (flag) {
                oldUsers.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldUsers.sort(function (a, b) {
                    return new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateUserList([...oldUsers]))
            if (flag) {
                oldSort[4] = false;
            } else {
                oldSort[5] = false;
            }
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            if (flag) {
                oldUsers.sort(function (a, b) {
                    return -1 * new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldUsers.sort(function (a, b) {
                    return -1 * new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateUserList([...oldUsers]))
            if (flag) {
                oldSort[4] = true;
            } else {
                oldSort[5] = true;
            }
            setAscSort([...oldSort]);
        }
    }

    const handleCurrentUsers = (data) => {
        dispatch(saveCurrentUser(data));
        fetchUserGoals(data?.id);
        setOpenCreateModal(true);
    }

    const handleFilter = (user) => {
        if (filterUsers.length === 0) {
            return true;
        }
        const id = filterUsers.findIndex(e => e === user?.UsrFamilyName);
        return id !== -1;
    }

    const handleOpenFilterMenu = () => {
        setShowFilterMenu(prev => !prev);
    }

    return (
        <>
            {(userListLoading || currentRecords.length === 0) ? (
                <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <TailSpin
                        height="120"
                        width="120"
                        color="#62A6FF"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <div id='main-container' className="container max-w-full">
                        <div className='w-full flex justify-between items-center min-w-max'>
                            <h1 className='text-[32px] font-bold m-[40px]'>Users</h1>
                            <div className='w-[200px] mr-10 flex justify-center'>
                                <div className='cursor-pointer mr-3'>
                                    <img onClick={handleOpenFilterMenu} src={filterLogo} alt="" />
                                    <FilterDropdown
                                        filterItems={filterUsers}
                                        setFilterItems={setFilterUsers}
                                        showFilterMenu={showFilterMenu}
                                        type="Users"
                                    />
                                </div>
                                <div onClick={() => setOpenBroadcastModal(true)} className='cursor-pointer mr-3'>
                                    <img src={chatIcon} alt="" />
                                </div>
                                <div onClick={() => convertToXLSX()} className='cursor-pointer'>
                                    <img src={downloadLogo} alt="" />
                                </div>
                            </div>
                        </div>

                        <div id="user_table" className='flex flex-col justify-center text-center overflow-x-auto flex-1 overflow-y-hidden pb-12 min-w-max'>
                            <>
                                <div className='w-full flex'>
                                    <div className={`w-[35vw] lg:w-[20vw] ${tableHeadingClasses} justify-center ml-10 pl-4 rounded-l-lg`}>
                                        ID
                                        <img onClick={sortUsers} className={`ml-2 ${ascSort[0] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Given Name
                                        <img onClick={sortUsersByFirstName} className={`ml-2 ${ascSort[1] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Middle Name
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Family Name
                                        <img onClick={sortUsersByLastName} className={`ml-2 ${ascSort[2] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />

                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        Email
                                        <img onClick={sortUsersByEmail} className={`ml-2 ${ascSort[3] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />

                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Mobile Number
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        Goals
                                    </div>
                                    <div className={`w-[35vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Org ID
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        KYC Status
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        First Time Login Flag
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Profile Lock
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Number of Attempt
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Last Logged In
                                    </div>
                                    <div className={`w-[50vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Completed Grow Question?
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Title
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Gender
                                    </div>
                                    <div className={`w-[40vw] lg:w-[15vw] ${tableHeadingClasses}`}>
                                        State
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        City
                                    </div>
                                    <div className={`w-[60vw] lg:w-[25vw] ${tableHeadingClasses}`}>
                                        Street 1
                                    </div>
                                    <div className={`w-[60vw] lg:w-[25vw] ${tableHeadingClasses}`}>
                                        Street 2
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Post Code
                                    </div>
                                    <div className={`w-[50vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Building Number
                                    </div>
                                    <div className={`w-[50vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        House
                                    </div>
                                    <div className={`w-[50vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Landmark
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        DOB
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        Owner
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Country Code
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Use Biometrics
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Aadhar Auth Check?
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Aadhar OTP Check?
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Face ID Passed
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        PAN Check Passed
                                    </div>
                                    <div className={`w-[40vw] lg:w-[15vw] ${tableHeadingClasses}`}>
                                        Three Way Check Passed
                                    </div>
                                    <div className={`w-[40vw] lg:w-[15vw] ${tableHeadingClasses}`}>
                                        Communication Preferences
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Tenant
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Bank Customer ID
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Bank Savings Acc Num
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Bank Savings IFSC Code
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Bank Savings Branch
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Bank KYC Progress
                                    </div>
                                    <div className={`w-[35vw] lg:w-[12vw] ${tableHeadingClasses}`}>
                                        Savings T&C - TS
                                    </div>
                                    <div className={`w-[35vw] lg:w-[12vw] ${tableHeadingClasses}`}>
                                        Savings T&C - ACC
                                    </div>
                                    <div className={`w-[35vw] lg:w-[12vw] ${tableHeadingClasses}`}>
                                        Savings T&C - IP
                                    </div>
                                    <div className={`w-[35vw] lg:w-[12vw] ${tableHeadingClasses}`}>
                                        Cashfree Beneficiary ID
                                    </div>
                                    <div className={`w-[35vw] lg:w-[12vw] ${tableHeadingClasses}`}>
                                        Profile Picture URL
                                    </div>
                                    <div className={`w-[35vw] lg:w-[12vw] ${tableHeadingClasses}`}>
                                        Registered In App
                                    </div>
                                    <div className={`w-[35vw] lg:w-[12vw] ${tableHeadingClasses}`}>
                                        Registered In App DTS
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Nationality
                                    </div>

                                    <div className={`w-[50vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        FCM Reg Token
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Bank AC Type Code
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Bank AC Type Name
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Debit Card Name
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Bank Services
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Declaration
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        SNPL Bank Name
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Date Opened
                                    </div>
                                    <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                        SNPL Average Balance
                                    </div>
                                    <div className={`w-[75vw] lg:w-[25vw] ${tableHeadingClasses}`}>
                                        Communication Address
                                    </div>
                                    <div className={`w-[40vw] lg:w-[15vw] ${tableHeadingClasses}`}>
                                        Customer Savings Account
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Added
                                        <img onClick={() => sortByDate(true)} className={`ml-2 ${ascSort[4] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />

                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses} mr-10 rounded-r-lg`}>
                                        Updated
                                        <img onClick={() => sortByDate(false)} className={`ml-2 ${ascSort[5] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                </div>

                                {userList && currentRecords.filter(handleFilter).map((ele, i) => {
                                    if (ele === null || ele === undefined) {
                                        return <></>;
                                    }
                                    try {
                                        const {
                                            id,
                                            OrgId,
                                            UsrEmail,
                                            UsrMobileNo,
                                            UsrGivenName,
                                            UsrMiddleName,
                                            UsrFamilyName,
                                            UsrTCAcceptance,
                                            UsrKYCStatus,
                                            UsrFirstTimeLoginFlag,
                                            ProfileLock,
                                            NoOfAttempt,
                                            LastLoggedIn,
                                            CreatedAt,
                                            ModifiedAt,
                                            hasUserCompletedGrowQuestionnaire,
                                            Title,
                                            Gender,
                                            State,
                                            City,
                                            Street1,
                                            Street2,
                                            PostCode,
                                            addressBuildingNumber,
                                            addressHouse,
                                            addressLandmark,
                                            DateOfBirth,
                                            owner,
                                            language,
                                            CountryCode,
                                            useBiometrics,
                                            aadhaarPassedAuthCheck,
                                            aadhaarPassedOTPCheck,
                                            faceIDPasssed,
                                            panCheckPassed,
                                            threeWayCheckPassed,
                                            pushNotifications,
                                            communicationPreferences,
                                            tenant,
                                            snplBankCustomerId,
                                            snplBankSavingsAccountNumber,
                                            snplBankSavingsIFSCCode,
                                            snplBankSavingsBranch,
                                            snplBankKYCProgress,
                                            SavingsTermsAndConditionsTS,
                                            SavingsTermsAndConditionsAcceptance,
                                            SavingsTermsAndConditionsIP,
                                            cashfreeBeneficiaryId,
                                            ProfilePictureURL,
                                            registeredInApp,
                                            registeredInAppDTS,
                                            nationality,
                                            goals,
                                            FCMRegToken,
                                            snplBankAccountTypeCode,
                                            snplBankAccountTypeDisplayName,
                                            snplDebitCardName,
                                            snplBankServices,
                                            snplDeclarations,
                                            snplBankName,
                                            snplBankAccountDateOpenedDTS,
                                            snplAverageBalance,
                                            communicationAddress,
                                            CustomerSavingsAccount,
                                            createdAt,
                                            updatedAt,
                                        } = ele
                                        // const parser = new DOMParser();
                                        // const desc = parser.parseFromString(merchantDescription, "text/html")
                                        let snplDeclarationsObj = snplDeclarations;
                                        let kycProgress = snplBankKYCProgress;
                                        if (typeof kycProgress === 'string') {
                                            kycProgress = JSON.parse(kycProgress)
                                        }
                                        if (typeof snplDeclarations === 'string') {
                                            snplDeclarationsObj = JSON.parse(snplDeclarations);
                                        }
                                        if (!id) {
                                            return (<></>)
                                        }
                                        // const index = recordsPerPage * (currentPage - 1) + (id + 1);
                                        return (
                                            <div className='w-full flex my-3'>
                                                <div onClick={() => handleCurrentUsers(ele)} className={`w-[35vw] lg:w-[20vw] cursor-pointer underline pl-4 text-blue-500 ${tableCellClasses} ml-10`}>
                                                    <div className='w-[90%] overflow-x-auto'>
                                                        {id}
                                                    </div>
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {UsrGivenName || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {UsrMiddleName || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {UsrFamilyName || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] t${tableCellClasses} text-left`}>
                                                    <div style={{ width: "90%" }} className='overflow-x-auto'>
                                                        {UsrEmail || "-"}
                                                    </div>
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {UsrMobileNo || "-"}
                                                </div>
                                                <div onClick={() => fetchUserGoals(id)} className={`w-[45vw] lg:w-[18vw] underline cursor-pointer ${tableCellClasses}`}>
                                                    Click to View Goals
                                                </div>
                                                <div className={`w-[35vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                    {OrgId || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {UsrKYCStatus || "-"}
                                                </div>

                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {UsrFirstTimeLoginFlag}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {ProfileLock ? "True" : "False"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {NoOfAttempt || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {LastLoggedIn || "-"}
                                                </div>
                                                <div className={`w-[50vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                    {hasUserCompletedGrowQuestionnaire ? "True" : "False"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {Title || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {Gender || "-"}
                                                </div>
                                                <div className={`w-[40vw] lg:w-[15vw] ${tableCellClasses}`}>
                                                    {State || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {City || "-"}
                                                </div>
                                                <div className={`w-[60vw] lg:w-[25vw] ${tableCellClasses} text-justify`}>
                                                    <div style={{ width: "90%", maxHeight: "300px" }} className='overflow-auto whitespace-pre-line'>
                                                        {Street1 || "-"}
                                                    </div>
                                                </div>
                                                <div className={`w-[60vw] lg:w-[25vw] ${tableCellClasses} text-justify`}>
                                                    <div style={{ width: "90%", maxHeight: "300px" }} className='overflow-auto whitespace-pre-line'>
                                                        {Street2 || "-"}
                                                    </div>
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {PostCode || "-"}
                                                </div>
                                                <div style={{ justifyContent: "flex-start" }} className={`w-[50vw] lg:w-[20vw] ${tableCellClasses} text-justify`}>
                                                    {addressBuildingNumber || "-"}
                                                </div>
                                                <div style={{ justifyContent: "flex-start" }} className={`w-[50vw] lg:w-[20vw] ${tableCellClasses} text-justify`}>
                                                    <div style={{ width: "95%", maxHeight: "300px" }} className='overflow-auto whitespace-pre-line'>
                                                        {addressHouse || "-"}
                                                    </div>
                                                </div>
                                                <div style={{ justifyContent: "flex-start" }} className={`w-[50vw] lg:w-[20vw] ${tableCellClasses} text-justify`}>
                                                    <div style={{ width: "95%", maxHeight: "300px" }} className='overflow-auto whitespace-pre-line'>
                                                        {addressLandmark || "-"}
                                                    </div>
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {DateOfBirth || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    <div style={{ width: "90%" }} className='overflow-x-auto'>
                                                        {owner || "-"}
                                                    </div>
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses} flex items-center text-center`}>
                                                    {CountryCode || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {useBiometrics ? "True" : "False"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {aadhaarPassedAuthCheck ? "True" : "False"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {aadhaarPassedOTPCheck ? "True" : "False"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {faceIDPasssed ? "True" : "False"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {panCheckPassed ? "True" : "False"}
                                                </div>
                                                <div className={`w-[40vw] lg:w-[15vw] ${tableCellClasses}`}>
                                                    {threeWayCheckPassed ? "True" : "False"}
                                                </div>
                                                <div className={`w-[40vw] lg:w-[15vw] ${tableCellClasses}`}>
                                                    {communicationPreferences || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {tenant || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplBankCustomerId || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplBankSavingsAccountNumber || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplBankSavingsIFSCCode || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplBankSavingsBranch || "-"}
                                                </div>
                                                <div onClick={(e) => handleShowInfo(e, convertKYCToString(kycProgress || {}))} className={`w-[45vw] lg:w-[18vw] ${tableCellClasses} underline cursor-pointer`}>
                                                    Click to View KYC Progress
                                                </div>
                                                <div className={`w-[35vw] lg:w-[12vw] ${tableCellClasses}`}>
                                                    {SavingsTermsAndConditionsTS ? new Date(SavingsTermsAndConditionsTS).toLocaleDateString() : "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[12vw] ${tableCellClasses}`}>
                                                    {SavingsTermsAndConditionsAcceptance ? "True" : "False"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[12vw] ${tableCellClasses}`}>
                                                    {SavingsTermsAndConditionsIP || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[12vw] ${tableCellClasses}`}>
                                                    {cashfreeBeneficiaryId || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[12vw] ${tableCellClasses}`}>
                                                    <div style={{ width: "95%" }} className='truncate'>
                                                        {ProfilePictureURL || "-"}
                                                    </div>
                                                </div>
                                                <div className={`w-[35vw] lg:w-[12vw] ${tableCellClasses}`}>
                                                    {registeredInApp ? new Date(registeredInApp).toLocaleDateString() : "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[12vw] ${tableCellClasses}`}>
                                                    {registeredInAppDTS ? new Date(registeredInApp).toLocaleDateString() : "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {nationality || "-"}
                                                </div>
                                                <div className={`w-[50vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                    <div className='w-[90%] overflow-x-auto'>
                                                        {FCMRegToken || "-"}
                                                    </div>
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplBankAccountTypeCode || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplBankAccountTypeDisplayName || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplDebitCardName || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplBankServices || "-"}
                                                </div>
                                                <div onClick={(e) => handleShowInfo(e, convertSNPLToString(snplDeclarationsObj || {}))} className={`w-[45vw] lg:w-[18vw] ${tableCellClasses} underline cursor-pointer`}>
                                                    Click to View Info
                                                    {/* {snplDeclarations || "-"} */}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {snplBankName || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplBankAccountDateOpenedDTS || "-"}
                                                </div>
                                                <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses}`}>
                                                    {snplAverageBalance || "-"}
                                                </div>
                                                <div className={`w-[75vw] lg:w-[25vw] ${tableCellClasses} text-left`}>
                                                    <div style={{ width: "95%", maxHeight: "300px" }} className='overflow-auto whitespace-pre-line'>
                                                        {communicationAddress || "-"}
                                                    </div>
                                                </div>
                                                <div className={`w-[40vw] lg:w-[15vw] ${tableCellClasses}`}>
                                                    {CustomerSavingsAccount || "-"}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                    {new Date(createdAt).toLocaleDateString()}
                                                </div>
                                                <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses} mr-10`}>
                                                    {updatedAt ? new Date(updatedAt).toLocaleDateString() : new Date().toLocaleDateString()}
                                                </div>
                                            </div>
                                        )
                                    } catch (error) {
                                        return <></>;
                                    }
                                })}

                            </>
                        </div>

                        {/* Goals Modal */}
                        <Transition appear show={openCreateModal} as={Fragment}>
                            <Dialog as="div" className="relative z-10" onClose={() => {
                                setOpenCreateModal(false)
                                setEditFlag(false);
                            }}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                                </Transition.Child>

                                <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full justify-center p-4">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Dialog.Panel className="transition-all">
                                                <GoalsModal setOpenEditModal={setOpenCreateModal} openEditModal={openCreateModal} editFlag={editFlag} setEditFlag={setEditFlag} />
                                                {/* <EditUserModal setOpenEditModal={setOpenCreateModal} openEditModal={openCreateModal} /> */}
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition>

                        {/* Open Broadcast Modal */}
                        <Transition appear show={openBroadcastModal} as={Fragment}>
                            <Dialog as="div" className="relative z-10" onClose={() => {
                                setOpenBroadcastModal(false);
                            }}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                                </Transition.Child>

                                <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full justify-center p-4">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Dialog.Panel className="transition-all">
                                                <BroadcastModal openBroadcastModal={openBroadcastModal} setOpenBroadcastModal={setOpenBroadcastModal} />
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition>

                        <Pagination
                            size={userList.filter(handleFilter).length || 0}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            type='Users'
                            setRecordsPerPage={setRecordsPerPage}
                            recordsPerPage={recordsPerPage}
                        />

                        {showInfoDropdown && (
                            <InfoMenu handleClose={handleCloseInfo} left={mousePointer["x"]} top={mousePointer["y"]} content={infoContent} />
                        )}
                    </div>
                </>
            )}
        </>
    )
}

export default Users