import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { utils, writeFileXLSX } from 'xlsx';
import { TailSpin } from 'react-loader-spinner'
import CreateMerchantModal from './CreateMerchantModal';

import FilterDropdown from '../../../sharedComponents/FilterDropdown/FilterDropdown';
import Pagination from '../../../sharedComponents/Pagination/Pagination';
import { listMerchantProductCategoryData, saveCurrentMerchant, updateMerchantList } from '../../../api/merchantSlice'

import filterLogo from '../../../assets/images/filterLogo.svg'
import downloadLogo from '../../../assets/images/downloadLogo.svg'
import createLogo from '../../../assets/images/createLogo.svg'
import upIcon from '../../../assets/images/upIcon.svg';
import { toast } from 'react-hot-toast';

function Merchant() {
    const dispatch = useDispatch();

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [filterMerchants, setFilterMerchants] = useState([]);
    const [currentRecords, setCurrentRecords] = useState([]);
    const [ascSort, setAscSort] = useState([true, true, true, true, true]);
    const [filterFlag, setFilterFlag] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const { loading, error, merchantList, merchantProductCategories } = useSelector(state => state.merchant)

    const tableHeadingClasses = "text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading"

    const tableCellClasses = "min-h-[40px] flex items-center justify-start"

    useEffect(() => {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        let oldMerchants = [...merchantList];

        if (filterMerchants.length > 0) {
            const newMerchants = oldMerchants.filter(handleFilter);
            if (newMerchants.length <= recordsPerPage) {
                setCurrentRecords(oldMerchants);
            } else {
                setCurrentRecords(newMerchants.slice(indexOfFirstRecord, indexOfLastRecord))
            }
        } else {
            setCurrentRecords([...oldMerchants.slice(indexOfFirstRecord, indexOfLastRecord)])
        }
    }, [currentPage, recordsPerPage, loading, merchantList, filterMerchants])

    useEffect(() => {
        if(error && error.length>0) {
            toast.error(error);
        }
        sortMerchant();
        if (merchantProductCategories.length === 0) {
            fetchMerchantProductCategory();
        }
    }, [])

    const sortMerchant = () => {
        let oldSort = [true, true, true, true, true];
        if (ascSort[0]) {
            let oldMerchants = [...merchantList];
            oldMerchants.sort(function (a, b) {
                return a.id.localeCompare(b.id)
            });
            dispatch(updateMerchantList([...oldMerchants]))
            oldSort[0] = false;
            setAscSort([...oldSort]);
        } else {
            let oldMerchants = [...merchantList];
            oldMerchants.sort(function (a, b) {
                return -1 * a.id.localeCompare(b.id)
            });
            dispatch(updateMerchantList([...oldMerchants]))
            oldSort[0] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortMerchantByName = () => {
        let oldSort = [true, true, true, true, true];
        if (ascSort[1]) {
            let oldMerchants = [...merchantList];
            oldMerchants.sort(function (a, b) {
                return a.merchant_name.localeCompare(b.merchant_name)
            });
            dispatch(updateMerchantList([...oldMerchants]))
            oldSort[1] = false;
            setAscSort([...oldSort]);
        } else {
            let oldMerchants = [...merchantList];
            oldMerchants.sort(function (a, b) {
                return -1 * a.merchant_name.localeCompare(b.merchant_name)
            });
            dispatch(updateMerchantList([...oldMerchants]))
            oldSort[1] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortMerchantByShopifyId = () => {
        let oldSort = [true, true, true, true, true];
        if (ascSort[2]) {
            let oldMerchants = [...merchantList];
            oldMerchants.sort(function (a, b) {
                return a.shopifyStoreID.localeCompare(b.shopifyStoreID)
            });
            dispatch(updateMerchantList([...oldMerchants]))
            oldSort[2] = false;
            setAscSort([...oldSort]);
        } else {
            let oldMerchants = [...merchantList];
            oldMerchants.sort(function (a, b) {
                return -1 * a.shopifyStoreID.localeCompare(b.shopifyStoreID)
            });
            dispatch(updateMerchantList([...oldMerchants]))
            oldSort[2] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortMerchantsByDate = (flag = false) => {
        let oldSort = [true, true, true, true, true];
        if (flag ? ascSort[3] : ascSort[4]) {
            let oldMerchants = [...merchantList];
            if(flag) {
                oldMerchants.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
            }else {
                oldMerchants.sort(function (a, b) {
                    return new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateMerchantList([...oldMerchants]))
            if (flag) {
                oldSort[3] = false;
            } else {
                oldSort[4] = false;
            }
            setAscSort([...oldSort]);
        } else {
            let oldMerchants = [...merchantList];
            if (flag) {
                oldMerchants.sort(function (a, b) {
                    return -1 * new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldMerchants.sort(function (a, b) {
                    return -1 * new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateMerchantList([...oldMerchants]))
            if (flag) {
                oldSort[3] = true;
            } else {
                oldSort[4] = true;
            }
            setAscSort([...oldSort]);
        }
    }

    const convertToXLSX = async (data) => {
        try {
            let cleanData = [];
            data.map((ele, id) => {
                let temp = {};
                temp["ID"] = ele["id"] || id;
                temp["Name"] = ele["merchant_name"] || ""
                if (ele.merchantDescription && ele.merchantDescription.length > 10000) {
                    let trimmedDesc = ele.merchantDescription.replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, "").trim();
                    if (trimmedDesc.length <= 25000) {
                        temp["Merchant Description"] = trimmedDesc;
                    } else {
                        temp["Merchant Description"] = trimmedDesc.slice(0, 10000);
                    }
                } else {
                    temp["Merchant Description"] = ele.merchantDescription.replace(/<[^>]+>/g, '').trim()
                }
                temp["Merchant Offer Amount"] = ele["merchantOfferAmount"] || ""
                temp["Background Circle Hex Color"] = ele["backgroundCircleHexColour"] || "#FFFFF"
                temp["Merchant Checkout URL"] = ele["merchant_checkout_url"] || ""
                temp["Afiliate URL"] = ele["afiliateUrl"] || ""
                temp["Shopify Store Saathi SNPL TCs Agreed"] = ele["shopifyStoreSaathiSNPLTCsAgreed"] || false
                temp["Shopify Store ID"] = ele["shopifyStoreID"] || ""
                temp["Merchant Logo URL"] = ele["merchant_logo_url"] || ""
                temp["Merchant Website URL"] = ele["merchantWebsiteURL"] || ""
                temp["SNPLAllowedBySaathi"] = ele["SNPLAllowedBySaathi"] || false
                temp["Merchant Category"] = ele["merchantCategory"] || ""
                temp["Merchant Type"] = ele["merchantType"] || ""
                temp["isMaxOffer"] = ele["isMaxOffer"] || ""
                temp["Background Image URL"] = ele["merchantBackgroundImageURL"] || ""
                temp["Tenant"] = ele["tenant"] || ""
                temp["Offer Type"] = ele["merchantOfferType"] || ""
                temp["Platform"] = ele["platform"] || ""
                temp["Platform Merchant ID"] = ele["platformMerchantId"] || ""
                temp["Add ID to Checkout URL"] = ele["merchant_add_id_to_checkout_url"] || ""
                temp["Offer Title"] = ele["merchantOfferTitle"] || ""
                temp["Created At"] = ele.createdAt ? new Date(ele.createdAt).toLocaleString() : new Date().toLocaleString()
                temp["Updated At"] = ele.createdAt ? new Date(ele.updatedAt).toLocaleString() : new Date().toLocaleString()

                cleanData.push(temp);
            })
            const ws = utils.json_to_sheet(cleanData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "merchants");
            writeFileXLSX(wb, "merchant_data.xlsx");

        } catch (error) {
            console.log(error);
        }
    }

    const handleCurrentMerchant = (data) => {
        dispatch(saveCurrentMerchant(data));
        setEditFlag(true);
        setOpenCreateModal(true);
    }

    const fetchMerchantProductCategory = async () => {
        dispatch(listMerchantProductCategoryData());
    }

    const handleFilter = (merchant) => {
        if (filterMerchants.length === 0) {
            return true;
        }
        const id = filterMerchants.findIndex(e => e === merchant?.merchant_name);
        return id !== -1;
    }

    const handleOpenFilterMenu = () => {
        setShowFilterMenu(prev => !prev);
    }

    return (
        <>
            {(loading || currentRecords.length === 0) ?
                <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <TailSpin
                        height="120"
                        width="120"
                        color="#62A6FF"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        visible={true}
                    />
                </div> : (
                    <div className="container max-w-full">
                        <div className='w-full flex justify-between items-center min-w-max'>
                            <h1 className='text-[32px] font-bold m-[40px]'>Merchants</h1>
                            <div className='w-[200px] mr-10 flex justify-around'>
                                <div className='cursor-pointer'>
                                    <img onClick={handleOpenFilterMenu} src={filterLogo} alt="" />
                                    <FilterDropdown
                                        filterItems={filterMerchants}
                                        setFilterItems={setFilterMerchants}
                                        showFilterMenu={showFilterMenu}
                                        type="Merchant"
                                    />
                                </div>
                                <div className='cursor-pointer'>
                                    <img onClick={() => convertToXLSX(currentRecords.filter(handleFilter))} src={downloadLogo} alt="" />
                                </div>
                                <div className='cursor-pointer' onClick={() => setOpenCreateModal(prev => !prev)}>
                                    <img src={createLogo} alt="" />
                                </div>
                            </div>
                        </div>

                        <div id="merchant_table" className='flex flex-col justify-center text-center overflow-x-auto flex-1 overflow-y-hidden pb-12 min-w-max'>
                            <>
                                <div className='w-full flex'>
                                    <div className={`w-[35vw] lg:w-[20vw] ${tableHeadingClasses} justify-center ml-10 pl-4 rounded-l-lg`}>
                                        ID
                                        <img onClick={sortMerchant} className={`ml-2 ${ascSort[0] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[35vw] lg:w-[15vw] pl-10 text-sm text-darkGray font-bold h-[51px] flex items-center bg-tableHeading`}>
                                        Name
                                        <img onClick={sortMerchantByName} className={`ml-2 ${ascSort[1] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-end sm:justify-center bg-tableHeading`}>
                                        Logo
                                    </div>
                                    <div className={`w-[60vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Checkout URL
                                    </div>
                                    <div className={`w-[60vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Affiliate URL
                                    </div>
                                    <div className={`w-[60vw] lg:w-[30vw] ${tableHeadingClasses}`}>
                                        ID - Checkout URL
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Type
                                    </div>
                                    <div className={`w-[75vw] lg:w-[25vw] ${tableHeadingClasses}`}>
                                        Description
                                    </div>
                                    <div className={`w-[35vw] lg:w-[13vw] ${tableHeadingClasses}`}>
                                        Offer Type
                                    </div>
                                    <div className={`w-[35vw] lg:w-[13vw] ${tableHeadingClasses}`}>
                                        Offer Amount
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Shopify ID
                                        {/* <img onClick={sortMerchantByShopifyId} className={`ml-2 ${ascSort[2] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" /> */}
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Shopify Terms
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        isReviewed
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        isMaxOffer
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        SNPL Allowed
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Tenant
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Added
                                        <img onClick={() => sortMerchantsByDate(true)} className={`ml-2 ${ascSort[3] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses} mr-10 rounded-r-lg`}>
                                        Updated
                                        <img onClick={sortMerchantsByDate} className={`ml-2 ${ascSort[4] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                </div>

                                {merchantList && currentRecords.filter(handleFilter).map((ele, i) => {
                                    if (ele === null || ele === undefined) {
                                        return;
                                    }
                                    const { merchant_name,
                                        merchant_logo_url,
                                        merchant_checkout_url,
                                        merchantType,
                                        createdAt,
                                        afiliateUrl,
                                        shopifyStoreID,
                                        backgroundCircleHexColour,
                                        merchantDescription,
                                        merchantOfferType,
                                        merchantOfferAmount,
                                        isReviewed,
                                        shopifyStoreSaathiSNPLTCsAgreed,
                                        SNPLAllowedBySaathi,
                                        isMaxOffer,
                                        tenant,
                                        merchant_add_id_to_checkout_url,
                                        id,
                                        updatedAt
                                    } = ele
                                    const parser = new DOMParser();
                                    const desc = parser.parseFromString(merchantDescription, "text/html")
                                    if (!afiliateUrl) {
                                        return (<></>)
                                    }
                                    // const index = recordsPerPage * (currentPage - 1) + (id + 1);
                                    return (
                                        <div className='w-full flex my-3'>
                                            <div onClick={() => handleCurrentMerchant(ele)} className={`w-[35vw] lg:w-[20vw] cursor-pointer underline pl-4 text-blue-500 ${tableCellClasses} ml-10`}>
                                                <div className='w-[90%] overflow-x-auto'>
                                                    {id}
                                                </div>
                                            </div>
                                            <div className={`w-[35vw] lg:w-[15vw] pl-10 flex items-center min-h-[40px]`}>
                                                {merchant_name}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] min-h-[40px] flex items-center justify-end sm:justify-center`}>
                                                <img style={{ backgroundColor: backgroundCircleHexColour || "#FFFFFF" }} src={merchant_logo_url} height={40} width={40} alt="" />
                                            </div>
                                            <div className={`w-[60vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                <a href={merchant_checkout_url} target="_blank" style={{ width: "90%" }} className='truncate ml-2 text-left'>
                                                    {merchant_checkout_url}
                                                </a>
                                            </div>
                                            <div className={`w-[60vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                <a href={afiliateUrl} target="_blank" style={{ width: "90%" }} className='truncate ml-2 text-left'>
                                                    {afiliateUrl}
                                                </a>
                                            </div>
                                            <div className={`w-[60vw] lg:w-[30vw] ${tableCellClasses}`}>
                                                <a href={merchant_add_id_to_checkout_url} target="_blank" style={{ width: "90%" }} className='truncate ml-2 text-left'>
                                                    {merchant_add_id_to_checkout_url}
                                                </a>
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {merchantType}
                                            </div>
                                            <div style={{ justifyContent: "flex-start" }} className={`w-[75vw] lg:w-[25vw] p-9 lg:p-0 ${tableCellClasses} text-justify`}>
                                                <div style={{ width: "95%", maxHeight: "300px" }} className='overflow-auto whitespace-pre-line'>
                                                    {desc.documentElement.textContent.trim()}
                                                </div>
                                            </div>
                                            <div className={`w-[35vw] lg:w-[13vw] ${tableCellClasses}`}>
                                                {merchantOfferType}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[13vw] ${tableCellClasses}`}>
                                                {merchantOfferAmount}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses} text-left`}>
                                                {shopifyStoreID}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {shopifyStoreSaathiSNPLTCsAgreed ? "True" : "False"}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {isReviewed ? "True" : "False"}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {isMaxOffer ? "True" : "False"}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {SNPLAllowedBySaathi ? "True" : "False"}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {tenant ? tenant : "-"}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {new Date(createdAt).toLocaleDateString()}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses} mr-10`}>
                                                {updatedAt ? new Date(updatedAt).toLocaleDateString() : new Date().toLocaleDateString()}
                                            </div>
                                        </div>
                                    )
                                })}

                            </>
                        </div>

                        {/* Create Merchant Modal */}
                        <Transition appear show={openCreateModal} as={Fragment}>
                            <Dialog as="div" className="relative z-10" onClose={() => setOpenCreateModal(false)}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                                </Transition.Child>

                                <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full justify-center p-4">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Dialog.Panel className="transition-all">
                                                <CreateMerchantModal editFlag={editFlag} setEditFlag={setEditFlag} setOpenCreateModal={setOpenCreateModal} openCreateModal={openCreateModal} />
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition>

                        <Pagination
                            size={merchantList.filter(handleFilter).length || 0}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setRecordsPerPage={setRecordsPerPage}
                            recordsPerPage={recordsPerPage}
                        />
                    </div>
                )}
        </>
    )
}

export default Merchant