/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      OrgName
      OrgLogo
      OrgEmail
      HREmailAddresses
      RequestLargeAdvanceEmailAddresses
      OrgStatus
      OrgSystemFreezeStatus
      OrgWithdrawalCycle
      OrgCurrency
      OrgApprovalFlow
      OrgPayCheckDay
      OrgKYCFlag
      OrgTNCFlag
      Onboard
      EmpBasicInfo
      EmpBenefitSettings
      EmpInvited
      LendingApproach
      ChargeType
      BankName
      BankAccountNumber
      BankIFSC
      PaymentMode
      RegisteredAddress
      CompanyPhoneNumber
      CreatedAt
      ModifiedAt
      ApproveEmpUpdates
      AreWithdrawalsDownForMaintenance
      EmailWithdraw
      AutoApproveWithdrawals
      MaxLimitPercentAutoApproveWithdrawals
      TNCRequiredByEmployees
      KYCRequiredByEmployees
      TermsSource
      TermsURL
      EmployeeProbationPeriodMonths
      Product
      SingleWithdrawalDayOrSetPeriod
      SingleWithdrawalDayOfMonth
      OrgWithdrawalStartDay
      OrgWithdrawalEndDay
      FeeFreeBoolean
      PaymentFileOut
      PaymentFileDay
      PaymentFileHour
      ApprovalFlow
      MetricFlow
      tenant
      autoMarkWithdrawalsAsRepaidMonthsEnd
      employer {
        nextToken
      }
      blackoutPeriod {
        nextToken
      }
      growQuestionnaireResults {
        nextToken
      }
      monthlyReportMetrics {
        nextToken
      }
      orgCurrentCycleCalcs {
        orgId
        discount
        withdrawalPeriodStart
        withdrawalPeriodEnd
        payDate
        potentialBlackoutPercentage
        isWithdrawalPeriodOpen
        tenant
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      OrgName
      OrgLogo
      OrgEmail
      HREmailAddresses
      RequestLargeAdvanceEmailAddresses
      OrgStatus
      OrgSystemFreezeStatus
      OrgWithdrawalCycle
      OrgCurrency
      OrgApprovalFlow
      OrgPayCheckDay
      OrgKYCFlag
      OrgTNCFlag
      Onboard
      EmpBasicInfo
      EmpBenefitSettings
      EmpInvited
      LendingApproach
      ChargeType
      BankName
      BankAccountNumber
      BankIFSC
      PaymentMode
      RegisteredAddress
      CompanyPhoneNumber
      CreatedAt
      ModifiedAt
      ApproveEmpUpdates
      AreWithdrawalsDownForMaintenance
      EmailWithdraw
      AutoApproveWithdrawals
      MaxLimitPercentAutoApproveWithdrawals
      TNCRequiredByEmployees
      KYCRequiredByEmployees
      TermsSource
      TermsURL
      EmployeeProbationPeriodMonths
      Product
      SingleWithdrawalDayOrSetPeriod
      SingleWithdrawalDayOfMonth
      OrgWithdrawalStartDay
      OrgWithdrawalEndDay
      FeeFreeBoolean
      PaymentFileOut
      PaymentFileDay
      PaymentFileHour
      ApprovalFlow
      MetricFlow
      tenant
      autoMarkWithdrawalsAsRepaidMonthsEnd
      employer {
        nextToken
      }
      blackoutPeriod {
        nextToken
      }
      growQuestionnaireResults {
        nextToken
      }
      monthlyReportMetrics {
        nextToken
      }
      orgCurrentCycleCalcs {
        orgId
        discount
        withdrawalPeriodStart
        withdrawalPeriodEnd
        payDate
        potentialBlackoutPercentage
        isWithdrawalPeriodOpen
        tenant
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      OrgName
      OrgLogo
      OrgEmail
      HREmailAddresses
      RequestLargeAdvanceEmailAddresses
      OrgStatus
      OrgSystemFreezeStatus
      OrgWithdrawalCycle
      OrgCurrency
      OrgApprovalFlow
      OrgPayCheckDay
      OrgKYCFlag
      OrgTNCFlag
      Onboard
      EmpBasicInfo
      EmpBenefitSettings
      EmpInvited
      LendingApproach
      ChargeType
      BankName
      BankAccountNumber
      BankIFSC
      PaymentMode
      RegisteredAddress
      CompanyPhoneNumber
      CreatedAt
      ModifiedAt
      ApproveEmpUpdates
      AreWithdrawalsDownForMaintenance
      EmailWithdraw
      AutoApproveWithdrawals
      MaxLimitPercentAutoApproveWithdrawals
      TNCRequiredByEmployees
      KYCRequiredByEmployees
      TermsSource
      TermsURL
      EmployeeProbationPeriodMonths
      Product
      SingleWithdrawalDayOrSetPeriod
      SingleWithdrawalDayOfMonth
      OrgWithdrawalStartDay
      OrgWithdrawalEndDay
      FeeFreeBoolean
      PaymentFileOut
      PaymentFileDay
      PaymentFileHour
      ApprovalFlow
      MetricFlow
      tenant
      autoMarkWithdrawalsAsRepaidMonthsEnd
      employer {
        nextToken
      }
      blackoutPeriod {
        nextToken
      }
      growQuestionnaireResults {
        nextToken
      }
      monthlyReportMetrics {
        nextToken
      }
      orgCurrentCycleCalcs {
        orgId
        discount
        withdrawalPeriodStart
        withdrawalPeriodEnd
        payDate
        potentialBlackoutPercentage
        isWithdrawalPeriodOpen
        tenant
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBlackoutPeriod = /* GraphQL */ `
  mutation CreateBlackoutPeriod(
    $input: CreateBlackoutPeriodInput!
    $condition: ModelBlackoutPeriodConditionInput
  ) {
    createBlackoutPeriod(input: $input, condition: $condition) {
      id
      OrgId
      startDate
      endDate
      chargeLimitPercent
      benefitDisabled
      createdBy
      description
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateBlackoutPeriod = /* GraphQL */ `
  mutation UpdateBlackoutPeriod(
    $input: UpdateBlackoutPeriodInput!
    $condition: ModelBlackoutPeriodConditionInput
  ) {
    updateBlackoutPeriod(input: $input, condition: $condition) {
      id
      OrgId
      startDate
      endDate
      chargeLimitPercent
      benefitDisabled
      createdBy
      description
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlackoutPeriod = /* GraphQL */ `
  mutation DeleteBlackoutPeriod(
    $input: DeleteBlackoutPeriodInput!
    $condition: ModelBlackoutPeriodConditionInput
  ) {
    deleteBlackoutPeriod(input: $input, condition: $condition) {
      id
      OrgId
      startDate
      endDate
      chargeLimitPercent
      benefitDisabled
      createdBy
      description
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateOrgNotifications = /* GraphQL */ `
  mutation UpdateOrgNotifications(
    $input: UpdateOrgNotificationsInput!
    $condition: ModelOrgNotificationsConditionInput
  ) {
    updateOrgNotifications(input: $input, condition: $condition) {
      id
      OrgId
      createdAt
      Description
      DisplayTo
      ActionType
      Title
      MarkedReadBy
      TaskId
      TaskCompleted
      tenant
      updatedAt
    }
  }
`;
export const deleteOrgNotifications = /* GraphQL */ `
  mutation DeleteOrgNotifications(
    $input: DeleteOrgNotificationsInput!
    $condition: ModelOrgNotificationsConditionInput
  ) {
    deleteOrgNotifications(input: $input, condition: $condition) {
      id
      OrgId
      createdAt
      Description
      DisplayTo
      ActionType
      Title
      MarkedReadBy
      TaskId
      TaskCompleted
      tenant
      updatedAt
    }
  }
`;
export const createEmployer = /* GraphQL */ `
  mutation CreateEmployer(
    $input: CreateEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    createEmployer(input: $input, condition: $condition) {
      id
      OrgId
      EmpId
      EmpDesignation
      EmpJoiningDate
      EmpEmail
      EmpCountryCode
      EmpMobileNo
      EmpPassword
      EmpGivenName
      EmpFamilyName
      EmpRole
      EmpDashBoardStatus
      EmpOnBordingStatus
      EmpFirstTimeLoginFlag
      EmpStatus
      LastLoggedIn
      ProfileLock
      NoOfAttempt
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      InAppRepayment
      InAppWithdraw
      InAppDeduction
      InAppPayroll
      EmailRepayment
      EmailWithdraw
      EmailDeduction
      EmailPayroll
      owner
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployer = /* GraphQL */ `
  mutation UpdateEmployer(
    $input: UpdateEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    updateEmployer(input: $input, condition: $condition) {
      id
      OrgId
      EmpId
      EmpDesignation
      EmpJoiningDate
      EmpEmail
      EmpCountryCode
      EmpMobileNo
      EmpPassword
      EmpGivenName
      EmpFamilyName
      EmpRole
      EmpDashBoardStatus
      EmpOnBordingStatus
      EmpFirstTimeLoginFlag
      EmpStatus
      LastLoggedIn
      ProfileLock
      NoOfAttempt
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      InAppRepayment
      InAppWithdraw
      InAppDeduction
      InAppPayroll
      EmailRepayment
      EmailWithdraw
      EmailDeduction
      EmailPayroll
      owner
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployer = /* GraphQL */ `
  mutation DeleteEmployer(
    $input: DeleteEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    deleteEmployer(input: $input, condition: $condition) {
      id
      OrgId
      EmpId
      EmpDesignation
      EmpJoiningDate
      EmpEmail
      EmpCountryCode
      EmpMobileNo
      EmpPassword
      EmpGivenName
      EmpFamilyName
      EmpRole
      EmpDashBoardStatus
      EmpOnBordingStatus
      EmpFirstTimeLoginFlag
      EmpStatus
      LastLoggedIn
      ProfileLock
      NoOfAttempt
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      InAppRepayment
      InAppWithdraw
      InAppDeduction
      InAppPayroll
      EmailRepayment
      EmailWithdraw
      EmailDeduction
      EmailPayroll
      owner
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      OrgId
      UsrEmpId
      UsrDesignation
      UsrGrade
      UsrJoiningDate
      UsrEmail
      UsrMobileNo
      UsrWithdrawalLimit
      UsrGivenName
      UsrMiddleName
      UsrFamilyName
      fathersFirstName
      fathersMiddleName
      fathersLastName
      spouseFirstName
      spouseMiddleName
      spouseLastName
      mothersFirstName
      mothersMiddleName
      mothersLastName
      UsrBankAccount
      UsrBankName
      UsrBankIFSCCode
      UsrBenefitStatus
      UsrInviteEmail
      UsrConsentAcceptance
      UsrConsentTS
      UsrTCAcceptance
      UsrTCAcceptanceTS
      TCAcceptanceIP
      ConsentAgreed
      ConsentTimeStamp
      ConsentIP
      UsrKYCStatus
      UsrRegJourneyStatus
      UsrFirstTimeLoginFlag
      UsrStatus
      UsrWithdrawalCycle
      ProfileLock
      NoOfAttempt
      CreatedBy
      ModifiedBy
      LastLoggedIn
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      Invited
      Currency
      LimitAsPercent
      Salary
      JoiningDate
      DeactivationReason
      DeactivationDate
      lbUserId
      lbRegistered
      lbSign
      lbLoanId
      lbLoanDate
      lbContractDate
      lbAgreementIP
      limitUpdateFlag
      hasUserCompletedGrowQuestionnaire
      Title
      Gender
      FatherName
      PAN
      AADHAR
      State
      City
      Street1
      Street2
      PostCode
      addressCountry
      addressBuildingNumber
      addressHouse
      addressLandmark
      DateOfBirth
      owner
      language
      lendingHistory
      newWithdrawalLimit
      isOnProbation
      MatchRateDoc_1
      MatchRateDoc_2
      WorkedDaysInMonth
      WorkedDaysUpdatedAt
      WorkedHoursInMonth
      WorkedHoursUpdatedAt
      Department
      CountryCode
      GrossSalary
      DeductionAmount
      DeductionAmountPercent
      EMI
      enrolledBoolean
      managerId
      managerEmail
      useBiometrics
      aadhaarFrontReadByOCR
      aadhaarBackReadByOCR
      panReadByOCR
      aadhaarPassedAuthCheck
      aadhaarPassedOTPCheck
      faceIDPasssed
      panCheckPassed
      threeWayCheckPassed
      pushNotifications {
        nextToken
      }
      communicationPreferences {
        id
        OrgId
        allowSaathiMarketing
        allowPartnerMarketing
        allowPushNotifications
        allowSMSNotification
        allowEmailNotifications
        allowVerificationBySMS
        allowVerificationByEmail
        createdAt
        updatedAt
        tenant
        owner
      }
      empCurrentCycleCalcs {
        empId
        owner
        totalEarnedSalary
        spentEarnedSalary
        quantityOfWithdrawalsTaken
        totalValueOfAutoApprovals
        tenant
        createdAt
        updatedAt
      }
      tenant
      snplBankCustomerId
      snplBankSavingsAccountNumber
      snplBankSavingsIFSCCode
      snplBankSavingsBranch
      snplBankKYCProgress
      SavingsTermsAndConditionsTS
      SavingsTermsAndConditionsAcceptance
      SavingsTermsAndConditionsIP
      cashfreeBeneficiaryId
      cashfreeVendorId
      MaritalStatus
      ProfilePictureURL
      registeredInApp
      registeredInAppDTS
      nationality
      PEPBool
      goals {
        nextToken
      }
      FCMRegToken
      snplBankAccountTypeCode
      snplBankAccountTypeDisplayName
      snplDebitCardName
      snplBankServices
      snplDeclarations
      snplNomineeRequiredBool
      snplBankName
      snplSavingsLimit
      snplBankAccountDateOpenedDTS
      snplAverageBalance
      economicCategory
      educationalQualification
      occupation
      annualIncome
      mothersMaidenName
      communicationAddress
      isFoundersClubMember
      CustomerSavingsAccount {
        userId
        TotalDailyAccruedBalance
        AvailableToSpendBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        linkedGoalsArray
        type
        savingsAccountStartDate
        createdAt
        updatedAt
      }
      usedReferralCode
      Referral {
        userId
        referralCode
        maxReferrals
        referralCount
        userReferralOffer
        userReferralOfferType
        refereeOffer
        refereeOfferType
        isEnabled
        referred
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      OrgId
      UsrEmpId
      UsrDesignation
      UsrGrade
      UsrJoiningDate
      UsrEmail
      UsrMobileNo
      UsrWithdrawalLimit
      UsrGivenName
      UsrMiddleName
      UsrFamilyName
      fathersFirstName
      fathersMiddleName
      fathersLastName
      spouseFirstName
      spouseMiddleName
      spouseLastName
      mothersFirstName
      mothersMiddleName
      mothersLastName
      UsrBankAccount
      UsrBankName
      UsrBankIFSCCode
      UsrBenefitStatus
      UsrInviteEmail
      UsrConsentAcceptance
      UsrConsentTS
      UsrTCAcceptance
      UsrTCAcceptanceTS
      TCAcceptanceIP
      ConsentAgreed
      ConsentTimeStamp
      ConsentIP
      UsrKYCStatus
      UsrRegJourneyStatus
      UsrFirstTimeLoginFlag
      UsrStatus
      UsrWithdrawalCycle
      ProfileLock
      NoOfAttempt
      CreatedBy
      ModifiedBy
      LastLoggedIn
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      Invited
      Currency
      LimitAsPercent
      Salary
      JoiningDate
      DeactivationReason
      DeactivationDate
      lbUserId
      lbRegistered
      lbSign
      lbLoanId
      lbLoanDate
      lbContractDate
      lbAgreementIP
      limitUpdateFlag
      hasUserCompletedGrowQuestionnaire
      Title
      Gender
      FatherName
      PAN
      AADHAR
      State
      City
      Street1
      Street2
      PostCode
      addressCountry
      addressBuildingNumber
      addressHouse
      addressLandmark
      DateOfBirth
      owner
      language
      lendingHistory
      newWithdrawalLimit
      isOnProbation
      MatchRateDoc_1
      MatchRateDoc_2
      WorkedDaysInMonth
      WorkedDaysUpdatedAt
      WorkedHoursInMonth
      WorkedHoursUpdatedAt
      Department
      CountryCode
      GrossSalary
      DeductionAmount
      DeductionAmountPercent
      EMI
      enrolledBoolean
      managerId
      managerEmail
      useBiometrics
      aadhaarFrontReadByOCR
      aadhaarBackReadByOCR
      panReadByOCR
      aadhaarPassedAuthCheck
      aadhaarPassedOTPCheck
      faceIDPasssed
      panCheckPassed
      threeWayCheckPassed
      pushNotifications {
        nextToken
      }
      communicationPreferences {
        id
        OrgId
        allowSaathiMarketing
        allowPartnerMarketing
        allowPushNotifications
        allowSMSNotification
        allowEmailNotifications
        allowVerificationBySMS
        allowVerificationByEmail
        createdAt
        updatedAt
        tenant
        owner
      }
      empCurrentCycleCalcs {
        empId
        owner
        totalEarnedSalary
        spentEarnedSalary
        quantityOfWithdrawalsTaken
        totalValueOfAutoApprovals
        tenant
        createdAt
        updatedAt
      }
      tenant
      snplBankCustomerId
      snplBankSavingsAccountNumber
      snplBankSavingsIFSCCode
      snplBankSavingsBranch
      snplBankKYCProgress
      SavingsTermsAndConditionsTS
      SavingsTermsAndConditionsAcceptance
      SavingsTermsAndConditionsIP
      cashfreeBeneficiaryId
      cashfreeVendorId
      MaritalStatus
      ProfilePictureURL
      registeredInApp
      registeredInAppDTS
      nationality
      PEPBool
      goals {
        nextToken
      }
      FCMRegToken
      snplBankAccountTypeCode
      snplBankAccountTypeDisplayName
      snplDebitCardName
      snplBankServices
      snplDeclarations
      snplNomineeRequiredBool
      snplBankName
      snplSavingsLimit
      snplBankAccountDateOpenedDTS
      snplAverageBalance
      economicCategory
      educationalQualification
      occupation
      annualIncome
      mothersMaidenName
      communicationAddress
      isFoundersClubMember
      CustomerSavingsAccount {
        userId
        TotalDailyAccruedBalance
        AvailableToSpendBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        linkedGoalsArray
        type
        savingsAccountStartDate
        createdAt
        updatedAt
      }
      usedReferralCode
      Referral {
        userId
        referralCode
        maxReferrals
        referralCount
        userReferralOffer
        userReferralOfferType
        refereeOffer
        refereeOfferType
        isEnabled
        referred
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      OrgId
      UsrEmpId
      UsrDesignation
      UsrGrade
      UsrJoiningDate
      UsrEmail
      UsrMobileNo
      UsrWithdrawalLimit
      UsrGivenName
      UsrMiddleName
      UsrFamilyName
      fathersFirstName
      fathersMiddleName
      fathersLastName
      spouseFirstName
      spouseMiddleName
      spouseLastName
      mothersFirstName
      mothersMiddleName
      mothersLastName
      UsrBankAccount
      UsrBankName
      UsrBankIFSCCode
      UsrBenefitStatus
      UsrInviteEmail
      UsrConsentAcceptance
      UsrConsentTS
      UsrTCAcceptance
      UsrTCAcceptanceTS
      TCAcceptanceIP
      ConsentAgreed
      ConsentTimeStamp
      ConsentIP
      UsrKYCStatus
      UsrRegJourneyStatus
      UsrFirstTimeLoginFlag
      UsrStatus
      UsrWithdrawalCycle
      ProfileLock
      NoOfAttempt
      CreatedBy
      ModifiedBy
      LastLoggedIn
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      Invited
      Currency
      LimitAsPercent
      Salary
      JoiningDate
      DeactivationReason
      DeactivationDate
      lbUserId
      lbRegistered
      lbSign
      lbLoanId
      lbLoanDate
      lbContractDate
      lbAgreementIP
      limitUpdateFlag
      hasUserCompletedGrowQuestionnaire
      Title
      Gender
      FatherName
      PAN
      AADHAR
      State
      City
      Street1
      Street2
      PostCode
      addressCountry
      addressBuildingNumber
      addressHouse
      addressLandmark
      DateOfBirth
      owner
      language
      lendingHistory
      newWithdrawalLimit
      isOnProbation
      MatchRateDoc_1
      MatchRateDoc_2
      WorkedDaysInMonth
      WorkedDaysUpdatedAt
      WorkedHoursInMonth
      WorkedHoursUpdatedAt
      Department
      CountryCode
      GrossSalary
      DeductionAmount
      DeductionAmountPercent
      EMI
      enrolledBoolean
      managerId
      managerEmail
      useBiometrics
      aadhaarFrontReadByOCR
      aadhaarBackReadByOCR
      panReadByOCR
      aadhaarPassedAuthCheck
      aadhaarPassedOTPCheck
      faceIDPasssed
      panCheckPassed
      threeWayCheckPassed
      pushNotifications {
        nextToken
      }
      communicationPreferences {
        id
        OrgId
        allowSaathiMarketing
        allowPartnerMarketing
        allowPushNotifications
        allowSMSNotification
        allowEmailNotifications
        allowVerificationBySMS
        allowVerificationByEmail
        createdAt
        updatedAt
        tenant
        owner
      }
      empCurrentCycleCalcs {
        empId
        owner
        totalEarnedSalary
        spentEarnedSalary
        quantityOfWithdrawalsTaken
        totalValueOfAutoApprovals
        tenant
        createdAt
        updatedAt
      }
      tenant
      snplBankCustomerId
      snplBankSavingsAccountNumber
      snplBankSavingsIFSCCode
      snplBankSavingsBranch
      snplBankKYCProgress
      SavingsTermsAndConditionsTS
      SavingsTermsAndConditionsAcceptance
      SavingsTermsAndConditionsIP
      cashfreeBeneficiaryId
      cashfreeVendorId
      MaritalStatus
      ProfilePictureURL
      registeredInApp
      registeredInAppDTS
      nationality
      PEPBool
      goals {
        nextToken
      }
      FCMRegToken
      snplBankAccountTypeCode
      snplBankAccountTypeDisplayName
      snplDebitCardName
      snplBankServices
      snplDeclarations
      snplNomineeRequiredBool
      snplBankName
      snplSavingsLimit
      snplBankAccountDateOpenedDTS
      snplAverageBalance
      economicCategory
      educationalQualification
      occupation
      annualIncome
      mothersMaidenName
      communicationAddress
      isFoundersClubMember
      CustomerSavingsAccount {
        userId
        TotalDailyAccruedBalance
        AvailableToSpendBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        linkedGoalsArray
        type
        savingsAccountStartDate
        createdAt
        updatedAt
      }
      usedReferralCode
      Referral {
        userId
        referralCode
        maxReferrals
        referralCount
        userReferralOffer
        userReferralOfferType
        refereeOffer
        refereeOfferType
        isEnabled
        referred
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserBankAccount = /* GraphQL */ `
  mutation CreateUserBankAccount(
    $input: CreateUserBankAccountInput!
    $condition: ModelUserBankAccountConditionInput
  ) {
    createUserBankAccount(input: $input, condition: $condition) {
      id
      userId
      accountNumber
      ifsc
      accountHolderName
      bankName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserBankAccount = /* GraphQL */ `
  mutation UpdateUserBankAccount(
    $input: UpdateUserBankAccountInput!
    $condition: ModelUserBankAccountConditionInput
  ) {
    updateUserBankAccount(input: $input, condition: $condition) {
      id
      userId
      accountNumber
      ifsc
      accountHolderName
      bankName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserBankAccount = /* GraphQL */ `
  mutation DeleteUserBankAccount(
    $input: DeleteUserBankAccountInput!
    $condition: ModelUserBankAccountConditionInput
  ) {
    deleteUserBankAccount(input: $input, condition: $condition) {
      id
      userId
      accountNumber
      ifsc
      accountHolderName
      bankName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTransationService = /* GraphQL */ `
  mutation CreateTransationService(
    $input: CreateTransationServiceInput!
    $condition: ModelTransationServiceConditionInput
  ) {
    createTransationService(input: $input, condition: $condition) {
      id
      createdAt
      UserId
      OrgId
      TxnRefNo
      AvailableLimit
      AdvanceAmount
      ConvineneceFee
      DeductionAmount
      FundTransferStatus
      RepaymentSatus
      ReasonDesc
      ApprovalStatus
      RejReason
      DeductionDate
      AmountReceivedUpdatedBy
      ApprovedBy
      ApprovedDateTime
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      lbLoanId
      lbUserId
      leaveTaken
      employeeConsent
      receivedByLendbox
      History
      amountChange
      ApprovalFlow
      amountRequested
      exception
      tenant
      owner
      updatedAt
    }
  }
`;
export const deleteTransationService = /* GraphQL */ `
  mutation DeleteTransationService(
    $input: DeleteTransationServiceInput!
    $condition: ModelTransationServiceConditionInput
  ) {
    deleteTransationService(input: $input, condition: $condition) {
      id
      createdAt
      UserId
      OrgId
      TxnRefNo
      AvailableLimit
      AdvanceAmount
      ConvineneceFee
      DeductionAmount
      FundTransferStatus
      RepaymentSatus
      ReasonDesc
      ApprovalStatus
      RejReason
      DeductionDate
      AmountReceivedUpdatedBy
      ApprovedBy
      ApprovedDateTime
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      lbLoanId
      lbUserId
      leaveTaken
      employeeConsent
      receivedByLendbox
      History
      amountChange
      ApprovalFlow
      amountRequested
      exception
      tenant
      owner
      updatedAt
    }
  }
`;
export const updatePushNotification = /* GraphQL */ `
  mutation UpdatePushNotification(
    $input: UpdatePushNotificationInput!
    $condition: ModelPushNotificationConditionInput
  ) {
    updatePushNotification(input: $input, condition: $condition) {
      id
      UsrId
      createdAt
      UsrMobileNo
      UsrEmail
      NotDeviceID
      NotStatus
      NotType
      NotSubType
      NotSentDT
      NotSentDTS
      Priority
      Title
      Subtitle
      MainText
      TxtField1
      TxtField2
      TxtField3
      AppAction
      CreatedAt
      ModifiedAt
      Checked
      tenant
      updatedAt
    }
  }
`;
export const deletePushNotification = /* GraphQL */ `
  mutation DeletePushNotification(
    $input: DeletePushNotificationInput!
    $condition: ModelPushNotificationConditionInput
  ) {
    deletePushNotification(input: $input, condition: $condition) {
      id
      UsrId
      createdAt
      UsrMobileNo
      UsrEmail
      NotDeviceID
      NotStatus
      NotType
      NotSubType
      NotSentDT
      NotSentDTS
      Priority
      Title
      Subtitle
      MainText
      TxtField1
      TxtField2
      TxtField3
      AppAction
      CreatedAt
      ModifiedAt
      Checked
      tenant
      updatedAt
    }
  }
`;
export const createRepaymentDetails = /* GraphQL */ `
  mutation CreateRepaymentDetails(
    $input: CreateRepaymentDetailsInput!
    $condition: ModelRepaymentDetailsConditionInput
  ) {
    createRepaymentDetails(input: $input, condition: $condition) {
      id
      OrgId
      TxnRefNo
      TotAdvanceAmount
      TotConvenienceFee
      TotDeductionAmount
      RepaymentSatus
      DeductionDate
      PayTransferDate
      MarkedPaidBy
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateRepaymentDetails = /* GraphQL */ `
  mutation UpdateRepaymentDetails(
    $input: UpdateRepaymentDetailsInput!
    $condition: ModelRepaymentDetailsConditionInput
  ) {
    updateRepaymentDetails(input: $input, condition: $condition) {
      id
      OrgId
      TxnRefNo
      TotAdvanceAmount
      TotConvenienceFee
      TotDeductionAmount
      RepaymentSatus
      DeductionDate
      PayTransferDate
      MarkedPaidBy
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteRepaymentDetails = /* GraphQL */ `
  mutation DeleteRepaymentDetails(
    $input: DeleteRepaymentDetailsInput!
    $condition: ModelRepaymentDetailsConditionInput
  ) {
    deleteRepaymentDetails(input: $input, condition: $condition) {
      id
      OrgId
      TxnRefNo
      TotAdvanceAmount
      TotConvenienceFee
      TotDeductionAmount
      RepaymentSatus
      DeductionDate
      PayTransferDate
      MarkedPaidBy
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createEmployeeProfileAudit = /* GraphQL */ `
  mutation CreateEmployeeProfileAudit(
    $input: CreateEmployeeProfileAuditInput!
    $condition: ModelEmployeeProfileAuditConditionInput
  ) {
    createEmployeeProfileAudit(input: $input, condition: $condition) {
      id
      OrgId
      EmpId
      DetailsUpdatedAt
      DetailsUpdatedBy
      ListOfDetailsChanged
      ApprovalStatus
      ApprovedAt
      ApprovedBy
      RejectedAt
      RejectedBy
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployeeProfileAudit = /* GraphQL */ `
  mutation UpdateEmployeeProfileAudit(
    $input: UpdateEmployeeProfileAuditInput!
    $condition: ModelEmployeeProfileAuditConditionInput
  ) {
    updateEmployeeProfileAudit(input: $input, condition: $condition) {
      id
      OrgId
      EmpId
      DetailsUpdatedAt
      DetailsUpdatedBy
      ListOfDetailsChanged
      ApprovalStatus
      ApprovedAt
      ApprovedBy
      RejectedAt
      RejectedBy
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployeeProfileAudit = /* GraphQL */ `
  mutation DeleteEmployeeProfileAudit(
    $input: DeleteEmployeeProfileAuditInput!
    $condition: ModelEmployeeProfileAuditConditionInput
  ) {
    deleteEmployeeProfileAudit(input: $input, condition: $condition) {
      id
      OrgId
      EmpId
      DetailsUpdatedAt
      DetailsUpdatedBy
      ListOfDetailsChanged
      ApprovalStatus
      ApprovedAt
      ApprovedBy
      RejectedAt
      RejectedBy
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createEmployeeDetailsUpdateTask = /* GraphQL */ `
  mutation CreateEmployeeDetailsUpdateTask(
    $input: CreateEmployeeDetailsUpdateTaskInput!
    $condition: ModelEmployeeDetailsUpdateTaskConditionInput
  ) {
    createEmployeeDetailsUpdateTask(input: $input, condition: $condition) {
      id
      GUID
      OrgId
      EmpId
      RequestedAt
      RequestedBy
      ListOfDetailsToBeChanged
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployeeDetailsUpdateTask = /* GraphQL */ `
  mutation UpdateEmployeeDetailsUpdateTask(
    $input: UpdateEmployeeDetailsUpdateTaskInput!
    $condition: ModelEmployeeDetailsUpdateTaskConditionInput
  ) {
    updateEmployeeDetailsUpdateTask(input: $input, condition: $condition) {
      id
      GUID
      OrgId
      EmpId
      RequestedAt
      RequestedBy
      ListOfDetailsToBeChanged
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployeeDetailsUpdateTask = /* GraphQL */ `
  mutation DeleteEmployeeDetailsUpdateTask(
    $input: DeleteEmployeeDetailsUpdateTaskInput!
    $condition: ModelEmployeeDetailsUpdateTaskConditionInput
  ) {
    deleteEmployeeDetailsUpdateTask(input: $input, condition: $condition) {
      id
      GUID
      OrgId
      EmpId
      RequestedAt
      RequestedBy
      ListOfDetailsToBeChanged
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createUploadFileAudit = /* GraphQL */ `
  mutation CreateUploadFileAudit(
    $input: CreateUploadFileAuditInput!
    $condition: ModelUploadFileAuditConditionInput
  ) {
    createUploadFileAudit(input: $input, condition: $condition) {
      id
      OrgId
      StoredFileName
      StoredFileSuffix
      DisplayFileName
      UploadedAt
      UploadedBy
      UploadDescription
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateUploadFileAudit = /* GraphQL */ `
  mutation UpdateUploadFileAudit(
    $input: UpdateUploadFileAuditInput!
    $condition: ModelUploadFileAuditConditionInput
  ) {
    updateUploadFileAudit(input: $input, condition: $condition) {
      id
      OrgId
      StoredFileName
      StoredFileSuffix
      DisplayFileName
      UploadedAt
      UploadedBy
      UploadDescription
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteUploadFileAudit = /* GraphQL */ `
  mutation DeleteUploadFileAudit(
    $input: DeleteUploadFileAuditInput!
    $condition: ModelUploadFileAuditConditionInput
  ) {
    deleteUploadFileAudit(input: $input, condition: $condition) {
      id
      OrgId
      StoredFileName
      StoredFileSuffix
      DisplayFileName
      UploadedAt
      UploadedBy
      UploadDescription
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createOrgCycleDates = /* GraphQL */ `
  mutation CreateOrgCycleDates(
    $input: CreateOrgCycleDatesInput!
    $condition: ModelOrgCycleDatesConditionInput
  ) {
    createOrgCycleDates(input: $input, condition: $condition) {
      id
      OrgId
      MonthIndex
      CycleStartDate
      CycleEndDate
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateOrgCycleDates = /* GraphQL */ `
  mutation UpdateOrgCycleDates(
    $input: UpdateOrgCycleDatesInput!
    $condition: ModelOrgCycleDatesConditionInput
  ) {
    updateOrgCycleDates(input: $input, condition: $condition) {
      id
      OrgId
      MonthIndex
      CycleStartDate
      CycleEndDate
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrgCycleDates = /* GraphQL */ `
  mutation DeleteOrgCycleDates(
    $input: DeleteOrgCycleDatesInput!
    $condition: ModelOrgCycleDatesConditionInput
  ) {
    deleteOrgCycleDates(input: $input, condition: $condition) {
      id
      OrgId
      MonthIndex
      CycleStartDate
      CycleEndDate
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createGrowQuestionnaireResults = /* GraphQL */ `
  mutation CreateGrowQuestionnaireResults(
    $input: CreateGrowQuestionnaireResultsInput!
    $condition: ModelgrowQuestionnaireResultsConditionInput
  ) {
    createGrowQuestionnaireResults(input: $input, condition: $condition) {
      id
      OrgId
      createdAt
      EmpId
      monthlyFamilyIncome
      monthlyFamilyExpenses
      cashShortfallAmount
      emergencyFundValue
      monthlyFamilyEMI
      monthlyExpensesAsPercentageOfIncome
      cashOutflowRatio
      monthlyCashShortfall
      emergencyFundNumberMonths
      monthlySavingsAsPercentageOfIncome
      debtToIncomeRatio
      cibilScore
      totalHealthInsurance
      totalLifeInsurance
      healthInsuranceScore
      lifeInsuranceScore
      spendScore
      saveScore
      borrowScore
      protectScore
      financialFitnessScore
      financialStressLevel
      tenant
      updatedAt
    }
  }
`;
export const updateGrowQuestionnaireResults = /* GraphQL */ `
  mutation UpdateGrowQuestionnaireResults(
    $input: UpdateGrowQuestionnaireResultsInput!
    $condition: ModelgrowQuestionnaireResultsConditionInput
  ) {
    updateGrowQuestionnaireResults(input: $input, condition: $condition) {
      id
      OrgId
      createdAt
      EmpId
      monthlyFamilyIncome
      monthlyFamilyExpenses
      cashShortfallAmount
      emergencyFundValue
      monthlyFamilyEMI
      monthlyExpensesAsPercentageOfIncome
      cashOutflowRatio
      monthlyCashShortfall
      emergencyFundNumberMonths
      monthlySavingsAsPercentageOfIncome
      debtToIncomeRatio
      cibilScore
      totalHealthInsurance
      totalLifeInsurance
      healthInsuranceScore
      lifeInsuranceScore
      spendScore
      saveScore
      borrowScore
      protectScore
      financialFitnessScore
      financialStressLevel
      tenant
      updatedAt
    }
  }
`;
export const deleteGrowQuestionnaireResults = /* GraphQL */ `
  mutation DeleteGrowQuestionnaireResults(
    $input: DeleteGrowQuestionnaireResultsInput!
    $condition: ModelgrowQuestionnaireResultsConditionInput
  ) {
    deleteGrowQuestionnaireResults(input: $input, condition: $condition) {
      id
      OrgId
      createdAt
      EmpId
      monthlyFamilyIncome
      monthlyFamilyExpenses
      cashShortfallAmount
      emergencyFundValue
      monthlyFamilyEMI
      monthlyExpensesAsPercentageOfIncome
      cashOutflowRatio
      monthlyCashShortfall
      emergencyFundNumberMonths
      monthlySavingsAsPercentageOfIncome
      debtToIncomeRatio
      cibilScore
      totalHealthInsurance
      totalLifeInsurance
      healthInsuranceScore
      lifeInsuranceScore
      spendScore
      saveScore
      borrowScore
      protectScore
      financialFitnessScore
      financialStressLevel
      tenant
      updatedAt
    }
  }
`;
export const createCommunicationPreferences = /* GraphQL */ `
  mutation CreateCommunicationPreferences(
    $input: CreateCommunicationPreferencesInput!
    $condition: ModelCommunicationPreferencesConditionInput
  ) {
    createCommunicationPreferences(input: $input, condition: $condition) {
      id
      OrgId
      allowSaathiMarketing
      allowPartnerMarketing
      allowPushNotifications
      allowSMSNotification
      allowEmailNotifications
      allowVerificationBySMS
      allowVerificationByEmail
      createdAt
      updatedAt
      tenant
      owner
    }
  }
`;
export const updateCommunicationPreferences = /* GraphQL */ `
  mutation UpdateCommunicationPreferences(
    $input: UpdateCommunicationPreferencesInput!
    $condition: ModelCommunicationPreferencesConditionInput
  ) {
    updateCommunicationPreferences(input: $input, condition: $condition) {
      id
      OrgId
      allowSaathiMarketing
      allowPartnerMarketing
      allowPushNotifications
      allowSMSNotification
      allowEmailNotifications
      allowVerificationBySMS
      allowVerificationByEmail
      createdAt
      updatedAt
      tenant
      owner
    }
  }
`;
export const deleteCommunicationPreferences = /* GraphQL */ `
  mutation DeleteCommunicationPreferences(
    $input: DeleteCommunicationPreferencesInput!
    $condition: ModelCommunicationPreferencesConditionInput
  ) {
    deleteCommunicationPreferences(input: $input, condition: $condition) {
      id
      OrgId
      allowSaathiMarketing
      allowPartnerMarketing
      allowPushNotifications
      allowSMSNotification
      allowEmailNotifications
      allowVerificationBySMS
      allowVerificationByEmail
      createdAt
      updatedAt
      tenant
      owner
    }
  }
`;
export const createMonthlyReportMetrics = /* GraphQL */ `
  mutation CreateMonthlyReportMetrics(
    $input: CreateMonthlyReportMetricsInput!
    $condition: ModelmonthlyReportMetricsConditionInput
  ) {
    createMonthlyReportMetrics(input: $input, condition: $condition) {
      id
      OrgId
      MonthStart
      MonthEnd
      employeesQuantity
      earnActiveUsersQuantity
      earnTotalAdvancesTaken
      earnAverageAdvanceSize
      earnTypicalUseCasesQuantities
      benefitActiveUsersQuantity
      usersTakingGrowQuestionnaireQuantity
      growAverageScores
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateMonthlyReportMetrics = /* GraphQL */ `
  mutation UpdateMonthlyReportMetrics(
    $input: UpdateMonthlyReportMetricsInput!
    $condition: ModelmonthlyReportMetricsConditionInput
  ) {
    updateMonthlyReportMetrics(input: $input, condition: $condition) {
      id
      OrgId
      MonthStart
      MonthEnd
      employeesQuantity
      earnActiveUsersQuantity
      earnTotalAdvancesTaken
      earnAverageAdvanceSize
      earnTypicalUseCasesQuantities
      benefitActiveUsersQuantity
      usersTakingGrowQuestionnaireQuantity
      growAverageScores
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteMonthlyReportMetrics = /* GraphQL */ `
  mutation DeleteMonthlyReportMetrics(
    $input: DeleteMonthlyReportMetricsInput!
    $condition: ModelmonthlyReportMetricsConditionInput
  ) {
    deleteMonthlyReportMetrics(input: $input, condition: $condition) {
      id
      OrgId
      MonthStart
      MonthEnd
      employeesQuantity
      earnActiveUsersQuantity
      earnTotalAdvancesTaken
      earnAverageAdvanceSize
      earnTypicalUseCasesQuantities
      benefitActiveUsersQuantity
      usersTakingGrowQuestionnaireQuantity
      growAverageScores
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createEmpCurrentCycleCalcs = /* GraphQL */ `
  mutation CreateEmpCurrentCycleCalcs(
    $input: CreateEmpCurrentCycleCalcsInput!
    $condition: ModelempCurrentCycleCalcsConditionInput
  ) {
    createEmpCurrentCycleCalcs(input: $input, condition: $condition) {
      empId
      owner
      totalEarnedSalary
      spentEarnedSalary
      quantityOfWithdrawalsTaken
      totalValueOfAutoApprovals
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateEmpCurrentCycleCalcs = /* GraphQL */ `
  mutation UpdateEmpCurrentCycleCalcs(
    $input: UpdateEmpCurrentCycleCalcsInput!
    $condition: ModelempCurrentCycleCalcsConditionInput
  ) {
    updateEmpCurrentCycleCalcs(input: $input, condition: $condition) {
      empId
      owner
      totalEarnedSalary
      spentEarnedSalary
      quantityOfWithdrawalsTaken
      totalValueOfAutoApprovals
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmpCurrentCycleCalcs = /* GraphQL */ `
  mutation DeleteEmpCurrentCycleCalcs(
    $input: DeleteEmpCurrentCycleCalcsInput!
    $condition: ModelempCurrentCycleCalcsConditionInput
  ) {
    deleteEmpCurrentCycleCalcs(input: $input, condition: $condition) {
      empId
      owner
      totalEarnedSalary
      spentEarnedSalary
      quantityOfWithdrawalsTaken
      totalValueOfAutoApprovals
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createOrgCurrentCycleCalcs = /* GraphQL */ `
  mutation CreateOrgCurrentCycleCalcs(
    $input: CreateOrgCurrentCycleCalcsInput!
    $condition: ModelorgCurrentCycleCalcsConditionInput
  ) {
    createOrgCurrentCycleCalcs(input: $input, condition: $condition) {
      orgId
      discount
      withdrawalPeriodStart
      withdrawalPeriodEnd
      payDate
      potentialBlackoutPercentage
      isWithdrawalPeriodOpen
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateOrgCurrentCycleCalcs = /* GraphQL */ `
  mutation UpdateOrgCurrentCycleCalcs(
    $input: UpdateOrgCurrentCycleCalcsInput!
    $condition: ModelorgCurrentCycleCalcsConditionInput
  ) {
    updateOrgCurrentCycleCalcs(input: $input, condition: $condition) {
      orgId
      discount
      withdrawalPeriodStart
      withdrawalPeriodEnd
      payDate
      potentialBlackoutPercentage
      isWithdrawalPeriodOpen
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrgCurrentCycleCalcs = /* GraphQL */ `
  mutation DeleteOrgCurrentCycleCalcs(
    $input: DeleteOrgCurrentCycleCalcsInput!
    $condition: ModelorgCurrentCycleCalcsConditionInput
  ) {
    deleteOrgCurrentCycleCalcs(input: $input, condition: $condition) {
      orgId
      discount
      withdrawalPeriodStart
      withdrawalPeriodEnd
      payDate
      potentialBlackoutPercentage
      isWithdrawalPeriodOpen
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createOTP = /* GraphQL */ `
  mutation CreateOTP(
    $input: CreateOTPInput!
    $condition: ModelOTPConditionInput
  ) {
    createOTP(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const updateOTP = /* GraphQL */ `
  mutation UpdateOTP(
    $input: UpdateOTPInput!
    $condition: ModelOTPConditionInput
  ) {
    updateOTP(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const deleteOTP = /* GraphQL */ `
  mutation DeleteOTP(
    $input: DeleteOTPInput!
    $condition: ModelOTPConditionInput
  ) {
    deleteOTP(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const createStagingTransactions = /* GraphQL */ `
  mutation CreateStagingTransactions(
    $input: CreateStagingTransactionsInput!
    $condition: ModelstagingTransactionsConditionInput
  ) {
    createStagingTransactions(input: $input, condition: $condition) {
      id
      token
      managerId
      orgId
      createdAt
      updatedAt
    }
  }
`;
export const updateStagingTransactions = /* GraphQL */ `
  mutation UpdateStagingTransactions(
    $input: UpdateStagingTransactionsInput!
    $condition: ModelstagingTransactionsConditionInput
  ) {
    updateStagingTransactions(input: $input, condition: $condition) {
      id
      token
      managerId
      orgId
      createdAt
      updatedAt
    }
  }
`;
export const deleteStagingTransactions = /* GraphQL */ `
  mutation DeleteStagingTransactions(
    $input: DeleteStagingTransactionsInput!
    $condition: ModelstagingTransactionsConditionInput
  ) {
    deleteStagingTransactions(input: $input, condition: $condition) {
      id
      token
      managerId
      orgId
      createdAt
      updatedAt
    }
  }
`;
export const createArticles = /* GraphQL */ `
  mutation CreateArticles(
    $input: CreateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    createArticles(input: $input, condition: $condition) {
      id
      articleUrl
      apiUrl
      articleTitle
      shortTitle
      imageUrl
      first80Characters
      articleContent
      articleType
      createdAt
      updatedAt
    }
  }
`;
export const updateArticles = /* GraphQL */ `
  mutation UpdateArticles(
    $input: UpdateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    updateArticles(input: $input, condition: $condition) {
      id
      articleUrl
      apiUrl
      articleTitle
      shortTitle
      imageUrl
      first80Characters
      articleContent
      articleType
      createdAt
      updatedAt
    }
  }
`;
export const deleteArticles = /* GraphQL */ `
  mutation DeleteArticles(
    $input: DeleteArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    deleteArticles(input: $input, condition: $condition) {
      id
      articleUrl
      apiUrl
      articleTitle
      shortTitle
      imageUrl
      first80Characters
      articleContent
      articleType
      createdAt
      updatedAt
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      userId
      voucherId
      name
      image
      totalGoalAmount
      netGoalAmount
      goalInterestAmount
      goalRewardType
      goalRewardAmount
      instalmentAmount
      currency
      savedAmount
      length
      lengthDurationType
      maxInstalmentAmount
      paymentDate
      bankInterestRatePercent
      bankInterestRateAmount
      savingsProductType
      isShared
      archivedSharedUsersId
      sharedUsersId
      sharedGoalDetails
      tenant
      snplBankAccountNumber
      snplBankAccountType
      cashfreeSubReferenceId
      cashfreeSubscriptionStatus
      goalStatus
      firstChargeDate
      goalEndDate
      maxGoalEndDate
      DailyAccruedBalance
      AvailableDisplayBalance
      TotalInterestEarnedOnDailyAccruedBalance
      TotalInterestEarnedOnAvailableToSpendBalance
      goalChangeAuditArray
      linkedProductType
      updatedAt
      isArchived
      products {
        nextToken
      }
      subscriptions {
        nextToken
      }
      Voucher {
        id
        merchantID
        affiliateID
        userID
        productCategory
        productID
        voucherCode
        voucherType
        voucherDescription
        voucherImageURL
        voucherOfferAmount
        voucherOfferType
        emailRequiredBool
        mobileRequiredBool
        voucherUsedBool
        voucherUsageCount
        tenant
        createdAt
        updatedAt
      }
      createdAt
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      userId
      voucherId
      name
      image
      totalGoalAmount
      netGoalAmount
      goalInterestAmount
      goalRewardType
      goalRewardAmount
      instalmentAmount
      currency
      savedAmount
      length
      lengthDurationType
      maxInstalmentAmount
      paymentDate
      bankInterestRatePercent
      bankInterestRateAmount
      savingsProductType
      isShared
      archivedSharedUsersId
      sharedUsersId
      sharedGoalDetails
      tenant
      snplBankAccountNumber
      snplBankAccountType
      cashfreeSubReferenceId
      cashfreeSubscriptionStatus
      goalStatus
      firstChargeDate
      goalEndDate
      maxGoalEndDate
      DailyAccruedBalance
      AvailableDisplayBalance
      TotalInterestEarnedOnDailyAccruedBalance
      TotalInterestEarnedOnAvailableToSpendBalance
      goalChangeAuditArray
      linkedProductType
      updatedAt
      isArchived
      products {
        nextToken
      }
      subscriptions {
        nextToken
      }
      Voucher {
        id
        merchantID
        affiliateID
        userID
        productCategory
        productID
        voucherCode
        voucherType
        voucherDescription
        voucherImageURL
        voucherOfferAmount
        voucherOfferType
        emailRequiredBool
        mobileRequiredBool
        voucherUsedBool
        voucherUsageCount
        tenant
        createdAt
        updatedAt
      }
      createdAt
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      userId
      voucherId
      name
      image
      totalGoalAmount
      netGoalAmount
      goalInterestAmount
      goalRewardType
      goalRewardAmount
      instalmentAmount
      currency
      savedAmount
      length
      lengthDurationType
      maxInstalmentAmount
      paymentDate
      bankInterestRatePercent
      bankInterestRateAmount
      savingsProductType
      isShared
      archivedSharedUsersId
      sharedUsersId
      sharedGoalDetails
      tenant
      snplBankAccountNumber
      snplBankAccountType
      cashfreeSubReferenceId
      cashfreeSubscriptionStatus
      goalStatus
      firstChargeDate
      goalEndDate
      maxGoalEndDate
      DailyAccruedBalance
      AvailableDisplayBalance
      TotalInterestEarnedOnDailyAccruedBalance
      TotalInterestEarnedOnAvailableToSpendBalance
      goalChangeAuditArray
      linkedProductType
      updatedAt
      isArchived
      products {
        nextToken
      }
      subscriptions {
        nextToken
      }
      Voucher {
        id
        merchantID
        affiliateID
        userID
        productCategory
        productID
        voucherCode
        voucherType
        voucherDescription
        voucherImageURL
        voucherOfferAmount
        voucherOfferType
        emailRequiredBool
        mobileRequiredBool
        voucherUsedBool
        voucherUsageCount
        tenant
        createdAt
        updatedAt
      }
      createdAt
    }
  }
`;
export const createSavingProduct = /* GraphQL */ `
  mutation CreateSavingProduct(
    $input: CreateSavingProductInput!
    $condition: ModelSavingProductConditionInput
  ) {
    createSavingProduct(input: $input, condition: $condition) {
      id
      goalId
      merchantId
      merchantProductId
      rewardType
      offer
      offerType
      logoUrl
      productUrl
      productImageURL
      productName
      productDescription
      productPrice
      deliveryFee
      packingFee
      totalPrice
      itemId
      tenant
      trackingLink
      Merchant {
        id
        platformMerchantId
        platform
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSavingProduct = /* GraphQL */ `
  mutation UpdateSavingProduct(
    $input: UpdateSavingProductInput!
    $condition: ModelSavingProductConditionInput
  ) {
    updateSavingProduct(input: $input, condition: $condition) {
      id
      goalId
      merchantId
      merchantProductId
      rewardType
      offer
      offerType
      logoUrl
      productUrl
      productImageURL
      productName
      productDescription
      productPrice
      deliveryFee
      packingFee
      totalPrice
      itemId
      tenant
      trackingLink
      Merchant {
        id
        platformMerchantId
        platform
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSavingProduct = /* GraphQL */ `
  mutation DeleteSavingProduct(
    $input: DeleteSavingProductInput!
    $condition: ModelSavingProductConditionInput
  ) {
    deleteSavingProduct(input: $input, condition: $condition) {
      id
      goalId
      merchantId
      merchantProductId
      rewardType
      offer
      offerType
      logoUrl
      productUrl
      productImageURL
      productName
      productDescription
      productPrice
      deliveryFee
      packingFee
      totalPrice
      itemId
      tenant
      trackingLink
      Merchant {
        id
        platformMerchantId
        platform
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMerchantProduct = /* GraphQL */ `
  mutation CreateMerchantProduct(
    $input: CreateMerchantProductInput!
    $condition: ModelMerchantProductConditionInput
  ) {
    createMerchantProduct(input: $input, condition: $condition) {
      merchantID
      productID
      productName
      merchantName
      rewardType
      productDescription
      productParameters
      price
      deliveryPackingChange
      deliveryFee
      packingFee
      productOffer
      productOfferType
      cashkaroOfferList
      productTags
      productLinkURL
      productImageURL
      relatedArticles
      featured
      offerDescription
      tenant
      platform
      isExpired
      status
      trackingLink
      merchantProductType
      merchantProductBackgroundURL
      hasVoucher
      Merchant {
        id
        platformMerchantId
        platform
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMerchantProduct = /* GraphQL */ `
  mutation UpdateMerchantProduct(
    $input: UpdateMerchantProductInput!
    $condition: ModelMerchantProductConditionInput
  ) {
    updateMerchantProduct(input: $input, condition: $condition) {
      merchantID
      productID
      productName
      merchantName
      rewardType
      productDescription
      productParameters
      price
      deliveryPackingChange
      deliveryFee
      packingFee
      productOffer
      productOfferType
      cashkaroOfferList
      productTags
      productLinkURL
      productImageURL
      relatedArticles
      featured
      offerDescription
      tenant
      platform
      isExpired
      status
      trackingLink
      merchantProductType
      merchantProductBackgroundURL
      hasVoucher
      Merchant {
        id
        platformMerchantId
        platform
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMerchantProduct = /* GraphQL */ `
  mutation DeleteMerchantProduct(
    $input: DeleteMerchantProductInput!
    $condition: ModelMerchantProductConditionInput
  ) {
    deleteMerchantProduct(input: $input, condition: $condition) {
      merchantID
      productID
      productName
      merchantName
      rewardType
      productDescription
      productParameters
      price
      deliveryPackingChange
      deliveryFee
      packingFee
      productOffer
      productOfferType
      cashkaroOfferList
      productTags
      productLinkURL
      productImageURL
      relatedArticles
      featured
      offerDescription
      tenant
      platform
      isExpired
      status
      trackingLink
      merchantProductType
      merchantProductBackgroundURL
      hasVoucher
      Merchant {
        id
        platformMerchantId
        platform
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMerchantProductCategory = /* GraphQL */ `
  mutation CreateMerchantProductCategory(
    $input: CreateMerchantProductCategoryInput!
    $condition: ModelMerchantProductCategoryConditionInput
  ) {
    createMerchantProductCategory(input: $input, condition: $condition) {
      id
      cashkaroCategoryId
      categoryName
      categoryImage
      merchantProductCount
      backgroundColourHex
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateMerchantProductCategory = /* GraphQL */ `
  mutation UpdateMerchantProductCategory(
    $input: UpdateMerchantProductCategoryInput!
    $condition: ModelMerchantProductCategoryConditionInput
  ) {
    updateMerchantProductCategory(input: $input, condition: $condition) {
      id
      cashkaroCategoryId
      categoryName
      categoryImage
      merchantProductCount
      backgroundColourHex
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteMerchantProductCategory = /* GraphQL */ `
  mutation DeleteMerchantProductCategory(
    $input: DeleteMerchantProductCategoryInput!
    $condition: ModelMerchantProductCategoryConditionInput
  ) {
    deleteMerchantProductCategory(input: $input, condition: $condition) {
      id
      cashkaroCategoryId
      categoryName
      categoryImage
      merchantProductCount
      backgroundColourHex
      status
      createdAt
      updatedAt
    }
  }
`;
export const createVoucher = /* GraphQL */ `
  mutation CreateVoucher(
    $input: CreateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    createVoucher(input: $input, condition: $condition) {
      id
      merchantID
      affiliateID
      userID
      productCategory
      productID
      voucherCode
      voucherType
      voucherDescription
      voucherImageURL
      voucherOfferAmount
      voucherOfferType
      emailRequiredBool
      mobileRequiredBool
      voucherUsedBool
      voucherUsageCount
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const updateVoucher = /* GraphQL */ `
  mutation UpdateVoucher(
    $input: UpdateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    updateVoucher(input: $input, condition: $condition) {
      id
      merchantID
      affiliateID
      userID
      productCategory
      productID
      voucherCode
      voucherType
      voucherDescription
      voucherImageURL
      voucherOfferAmount
      voucherOfferType
      emailRequiredBool
      mobileRequiredBool
      voucherUsedBool
      voucherUsageCount
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoucher = /* GraphQL */ `
  mutation DeleteVoucher(
    $input: DeleteVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    deleteVoucher(input: $input, condition: $condition) {
      id
      merchantID
      affiliateID
      userID
      productCategory
      productID
      voucherCode
      voucherType
      voucherDescription
      voucherImageURL
      voucherOfferAmount
      voucherOfferType
      emailRequiredBool
      mobileRequiredBool
      voucherUsedBool
      voucherUsageCount
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentSubscription = /* GraphQL */ `
  mutation CreatePaymentSubscription(
    $input: CreatePaymentSubscriptionInput!
    $condition: ModelPaymentSubscriptionConditionInput
  ) {
    createPaymentSubscription(input: $input, condition: $condition) {
      id
      goalId
      userId
      type
      cfsubRefId
      planInfo
      authLink
      authStatus
      status
      firstChargeDate
      expiresOn
      tenant
      amount
      payments
      lastOrderId
      needsPayout
      accountHolder
      accountNumber
      bankId
      authMode
      accountType
      ifsc
      nextChargeRaisedDate
      nextChargeScheduledDate
      createdAt
      vendorId
      updatedAt
    }
  }
`;
export const updatePaymentSubscription = /* GraphQL */ `
  mutation UpdatePaymentSubscription(
    $input: UpdatePaymentSubscriptionInput!
    $condition: ModelPaymentSubscriptionConditionInput
  ) {
    updatePaymentSubscription(input: $input, condition: $condition) {
      id
      goalId
      userId
      type
      cfsubRefId
      planInfo
      authLink
      authStatus
      status
      firstChargeDate
      expiresOn
      tenant
      amount
      payments
      lastOrderId
      needsPayout
      accountHolder
      accountNumber
      bankId
      authMode
      accountType
      ifsc
      nextChargeRaisedDate
      nextChargeScheduledDate
      createdAt
      vendorId
      updatedAt
    }
  }
`;
export const deletePaymentSubscription = /* GraphQL */ `
  mutation DeletePaymentSubscription(
    $input: DeletePaymentSubscriptionInput!
    $condition: ModelPaymentSubscriptionConditionInput
  ) {
    deletePaymentSubscription(input: $input, condition: $condition) {
      id
      goalId
      userId
      type
      cfsubRefId
      planInfo
      authLink
      authStatus
      status
      firstChargeDate
      expiresOn
      tenant
      amount
      payments
      lastOrderId
      needsPayout
      accountHolder
      accountNumber
      bankId
      authMode
      accountType
      ifsc
      nextChargeRaisedDate
      nextChargeScheduledDate
      createdAt
      vendorId
      updatedAt
    }
  }
`;
export const createCashfreeSubscriptions = /* GraphQL */ `
  mutation CreateCashfreeSubscriptions(
    $input: CreateCashfreeSubscriptionsInput!
    $condition: ModelCashfreeSubscriptionsConditionInput
  ) {
    createCashfreeSubscriptions(input: $input, condition: $condition) {
      id
      goalId
      userId
      subReferenceId
      planId
      authLink
      authStatus
      status
      firstChargeDate
      expiresOn
      tenant
      payments
      lastOrderId
      needsPayout
      accountHolder
      accountNumber
      bankId
      authMode
      accountType
      ifsc
      createdAt
      transfers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateCashfreeSubscriptions = /* GraphQL */ `
  mutation UpdateCashfreeSubscriptions(
    $input: UpdateCashfreeSubscriptionsInput!
    $condition: ModelCashfreeSubscriptionsConditionInput
  ) {
    updateCashfreeSubscriptions(input: $input, condition: $condition) {
      id
      goalId
      userId
      subReferenceId
      planId
      authLink
      authStatus
      status
      firstChargeDate
      expiresOn
      tenant
      payments
      lastOrderId
      needsPayout
      accountHolder
      accountNumber
      bankId
      authMode
      accountType
      ifsc
      createdAt
      transfers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteCashfreeSubscriptions = /* GraphQL */ `
  mutation DeleteCashfreeSubscriptions(
    $input: DeleteCashfreeSubscriptionsInput!
    $condition: ModelCashfreeSubscriptionsConditionInput
  ) {
    deleteCashfreeSubscriptions(input: $input, condition: $condition) {
      id
      goalId
      userId
      subReferenceId
      planId
      authLink
      authStatus
      status
      firstChargeDate
      expiresOn
      tenant
      payments
      lastOrderId
      needsPayout
      accountHolder
      accountNumber
      bankId
      authMode
      accountType
      ifsc
      createdAt
      transfers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const createCashfreeOrder = /* GraphQL */ `
  mutation CreateCashfreeOrder(
    $input: CreateCashfreeOrderInput!
    $condition: ModelCashfreeOrderConditionInput
  ) {
    createCashfreeOrder(input: $input, condition: $condition) {
      id
      userId
      goalId
      cfOrderId
      amount
      status
      token
      note
      expireTime
      paymentLink
      payment
      tenant
      needsPayout
      vendorId
      createdAt
      transfers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateCashfreeOrder = /* GraphQL */ `
  mutation UpdateCashfreeOrder(
    $input: UpdateCashfreeOrderInput!
    $condition: ModelCashfreeOrderConditionInput
  ) {
    updateCashfreeOrder(input: $input, condition: $condition) {
      id
      userId
      goalId
      cfOrderId
      amount
      status
      token
      note
      expireTime
      paymentLink
      payment
      tenant
      needsPayout
      vendorId
      createdAt
      transfers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteCashfreeOrder = /* GraphQL */ `
  mutation DeleteCashfreeOrder(
    $input: DeleteCashfreeOrderInput!
    $condition: ModelCashfreeOrderConditionInput
  ) {
    deleteCashfreeOrder(input: $input, condition: $condition) {
      id
      userId
      goalId
      cfOrderId
      amount
      status
      token
      note
      expireTime
      paymentLink
      payment
      tenant
      needsPayout
      vendorId
      createdAt
      transfers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const createCashfreeTransfer = /* GraphQL */ `
  mutation CreateCashfreeTransfer(
    $input: CreateCashfreeTransferInput!
    $condition: ModelCashfreeTransferConditionInput
  ) {
    createCashfreeTransfer(input: $input, condition: $condition) {
      id
      goalId
      userId
      transferId
      beneId
      subReferenceId
      orderId
      referenceId
      utr
      status
      amount
      tenant
      needsRetry
      retryAttempts
      nextScheduledOn
      transferType
      failedTransfers
      createdAt
      updatedAt
    }
  }
`;
export const updateCashfreeTransfer = /* GraphQL */ `
  mutation UpdateCashfreeTransfer(
    $input: UpdateCashfreeTransferInput!
    $condition: ModelCashfreeTransferConditionInput
  ) {
    updateCashfreeTransfer(input: $input, condition: $condition) {
      id
      goalId
      userId
      transferId
      beneId
      subReferenceId
      orderId
      referenceId
      utr
      status
      amount
      tenant
      needsRetry
      retryAttempts
      nextScheduledOn
      transferType
      failedTransfers
      createdAt
      updatedAt
    }
  }
`;
export const deleteCashfreeTransfer = /* GraphQL */ `
  mutation DeleteCashfreeTransfer(
    $input: DeleteCashfreeTransferInput!
    $condition: ModelCashfreeTransferConditionInput
  ) {
    deleteCashfreeTransfer(input: $input, condition: $condition) {
      id
      goalId
      userId
      transferId
      beneId
      subReferenceId
      orderId
      referenceId
      utr
      status
      amount
      tenant
      needsRetry
      retryAttempts
      nextScheduledOn
      transferType
      failedTransfers
      createdAt
      updatedAt
    }
  }
`;
export const createCashfreeSplitOrder = /* GraphQL */ `
  mutation CreateCashfreeSplitOrder(
    $input: CreateCashfreeSplitOrderInput!
    $condition: ModelCashfreeSplitOrderConditionInput
  ) {
    createCashfreeSplitOrder(input: $input, condition: $condition) {
      id
      userId
      goalId
      subReferenceId
      vendorId
      vendorSettlementId
      status
      amount
      utr
      createdAt
      updatedAt
    }
  }
`;
export const updateCashfreeSplitOrder = /* GraphQL */ `
  mutation UpdateCashfreeSplitOrder(
    $input: UpdateCashfreeSplitOrderInput!
    $condition: ModelCashfreeSplitOrderConditionInput
  ) {
    updateCashfreeSplitOrder(input: $input, condition: $condition) {
      id
      userId
      goalId
      subReferenceId
      vendorId
      vendorSettlementId
      status
      amount
      utr
      createdAt
      updatedAt
    }
  }
`;
export const deleteCashfreeSplitOrder = /* GraphQL */ `
  mutation DeleteCashfreeSplitOrder(
    $input: DeleteCashfreeSplitOrderInput!
    $condition: ModelCashfreeSplitOrderConditionInput
  ) {
    deleteCashfreeSplitOrder(input: $input, condition: $condition) {
      id
      userId
      goalId
      subReferenceId
      vendorId
      vendorSettlementId
      status
      amount
      utr
      createdAt
      updatedAt
    }
  }
`;
export const createCashfreeSettlement = /* GraphQL */ `
  mutation CreateCashfreeSettlement(
    $input: CreateCashfreeSettlementInput!
    $condition: ModelCashfreeSettlementConditionInput
  ) {
    createCashfreeSettlement(input: $input, condition: $condition) {
      id
      type
      processOn
      createdAt
      updatedAt
    }
  }
`;
export const updateCashfreeSettlement = /* GraphQL */ `
  mutation UpdateCashfreeSettlement(
    $input: UpdateCashfreeSettlementInput!
    $condition: ModelCashfreeSettlementConditionInput
  ) {
    updateCashfreeSettlement(input: $input, condition: $condition) {
      id
      type
      processOn
      createdAt
      updatedAt
    }
  }
`;
export const deleteCashfreeSettlement = /* GraphQL */ `
  mutation DeleteCashfreeSettlement(
    $input: DeleteCashfreeSettlementInput!
    $condition: ModelCashfreeSettlementConditionInput
  ) {
    deleteCashfreeSettlement(input: $input, condition: $condition) {
      id
      type
      processOn
      createdAt
      updatedAt
    }
  }
`;
export const createBankTransaction = /* GraphQL */ `
  mutation CreateBankTransaction(
    $input: CreateBankTransactionInput!
    $condition: ModelBankTransactionConditionInput
  ) {
    createBankTransaction(input: $input, condition: $condition) {
      id
      goalId
      userId
      utr
      transactionDate
      snplBankAccountNumber
      snplBankAccountType
      amount
      currency
      snplDebitCredit
      snplStatementReference
      snplStatementDescription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBankTransaction = /* GraphQL */ `
  mutation UpdateBankTransaction(
    $input: UpdateBankTransactionInput!
    $condition: ModelBankTransactionConditionInput
  ) {
    updateBankTransaction(input: $input, condition: $condition) {
      id
      goalId
      userId
      utr
      transactionDate
      snplBankAccountNumber
      snplBankAccountType
      amount
      currency
      snplDebitCredit
      snplStatementReference
      snplStatementDescription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBankTransaction = /* GraphQL */ `
  mutation DeleteBankTransaction(
    $input: DeleteBankTransactionInput!
    $condition: ModelBankTransactionConditionInput
  ) {
    deleteBankTransaction(input: $input, condition: $condition) {
      id
      goalId
      userId
      utr
      transactionDate
      snplBankAccountNumber
      snplBankAccountType
      amount
      currency
      snplDebitCredit
      snplStatementReference
      snplStatementDescription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMerchant = /* GraphQL */ `
  mutation CreateMerchant(
    $input: CreateMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    createMerchant(input: $input, condition: $condition) {
      id
      platformMerchantId
      platform
      merchant_name
      merchant_logo_url
      merchant_logo_Base64
      merchant_checkout_url
      merchant_add_id_to_checkout_url
      merchantDescription
      merchantOfferTitle
      merchantOfferType
      merchantOfferAmount
      afiliateUrl
      merchantCategory
      merchantWebsiteURL
      tenant
      shopifyStoreID
      shopifyStoreSaathiSNPLTCsAgreed
      SNPLAllowedBySaathi
      merchantType
      backgroundCircleHexColour
      merchantBackgroundImageURL
      isReviewed
      isMaxOffer
      createdAt
      updatedAt
    }
  }
`;
export const updateMerchant = /* GraphQL */ `
  mutation UpdateMerchant(
    $input: UpdateMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    updateMerchant(input: $input, condition: $condition) {
      id
      platformMerchantId
      platform
      merchant_name
      merchant_logo_url
      merchant_logo_Base64
      merchant_checkout_url
      merchant_add_id_to_checkout_url
      merchantDescription
      merchantOfferTitle
      merchantOfferType
      merchantOfferAmount
      afiliateUrl
      merchantCategory
      merchantWebsiteURL
      tenant
      shopifyStoreID
      shopifyStoreSaathiSNPLTCsAgreed
      SNPLAllowedBySaathi
      merchantType
      backgroundCircleHexColour
      merchantBackgroundImageURL
      isReviewed
      isMaxOffer
      createdAt
      updatedAt
    }
  }
`;
export const deleteMerchant = /* GraphQL */ `
  mutation DeleteMerchant(
    $input: DeleteMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    deleteMerchant(input: $input, condition: $condition) {
      id
      platformMerchantId
      platform
      merchant_name
      merchant_logo_url
      merchant_logo_Base64
      merchant_checkout_url
      merchant_add_id_to_checkout_url
      merchantDescription
      merchantOfferTitle
      merchantOfferType
      merchantOfferAmount
      afiliateUrl
      merchantCategory
      merchantWebsiteURL
      tenant
      shopifyStoreID
      shopifyStoreSaathiSNPLTCsAgreed
      SNPLAllowedBySaathi
      merchantType
      backgroundCircleHexColour
      merchantBackgroundImageURL
      isReviewed
      isMaxOffer
      createdAt
      updatedAt
    }
  }
`;
export const createSuryoday = /* GraphQL */ `
  mutation CreateSuryoday(
    $input: CreateSuryodayInput!
    $condition: ModelSuryodayConditionInput
  ) {
    createSuryoday(input: $input, condition: $condition) {
      id
      currentStan
      minStan
      maxStan
      auaLicenseKey
      kuaLicenseKey
      savingsAccountProductCode
      savingsAccountProductName
      escrowAccountNumber
      escrowAccountIFSCCode
      escrowAccountBranchCode
      escrowAccountName
      escrowAccountMobileNo
      escrowAccountEmail
      escrowAccountAddress
      cashfreeBeneficiaryId
      aadhaarPanConstant
      aadhaarProcCodeOtp
      aadhaarProcCodeAuth
      aadhaarMcc
      aadhaarPosEnteryMode
      aadhaarPosCode
      aadhaarAcqId
      aadhaarCaTidOtp
      aadhaarCaTidAuth
      aadhaarCaId
      aadhaarCaTa
      aadhaarAc
      aadhaarSa
      aadhaarAPIVerion
      aadhaarEncryptionKey
      currentAccountCustomerId
      currentAccountNumber
      currentAccountIFSCCode
      currentAccountBranchCode
      currentAccountName
      currentAccountBalance
      createdAt
      updatedAt
    }
  }
`;
export const updateSuryoday = /* GraphQL */ `
  mutation UpdateSuryoday(
    $input: UpdateSuryodayInput!
    $condition: ModelSuryodayConditionInput
  ) {
    updateSuryoday(input: $input, condition: $condition) {
      id
      currentStan
      minStan
      maxStan
      auaLicenseKey
      kuaLicenseKey
      savingsAccountProductCode
      savingsAccountProductName
      escrowAccountNumber
      escrowAccountIFSCCode
      escrowAccountBranchCode
      escrowAccountName
      escrowAccountMobileNo
      escrowAccountEmail
      escrowAccountAddress
      cashfreeBeneficiaryId
      aadhaarPanConstant
      aadhaarProcCodeOtp
      aadhaarProcCodeAuth
      aadhaarMcc
      aadhaarPosEnteryMode
      aadhaarPosCode
      aadhaarAcqId
      aadhaarCaTidOtp
      aadhaarCaTidAuth
      aadhaarCaId
      aadhaarCaTa
      aadhaarAc
      aadhaarSa
      aadhaarAPIVerion
      aadhaarEncryptionKey
      currentAccountCustomerId
      currentAccountNumber
      currentAccountIFSCCode
      currentAccountBranchCode
      currentAccountName
      currentAccountBalance
      createdAt
      updatedAt
    }
  }
`;
export const deleteSuryoday = /* GraphQL */ `
  mutation DeleteSuryoday(
    $input: DeleteSuryodayInput!
    $condition: ModelSuryodayConditionInput
  ) {
    deleteSuryoday(input: $input, condition: $condition) {
      id
      currentStan
      minStan
      maxStan
      auaLicenseKey
      kuaLicenseKey
      savingsAccountProductCode
      savingsAccountProductName
      escrowAccountNumber
      escrowAccountIFSCCode
      escrowAccountBranchCode
      escrowAccountName
      escrowAccountMobileNo
      escrowAccountEmail
      escrowAccountAddress
      cashfreeBeneficiaryId
      aadhaarPanConstant
      aadhaarProcCodeOtp
      aadhaarProcCodeAuth
      aadhaarMcc
      aadhaarPosEnteryMode
      aadhaarPosCode
      aadhaarAcqId
      aadhaarCaTidOtp
      aadhaarCaTidAuth
      aadhaarCaId
      aadhaarCaTa
      aadhaarAc
      aadhaarSa
      aadhaarAPIVerion
      aadhaarEncryptionKey
      currentAccountCustomerId
      currentAccountNumber
      currentAccountIFSCCode
      currentAccountBranchCode
      currentAccountName
      currentAccountBalance
      createdAt
      updatedAt
    }
  }
`;
export const createMasterData = /* GraphQL */ `
  mutation CreateMasterData(
    $input: CreateMasterDataInput!
    $condition: ModelmasterDataConditionInput
  ) {
    createMasterData(input: $input, condition: $condition) {
      id
      masterKeyword
      masterValue
      createdAt
      updatedAt
    }
  }
`;
export const updateMasterData = /* GraphQL */ `
  mutation UpdateMasterData(
    $input: UpdateMasterDataInput!
    $condition: ModelmasterDataConditionInput
  ) {
    updateMasterData(input: $input, condition: $condition) {
      id
      masterKeyword
      masterValue
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasterData = /* GraphQL */ `
  mutation DeleteMasterData(
    $input: DeleteMasterDataInput!
    $condition: ModelmasterDataConditionInput
  ) {
    deleteMasterData(input: $input, condition: $condition) {
      id
      masterKeyword
      masterValue
      createdAt
      updatedAt
    }
  }
`;
export const createWaitList = /* GraphQL */ `
  mutation CreateWaitList(
    $input: CreateWaitListInput!
    $condition: ModelWaitListConditionInput
  ) {
    createWaitList(input: $input, condition: $condition) {
      id
      fullName
      eMail
      mobileCountryCode
      mobileNumber
      privacyTermsAccepted
      countryOfResidence
      isExported
      createdAt
      updatedAt
    }
  }
`;
export const updateWaitList = /* GraphQL */ `
  mutation UpdateWaitList(
    $input: UpdateWaitListInput!
    $condition: ModelWaitListConditionInput
  ) {
    updateWaitList(input: $input, condition: $condition) {
      id
      fullName
      eMail
      mobileCountryCode
      mobileNumber
      privacyTermsAccepted
      countryOfResidence
      isExported
      createdAt
      updatedAt
    }
  }
`;
export const deleteWaitList = /* GraphQL */ `
  mutation DeleteWaitList(
    $input: DeleteWaitListInput!
    $condition: ModelWaitListConditionInput
  ) {
    deleteWaitList(input: $input, condition: $condition) {
      id
      fullName
      eMail
      mobileCountryCode
      mobileNumber
      privacyTermsAccepted
      countryOfResidence
      isExported
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerSavingsAccount = /* GraphQL */ `
  mutation CreateCustomerSavingsAccount(
    $input: CreateCustomerSavingsAccountInput!
    $condition: ModelCustomerSavingsAccountConditionInput
  ) {
    createCustomerSavingsAccount(input: $input, condition: $condition) {
      userId
      TotalDailyAccruedBalance
      AvailableToSpendBalance
      TotalInterestEarnedOnDailyAccruedBalance
      TotalInterestEarnedOnAvailableToSpendBalance
      linkedGoalsArray
      type
      savingsAccountStartDate
      Employee {
        id
        OrgId
        UsrEmpId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        usedReferralCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerSavingsAccount = /* GraphQL */ `
  mutation UpdateCustomerSavingsAccount(
    $input: UpdateCustomerSavingsAccountInput!
    $condition: ModelCustomerSavingsAccountConditionInput
  ) {
    updateCustomerSavingsAccount(input: $input, condition: $condition) {
      userId
      TotalDailyAccruedBalance
      AvailableToSpendBalance
      TotalInterestEarnedOnDailyAccruedBalance
      TotalInterestEarnedOnAvailableToSpendBalance
      linkedGoalsArray
      type
      savingsAccountStartDate
      Employee {
        id
        OrgId
        UsrEmpId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        usedReferralCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerSavingsAccount = /* GraphQL */ `
  mutation DeleteCustomerSavingsAccount(
    $input: DeleteCustomerSavingsAccountInput!
    $condition: ModelCustomerSavingsAccountConditionInput
  ) {
    deleteCustomerSavingsAccount(input: $input, condition: $condition) {
      userId
      TotalDailyAccruedBalance
      AvailableToSpendBalance
      TotalInterestEarnedOnDailyAccruedBalance
      TotalInterestEarnedOnAvailableToSpendBalance
      linkedGoalsArray
      type
      savingsAccountStartDate
      Employee {
        id
        OrgId
        UsrEmpId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        usedReferralCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAppConfiguration = /* GraphQL */ `
  mutation CreateAppConfiguration(
    $input: CreateAppConfigurationInput!
    $condition: ModelAppConfigurationConditionInput
  ) {
    createAppConfiguration(input: $input, condition: $condition) {
      id
      bankInterestRate
      androidVersion
      iosVersion
      minAndroidVersion
      minIosVersion
      playStoreUrl
      appStoreUrl
      googlePlayHashKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAppConfiguration = /* GraphQL */ `
  mutation UpdateAppConfiguration(
    $input: UpdateAppConfigurationInput!
    $condition: ModelAppConfigurationConditionInput
  ) {
    updateAppConfiguration(input: $input, condition: $condition) {
      id
      bankInterestRate
      androidVersion
      iosVersion
      minAndroidVersion
      minIosVersion
      playStoreUrl
      appStoreUrl
      googlePlayHashKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAppConfiguration = /* GraphQL */ `
  mutation DeleteAppConfiguration(
    $input: DeleteAppConfigurationInput!
    $condition: ModelAppConfigurationConditionInput
  ) {
    deleteAppConfiguration(input: $input, condition: $condition) {
      id
      bankInterestRate
      androidVersion
      iosVersion
      minAndroidVersion
      minIosVersion
      playStoreUrl
      appStoreUrl
      googlePlayHashKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCashkaroConfiguration = /* GraphQL */ `
  mutation CreateCashkaroConfiguration(
    $input: CreateCashkaroConfigurationInput!
    $condition: ModelCashkaroConfigurationConditionInput
  ) {
    createCashkaroConfiguration(input: $input, condition: $condition) {
      id
      categoryList
      allowedMerchantList
      partnerId
      apiKey
      createdAt
      updatedAt
    }
  }
`;
export const updateCashkaroConfiguration = /* GraphQL */ `
  mutation UpdateCashkaroConfiguration(
    $input: UpdateCashkaroConfigurationInput!
    $condition: ModelCashkaroConfigurationConditionInput
  ) {
    updateCashkaroConfiguration(input: $input, condition: $condition) {
      id
      categoryList
      allowedMerchantList
      partnerId
      apiKey
      createdAt
      updatedAt
    }
  }
`;
export const deleteCashkaroConfiguration = /* GraphQL */ `
  mutation DeleteCashkaroConfiguration(
    $input: DeleteCashkaroConfigurationInput!
    $condition: ModelCashkaroConfigurationConditionInput
  ) {
    deleteCashkaroConfiguration(input: $input, condition: $condition) {
      id
      categoryList
      allowedMerchantList
      partnerId
      apiKey
      createdAt
      updatedAt
    }
  }
`;
export const createCashkaroConversion = /* GraphQL */ `
  mutation CreateCashkaroConversion(
    $input: CreateCashkaroConversionInput!
    $condition: ModelCashkaroConversionConditionInput
  ) {
    createCashkaroConversion(input: $input, condition: $condition) {
      id
      userId
      offerId
      goalId
      actionId
      userBankSavingsAccountNumber
      userBankSavingsAccountBranch
      status
      paymentStatus
      utr
      amount
      orderAmount
      needsPayout
      retryAttempts
      nextScheduledOn
      failedPayments
      createdAt
      updatedAt
    }
  }
`;
export const updateCashkaroConversion = /* GraphQL */ `
  mutation UpdateCashkaroConversion(
    $input: UpdateCashkaroConversionInput!
    $condition: ModelCashkaroConversionConditionInput
  ) {
    updateCashkaroConversion(input: $input, condition: $condition) {
      id
      userId
      offerId
      goalId
      actionId
      userBankSavingsAccountNumber
      userBankSavingsAccountBranch
      status
      paymentStatus
      utr
      amount
      orderAmount
      needsPayout
      retryAttempts
      nextScheduledOn
      failedPayments
      createdAt
      updatedAt
    }
  }
`;
export const deleteCashkaroConversion = /* GraphQL */ `
  mutation DeleteCashkaroConversion(
    $input: DeleteCashkaroConversionInput!
    $condition: ModelCashkaroConversionConditionInput
  ) {
    deleteCashkaroConversion(input: $input, condition: $condition) {
      id
      userId
      offerId
      goalId
      actionId
      userBankSavingsAccountNumber
      userBankSavingsAccountBranch
      status
      paymentStatus
      utr
      amount
      orderAmount
      needsPayout
      retryAttempts
      nextScheduledOn
      failedPayments
      createdAt
      updatedAt
    }
  }
`;
export const createSaathiConfiguration = /* GraphQL */ `
  mutation CreateSaathiConfiguration(
    $input: CreateSaathiConfigurationInput!
    $condition: ModelSaathiConfigurationConditionInput
  ) {
    createSaathiConfiguration(input: $input, condition: $condition) {
      id
      supportEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateSaathiConfiguration = /* GraphQL */ `
  mutation UpdateSaathiConfiguration(
    $input: UpdateSaathiConfigurationInput!
    $condition: ModelSaathiConfigurationConditionInput
  ) {
    updateSaathiConfiguration(input: $input, condition: $condition) {
      id
      supportEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaathiConfiguration = /* GraphQL */ `
  mutation DeleteSaathiConfiguration(
    $input: DeleteSaathiConfigurationInput!
    $condition: ModelSaathiConfigurationConditionInput
  ) {
    deleteSaathiConfiguration(input: $input, condition: $condition) {
      id
      supportEmail
      createdAt
      updatedAt
    }
  }
`;
export const createReferral = /* GraphQL */ `
  mutation CreateReferral(
    $input: CreateReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    createReferral(input: $input, condition: $condition) {
      userId
      referralCode
      maxReferrals
      referralCount
      userReferralOffer
      userReferralOfferType
      refereeOffer
      refereeOfferType
      isEnabled
      referred
      createdAt
      updatedAt
    }
  }
`;
export const updateReferral = /* GraphQL */ `
  mutation UpdateReferral(
    $input: UpdateReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    updateReferral(input: $input, condition: $condition) {
      userId
      referralCode
      maxReferrals
      referralCount
      userReferralOffer
      userReferralOfferType
      refereeOffer
      refereeOfferType
      isEnabled
      referred
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferral = /* GraphQL */ `
  mutation DeleteReferral(
    $input: DeleteReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    deleteReferral(input: $input, condition: $condition) {
      userId
      referralCode
      maxReferrals
      referralCount
      userReferralOffer
      userReferralOfferType
      refereeOffer
      refereeOfferType
      isEnabled
      referred
      createdAt
      updatedAt
    }
  }
`;
export const createKycStatus = /* GraphQL */ `
  mutation CreateKycStatus(
    $input: CreateKycStatusInput!
    $condition: ModelKycStatusConditionInput
  ) {
    createKycStatus(input: $input, condition: $condition) {
      userId
      okycStatus
      okycFailureCount
      ckycStatus
      ckycFailureCount
      numberOfAttempts
      updatedAt
      createdAt
    }
  }
`;
export const updateKycStatus = /* GraphQL */ `
  mutation UpdateKycStatus(
    $input: UpdateKycStatusInput!
    $condition: ModelKycStatusConditionInput
  ) {
    updateKycStatus(input: $input, condition: $condition) {
      userId
      okycStatus
      okycFailureCount
      ckycStatus
      ckycFailureCount
      numberOfAttempts
      updatedAt
      createdAt
    }
  }
`;
export const deleteKycStatus = /* GraphQL */ `
  mutation DeleteKycStatus(
    $input: DeleteKycStatusInput!
    $condition: ModelKycStatusConditionInput
  ) {
    deleteKycStatus(input: $input, condition: $condition) {
      userId
      okycStatus
      okycFailureCount
      ckycStatus
      ckycFailureCount
      numberOfAttempts
      updatedAt
      createdAt
    }
  }
`;
export const createAdminAnalyticalCount = /* GraphQL */ `
  mutation CreateAdminAnalyticalCount(
    $input: CreateAdminAnalyticalCountInput!
    $condition: ModelAdminAnalyticalCountConditionInput
  ) {
    createAdminAnalyticalCount(input: $input, condition: $condition) {
      id
      countUsr
      countGoal
      activeGoals
      cancelledGoals
      archievedGoals
      completedGoals
      goalSavings
      countVoucher
      countUniqueVoucher
      countSharedVoucher
      countUsedVoucher
      totalCashbackValue
      totalVoucherValue
      totalOfferValue
      totalAccountValue
      totalSpendValue
      activeMerchants
      inactiveMerchants
      activeMerchantProd
      inactiveMerchantProd
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateAdminAnalyticalCount = /* GraphQL */ `
  mutation UpdateAdminAnalyticalCount(
    $input: UpdateAdminAnalyticalCountInput!
    $condition: ModelAdminAnalyticalCountConditionInput
  ) {
    updateAdminAnalyticalCount(input: $input, condition: $condition) {
      id
      countUsr
      countGoal
      activeGoals
      cancelledGoals
      archievedGoals
      completedGoals
      goalSavings
      countVoucher
      countUniqueVoucher
      countSharedVoucher
      countUsedVoucher
      totalCashbackValue
      totalVoucherValue
      totalOfferValue
      totalAccountValue
      totalSpendValue
      activeMerchants
      inactiveMerchants
      activeMerchantProd
      inactiveMerchantProd
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdminAnalyticalCount = /* GraphQL */ `
  mutation DeleteAdminAnalyticalCount(
    $input: DeleteAdminAnalyticalCountInput!
    $condition: ModelAdminAnalyticalCountConditionInput
  ) {
    deleteAdminAnalyticalCount(input: $input, condition: $condition) {
      id
      countUsr
      countGoal
      activeGoals
      cancelledGoals
      archievedGoals
      completedGoals
      goalSavings
      countVoucher
      countUniqueVoucher
      countSharedVoucher
      countUsedVoucher
      totalCashbackValue
      totalVoucherValue
      totalOfferValue
      totalAccountValue
      totalSpendValue
      activeMerchants
      inactiveMerchants
      activeMerchantProd
      inactiveMerchantProd
      date
      createdAt
      updatedAt
    }
  }
`;
export const createMerchantAnalyticalCount = /* GraphQL */ `
  mutation CreateMerchantAnalyticalCount(
    $input: CreateMerchantAnalyticalCountInput!
    $condition: ModelMerchantAnalyticalCountConditionInput
  ) {
    createMerchantAnalyticalCount(input: $input, condition: $condition) {
      id
      countUsr
      countGoal
      activeGoals
      cancelledGoals
      archievedGoals
      completedGoals
      goalSavings
      countVoucher
      countUniqueVoucher
      countSharedVoucher
      countUsedVoucher
      totalCashbackValue
      totalVoucherValue
      totalOfferValue
      activeMerchants
      activeMerchantProd
      state
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateMerchantAnalyticalCount = /* GraphQL */ `
  mutation UpdateMerchantAnalyticalCount(
    $input: UpdateMerchantAnalyticalCountInput!
    $condition: ModelMerchantAnalyticalCountConditionInput
  ) {
    updateMerchantAnalyticalCount(input: $input, condition: $condition) {
      id
      countUsr
      countGoal
      activeGoals
      cancelledGoals
      archievedGoals
      completedGoals
      goalSavings
      countVoucher
      countUniqueVoucher
      countSharedVoucher
      countUsedVoucher
      totalCashbackValue
      totalVoucherValue
      totalOfferValue
      activeMerchants
      activeMerchantProd
      state
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteMerchantAnalyticalCount = /* GraphQL */ `
  mutation DeleteMerchantAnalyticalCount(
    $input: DeleteMerchantAnalyticalCountInput!
    $condition: ModelMerchantAnalyticalCountConditionInput
  ) {
    deleteMerchantAnalyticalCount(input: $input, condition: $condition) {
      id
      countUsr
      countGoal
      activeGoals
      cancelledGoals
      archievedGoals
      completedGoals
      goalSavings
      countVoucher
      countUniqueVoucher
      countSharedVoucher
      countUsedVoucher
      totalCashbackValue
      totalVoucherValue
      totalOfferValue
      activeMerchants
      activeMerchantProd
      state
      date
      createdAt
      updatedAt
    }
  }
`;
export const createOrgNotifications = /* GraphQL */ `
  mutation CreateOrgNotifications(
    $input: CreateOrgNotificationsInput!
    $condition: ModelOrgNotificationsConditionInput
  ) {
    createOrgNotifications(input: $input, condition: $condition) {
      id
      OrgId
      createdAt
      Description
      DisplayTo
      ActionType
      Title
      MarkedReadBy
      TaskId
      TaskCompleted
      tenant
      updatedAt
    }
  }
`;
export const updateTransationService = /* GraphQL */ `
  mutation UpdateTransationService(
    $input: UpdateTransationServiceInput!
    $condition: ModelTransationServiceConditionInput
  ) {
    updateTransationService(input: $input, condition: $condition) {
      id
      createdAt
      UserId
      OrgId
      TxnRefNo
      AvailableLimit
      AdvanceAmount
      ConvineneceFee
      DeductionAmount
      FundTransferStatus
      RepaymentSatus
      ReasonDesc
      ApprovalStatus
      RejReason
      DeductionDate
      AmountReceivedUpdatedBy
      ApprovedBy
      ApprovedDateTime
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      lbLoanId
      lbUserId
      leaveTaken
      employeeConsent
      receivedByLendbox
      History
      amountChange
      ApprovalFlow
      amountRequested
      exception
      tenant
      owner
      updatedAt
    }
  }
`;
export const createPushNotification = /* GraphQL */ `
  mutation CreatePushNotification(
    $input: CreatePushNotificationInput!
    $condition: ModelPushNotificationConditionInput
  ) {
    createPushNotification(input: $input, condition: $condition) {
      id
      UsrId
      createdAt
      UsrMobileNo
      UsrEmail
      NotDeviceID
      NotStatus
      NotType
      NotSubType
      NotSentDT
      NotSentDTS
      Priority
      Title
      Subtitle
      MainText
      TxtField1
      TxtField2
      TxtField3
      AppAction
      CreatedAt
      ModifiedAt
      Checked
      tenant
      updatedAt
    }
  }
`;