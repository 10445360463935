import React, {useRef, useState} from 'react'

function FileUploadInput({ label, inputName, req }) {
  const fileRef = useRef(null);
  const [name, setName] = useState("");
  const handleFileInput = (e) => {
    const fileName = fileRef.current.files[0]?.name;
    setName(fileName);
  }
  return (
    <div>
          <label className="block mb-1 text-sm font-medium text-gray-900">
              {label}
              {req && (
                  <span className="text-red-700">
                      *
                  </span>
              )}
          </label>

          <label htmlFor={label} className='w-full shadow-sidebarActive rounded-md h-10 flex items-center pl-3 border border-dashed border-inputBorder text-gray-500 text-sm truncate'>
            {name.length === 0 ? ("Click to Upload"):(name)}
          </label>
          <input onInput={handleFileInput} ref={fileRef} name={inputName} hidden id={label} type="file" />
    </div>
  )
}

export default FileUploadInput