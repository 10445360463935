import React, { useState, useEffect, useRef } from 'react'
import { Auth } from 'aws-amplify'
import { useSelector, useDispatch } from 'react-redux'
import { getUserData } from '../../api/userSlice'
import Logo from '../../assets/images/saathiLogoBlack.svg'
import LinkBox from './LinkBox'
import UserDropdown from './UserDropdown'
import './Sidebar.scss'

function Sidebar({ active, setActive, handleMenuBar }) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.users);
  const [showDropdown, setShowDropdown] = useState(false);
  const [name, setName] = useState("");
  const [email,setEmail] = useState("");
  const userDropDownRef = useRef(null);

  useEffect(() => {
    fetchUserDetails();
  }, [])
  
  const fetchUserDetails = async () => {
    const userInfo = await Auth.currentUserInfo();
    const { attributes, username } = userInfo;
    console.log(userInfo, user);
    if (!user || Object.keys(user).length == 0) {
      dispatch(getUserData(attributes["custom:employerId"]));
    }
    setName(username);
    setEmail(attributes["email"]);
  }

  const handleCloseDropdown = (e) => {
    const menu = userDropDownRef.current;
    const targetElement = e.target;
    const insideDropdown = menu.contains(targetElement);
    if(!insideDropdown) {
      setShowDropdown(prev => !prev);
    }
  }
  return (
    <div onClick={handleCloseDropdown} id='sidebar' className="flex flex-col justify-between w-full">
      <div className='logo relative top-[60px] lg:top-[40px] left-5'>
        <img src={Logo} alt="logo" width={150} height={40} />
      </div>

      <LinkBox handleMenuBar={handleMenuBar} active={active} setActive={setActive} />

      <div className='user-container relative bottom-5 left-5'>
        {showDropdown && (<div ref={userDropDownRef}><UserDropdown setActive={setActive} /></div>)}
        <div onClick={() => setShowDropdown(prev => !prev)} className='user-logo flex flex-row md:flex-col xl:flex-row'>
          <div className="logo-image cursor-pointer">
            <div id="white-ring" className='' />
            <div style={{ background: "#F2E9FB", text: "#A877D9" }} className='w-[46px] h-[46px] flex items-center saathi-font justify-center rounded-full font-bold'>{(user?.EmpGivenName && user?.EmpGivenName[0].toUpperCase()) || (name && name[0].toUpperCase())}</div>
          </div>
          <div className='user-info ml-[16px]'>
            <h3 className="username text-[16px]">{user?.EmpGivenName || name}</h3>
            <h4 className="userEmail text-[12px] opacity-50">
              {email}
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar