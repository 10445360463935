import React from 'react'
import { useSelector } from 'react-redux';
import EditUserModal from './EditUserModal';

import GoalsTab from './GoalsTab';

import editIcon from '../../../assets/images/editIcon.svg';

function GoalsModal({ setOpenEditModal, openEditModal, editFlag, setEditFlag }) {

    const { currentUser, goals } = useSelector(state => state.users)
    const showEditModal = () => {
        setEditFlag(true);
    }
    return (
        <>
            {editFlag ? (
                <EditUserModal openEditModal={openEditModal} setOpenEditModal={setOpenEditModal} editFlag={editFlag} setEditFlag={setEditFlag} />
            ) : (
                <div className='absolute top-1/2 left-1/2 w-[90vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[8px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'>

                    <div className='p-[32px]'>
                        <h1 className='text-[32px]'>User Information</h1>
                        <div className='shadow-goalsUserTable rounded-lg h-[150px] border-[0.5px] border-goalsUserTable p-7 mt-5'>
                            <div className='flex justify-between'>
                                <h3 className='text-[18px]'>Personal Information</h3>
                                <img src={editIcon} onClick={showEditModal} className='cursor-pointer' alt="" />
                            </div>
                            <div className='grid grid-cols-12 mt-5 h-[45px]'>
                                <div className='col-span-4'>
                                    Name
                                    <br />
                                    {currentUser?.UsrGivenName} {' '} {currentUser?.UsrFamilyName}
                                </div>
                                <div className='col-span-4'>
                                    Mobile
                                    <br />
                                    {currentUser.CountryCode && `+${currentUser.CountryCode} `} {currentUser?.UsrMobileNo}
                                </div>
                                <div className='col-span-4'>
                                    Email
                                    <br />
                                    {currentUser?.UsrEmail}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='grid grid-cols-12 gap-3 ml-8 mb-5'>
                        <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                            User ID
                            <br />
                            <span className='overflow-x-auto' style={{ color: "#828282", width: "90%" }}>{currentUser.id}</span>
                        </div>
                        <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                            First Time User Login
                            <br />
                            <span style={{ color: "#828282" }}>{currentUser?.UsrFirstTimeLoginFlag || "-"}</span>
                        </div>
                        <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                            Last Logged In
                            <br />
                            <span style={{ color: "#828282" }}>{currentUser?.LastLoggedIn || "-"}</span>
                        </div>
                        <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                            Org ID
                            <br />
                            <span className='w-[90%] overflow-x-auto' style={{ color: "#828282" }}>
                                {currentUser?.OrgId || "-"}
                            </span>
                        </div>
                        <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                            Profile Lock
                            <br />
                            <span style={{ color: "#828282" }}>
                                {currentUser?.ProfileLock || "-"}
                            </span>
                        </div>
                        <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                            Completed Grow Questionnaire?
                            <br />
                            <span style={{ color: "#828282" }}>
                                {currentUser?.hasUserCompletedGrowQuestionnaire ? "Yes" : "No"}
                            </span>
                        </div>
                        <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                            User KYC Status
                            <br />
                            <span style={{ color: "#828282" }}>
                                {currentUser?.UsrKYCStatus ? "Verified" : "Not Verified"}
                            </span>
                        </div>
                        <div className='col-span-12 sm:col-span-6 md:col-span-4'>
                            Number of Attempts
                            <br />
                            <span style={{ color: "#828282" }}>
                                {currentUser?.NoOfAttempt || "-"}
                            </span>
                        </div>
                    </div>

                    <div className='mx-8'>
                        <h3 className='text-[18px]'>Goals Information</h3>
                        <p>Total Goals: {goals && goals.length}</p>

                        <GoalsTab />
                    </div>
                </div>
            )}
        </>
    )
}

export default GoalsModal