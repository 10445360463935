import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { getEmployer, listEmployees, listGoals, getGoal } from '../graphql/queries'
import { updateEmployee } from "../graphql/mutations";
import { handleAPICall } from './APIUtils'
import { fetchAllDataFromGraphQLQuery } from '../common/Utility'

const initialState = {
    loading: false,
    error: '',
    user: {},
    userListLoading: false,
    userList: [],
    currentUser: {},
    id: '',
    goalLoading: false,
    goalError: "",
    goals: [],
    broadcastLoading: false,
    broadcastError: "",
    broadcastSuccess: ""
};

export const getUserData = createAsyncThunk('user/fetchUser', async (userId) => {
    return (
        await API.graphql({
            query: getEmployer,
            variables: { id: userId },
        })
    ).data.getEmployer
})

export const getUserListAPI = createAsyncThunk("user/fetchUserList",async (_, thunkAPI) => {
    try {
        const res = await fetchAllDataFromGraphQLQuery({
            query: listEmployees,
            variables: {
                limit: 2000
            },
            queryName: "listEmployees"
        })

        return res;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const getUserGoalsAPI = createAsyncThunk("user/goals", async(body, thunkAPI) => {
    try {
        const { id } = body;
        const res = await fetchAllDataFromGraphQLQuery({
            query: listGoals,
            variables: {
                limit: 2000,
                filter: {
                    userId: {
                        eq: id
                    }
                }
            },
            queryName: "listGoals"
        })

        return res;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateUserAPI = createAsyncThunk("user/update", async ({ body, callback = () => { } }, thunkAPI) => {
    try {
        const res = await API.graphql({
            query: updateEmployee,
            variables: {
                input: body
            }
        })
        callback(res);
        if(res.errors && res.errors.length>0) {
            return thunkAPI.rejectWithValue(res.errors[0].message);
        }else{
            return res.data;
        }
    } catch (error) {
        callback({
            errors:[{
                message: error.message
            }]
        })
        return thunkAPI.rejectWithValue(error.message);
    }
})

export const sendBroadcastMessageAPI = createAsyncThunk("broadcast/send", async ({ body, callback = () => { } }, thunkAPI) => {
    try {
        const options = {
            body: body
        }
        const data = await handleAPICall("broadcastMessageApi","",options, "POST");
        callback(data);
        if (data.errors && Object.keys(data.errors).length > 0) {
            return thunkAPI.rejectWithValue(data.errors[0]?.message || "Something went wrong!");
        }
        return data.success;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const userSlice = createSlice({
    name: "User",
    initialState,
    reducers: {
        saveCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        updateUserList: (state, action) => {
            state.userList = action.payload;
        },
        setUserError: (state, action) => {
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserData.pending, (state) => {
            state.loading = true;
            state.error = "";
        })
        builder.addCase(getUserData.fulfilled, (state, action) => {
            state.loading = false;
            state.error = "";
            state.user = action.payload;
            state.id = action.payload?.id;
        })
        builder.addCase(getUserData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message
            state.user = {}
        })

        builder.addCase(getUserListAPI.pending, (state) => {
            state.userListLoading = true;
            state.error = "";
        })
        builder.addCase(getUserListAPI.fulfilled, (state, action) => {
            state.userListLoading = false;
            state.userList = action.payload;
            state.error = "";
        })
        builder.addCase(getUserListAPI.rejected, (state, action) => {
            state.userListLoading = false;
            try {
                state.error = action.payload.error.response.data.error.message
            } catch (error) {
                state.error = "Something went Wrong"
            }
        })

        builder.addCase(getUserGoalsAPI.pending, (state, action) => {
            state.goalLoading = true;
            state.goalError = "";
        })
        builder.addCase(getUserGoalsAPI.fulfilled, (state, action) => {
            state.goalLoading = false;
            state.goalError = "";
            state.goals = [...action.payload];
        })
        builder.addCase(getUserGoalsAPI.rejected, (state, action) => {
            state.goalLoading = false;
            try {
                state.goalError = action.payload.error.response.data.error.message
            } catch (error) {
                state.goalError = "Something went Wrong"
            }
        })

        builder.addCase(updateUserAPI.pending, (state, action) => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(updateUserAPI.fulfilled, (state, action) => {
            state.loading = false;
            try {
                const updatedEmployee = action.payload["updateEmployee"];
                const id = updatedEmployee["id"];
                const it = state.userList.findIndex(e => e.id === id);
                if (it !== -1) {
                    state.userList[it] = updatedEmployee
                }
            } catch (error) {
                
            }
            state.error = "";
        })
        builder.addCase(updateUserAPI.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(sendBroadcastMessageAPI.pending, (state, action) => {
            state.broadcastLoading = true;
            state.broadcastError = "";
        })

        builder.addCase(sendBroadcastMessageAPI.fulfilled, (state, action) => {
            state.broadcastLoading = false;
            try {
                state.broadcastSuccess = action.payload[0].message;
            } catch (error) {
                state.broadcastSuccess = "Message Sent Successfully";
            }
        })

        builder.addCase(sendBroadcastMessageAPI.rejected, (state, action) => {
            state.broadcastLoading = false;
            state.broadcastError = action.payload || "Something went wrong!"
        })
    }
})

export const { saveCurrentUser, updateUserList, setUserError } = userSlice.actions;

export default userSlice.reducer