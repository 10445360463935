import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import toast from 'react-hot-toast'
import { setUserError, updateUserAPI } from '../../../api/userSlice';
import InputField from '../../../sharedComponents/InputField/InputField'
import ProductParameterInput from '../Product/ProductParameterInput';


function EditUserModal({ setOpenEditModal, openEditModal, editFlag, setEditFlag }) {
    const dispatch = useDispatch();
    const [timeStamp, setTimeStamp] = useState(new Date().toISOString().replace(/\.\d+/, '').replace('Z', ''));
    const { currentUser, error } = useSelector(
        state => state.users
    )
    useEffect(() => {
        try {
            const ts = currentUser.SavingsTermsAndConditionsTS
            setTimeStamp(new Date(ts).toISOString().replace(/\.\d+/, '').replace('Z', ''))
        } catch (error) {
            return
        }

    }, [currentUser])

    const onClose = () => {
        setOpenEditModal(false);
        setEditFlag(false);
    }

    const callback = (data, flag = false) => {
        setOpenEditModal(false);
        if (data.errors && Object.keys(data.errors).length > 0) {
            try {
                toast.error(`${data.error.error.response.data.error.message}`);
            } catch (error) {
                toast.error(`Error while Editing User`);
            }
            return;
        }
        toast.success("User info Edited successfully")
    }

    const onSubmitForm = (e) => {
        if (!openEditModal) {
            e.preventDefault();
            return;
        }
        e.preventDefault();
        try {
            const formElements = e.target.elements;
            let formObj = {};
            formObj["id"] = currentUser["id"];
            for (let i = 0; i < formElements.length; i++) {
                const ele = formElements[i];
                const name = ele.name;
                if (name.length > 0) {
                    if (name === 'hasUserCompletedGrowQuestionnaire' || name === 'useBiometrics' || name === 'aadharPassedAuthCheck' || name === 'aadharPassedOTPCheck' || name === 'faceIDPassed' || name === 'panCheckPassed' || name === 'threeWayCheckPassed' || name === 'SavingsTermsAndConditionsAcceptance') {
                        formObj[name] = ele.checked;
                        continue;
                    }
                    if (name === 'SavingsTermsAndConditionsTS') {
                        formObj[name] = new Date(ele.value).toISOString();
                        continue;
                    }
                    formObj[name] = ele.value;
                }
            }
            dispatch(updateUserAPI({ body: formObj, callback }));
            onClose();

        } catch (error) {
            dispatch(setUserError(error.message));
        }

    }
    return (
        <div id='create-user-modal' className='absolute top-1/2 left-1/2 w-[90vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'>
            <div className="container p-3 py-6 flex flex-col items-center md:p-10">
                <h1 className="text-[24px] mb-4">
                    Edit User
                </h1>
                <form onSubmit={onSubmitForm} className="w-full flex flex-col items-center">
                    
                    <div className="grid grid-cols-1 mt-4 w-full md:w-4/5 gap-2 md:gap-4">

                        <div className='w-full'>
                            <InputField name='UsrEmail' value={currentUser?.UsrEmail} label="User Email" req />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='UsrMobileNo' value={currentUser?.UsrMobileNo} label="User Mobile" req />
                        </div>
                        <div className='w-full'>
                            <InputField name='UsrGivenName' value={currentUser.UsrGivenName} label="First Name" req />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='UsrMiddleName' value={currentUser?.UsrMiddleName} label="User Middle Name" req />
                        </div>
                        <div className='w-full'>
                            <InputField name='UsrFamilyName' value={currentUser?.UsrFamilyName} label="User Family Name" req />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='UsrFirstTimeLoginFlag' value={currentUser?.UsrFirstTimeLoginFlag} label="User First Time Login Flag" req />
                        </div>
                        <div className='w-full'>
                            <InputField name='ProfileLock' value={currentUser?.ProfileLock} label="Profile Lock" />
                        </div>
                    </div>
                    
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='flex w-4/5 items-center'>
                            <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox" name="hasUserCompletedGrowQuestionnaire" defaultChecked={currentUser?.hasUserCompletedGrowQuestionnaire || false} id="grow-quest" />
                            <div className='text-sm ml-3'>
                                Completed Grow Questionnaire?
                            </div>
                        </div>

                        <div className='w-full'>
                            <InputField name='Title' value={currentUser?.Title} label="Title" />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='Gender' value={currentUser?.Gender} label="Gender" />
                        </div>

                        <div className='w-full'>
                            <InputField name='State' value={currentUser?.State} label="State" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='City' value={currentUser?.City} label="City" />
                        </div>

                        <div className='w-full'>
                            <InputField name='Street1' value={currentUser?.Street1} label="Street 1" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full lg:w-4/5 gap-2 lg:gap-4">
                        <div className='w-full'>
                            <InputField name='Street2' value={currentUser?.Street2} label="Street2" />
                        </div>

                        <div className='w-full'>
                            <InputField name='PostCode' value={currentUser?.PostCode} label="Post Code" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full lg:w-4/5 gap-2 lg:gap-4">
                        <div className='w-full'>
                            <InputField name='addressBuildingNumber' value={currentUser?.addressBuildingNumber} label="Building Number" />
                        </div>

                        <div className='w-full'>
                            <InputField name='addressHouse' value={currentUser?.addressHouse} label="Address House" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='addressLandmark' value={currentUser?.addressLandmark} label="Landmark" />
                        </div>

                        <div className='w-full'>
                            <InputField name='DateOfBirth' value={currentUser?.DateOfBirth} label="Date of Birth" placeholder='DD-MM-YYYY' />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='CountryCode' value={currentUser?.CountryCode} label="Country Code" req />
                        </div>
                    </div>
                    
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='snplBankCustomerId' value={currentUser?.snplBankCustomerId} label="Bank Customer ID" req />
                        </div>

                        <div className='w-full'>
                            <InputField name='snplBankSavingsAccountNumber' value={currentUser?.snplBankSavingsAccountNumber} label="Bank Savings A/C Num" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='snplBankSavingsBranch' value={currentUser?.snplBankSavingsBranch} label="Bank Savings Branch" />
                        </div>
                        <div className='w-full'>
                            <InputField name='snplBankSavingsIFSCCode' value={currentUser?.snplBankSavingsIFSCCode} label="IFSC Code" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="T&C_TS">Savings T&C TS</label>
                            <div className="relative mt-1 rounded-md shadow-sidebarActive w-full">
                                <input className="block w-full h-10 rounded-md border border-inputBorder pl-7 pr-7 outline-none sm:text-sm" type="datetime-local" step="1" name="SavingsTermsAndConditionsTS" defaultValue={timeStamp} required />
                            </div>

                        </div>
                        <div className='flex w-4/5 items-center'>
                            <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox" name="SavingsTermsAndConditionsAcceptance" defaultChecked={currentUser?.SavingsTermsAndConditionsAcceptance || false} />
                            <div className='text-sm ml-3'>
                                Savings T&C Acceptance
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='SavingsTermsAndConditionsIP' value={currentUser?.SavingsTermsAndConditionsIP} label="Savings T&C IP" req />
                        </div>
                        <div className='w-full lg:w-4/5'>
                            <InputField name='UsrKYCStatus' value={currentUser?.UsrKYCStatus} label="KYC Status" />

                        </div>
                    </div>
                    
                    <div className="grid grid-cols-1 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='snplDebitCardName' value={currentUser?.snplDebitCardName} label="Debit Card Name" />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full md:w-full'>
                            <button onClick={() => onClose()} className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full font-bold flex justify-center items-center' >
                                Cancel
                            </button>
                        </div>
                        <div className='w-full md:w-full'>
                            <button type='submit' className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full flex justify-center items-center' >
                                Save
                            </button>
                        </div>
                    </div>

                    <p onClick={() => setEditFlag(prev => !prev)} className="font-bold underline my-3 cursor-pointer">Click to View Goals Summary</p>

                    {(error && error.length > 0) && (
                        <div className='block text-sm text-red-600'>{error}</div>
                    )}
                </form>

            </div>
        </div>
    )
}

export default EditUserModal