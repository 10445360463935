import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../api/userSlice";
import merchantSlice from "../api/merchantSlice";
import productSlice from "../api/productSlice";
import articleSlice from "../api/articleSlice";
import merchantProductCatSlice from '../api/productCategorySlice'
import analyticsSlice from "../api/analyticsSlice";

export default configureStore({
    reducer: {
        users: userSlice,
        merchant: merchantSlice,
        product: productSlice,
        articles: articleSlice,
        merchantProductCat: merchantProductCatSlice,
        analytics: analyticsSlice
    }
})