import React from 'react'
import { sidebarLinks } from '../../constants/UIConstants'
function LinkBox({ active, setActive, handleMenuBar }) {
    const handleActive = (link) => {
        handleMenuBar()
        setActive(link);
    }
    const classes = (flag) => {
        return (
            `flex cursor-pointer items-center w-[216px] lg:w-[16vw] h-[40px] m-auto mt-[5px] rounded-[8px] ${flag && 'bg-white shadow-sidebarActive border-2 border-sidebarActive'}`
        )
    }
  return (
      <div className="link-group relative top-[-40px] lg:top-[-50px]">
          {sidebarLinks.map((li, id) => {
              const flag = li.link === active;
              return (
                  <div onClick={() => handleActive(li.link)} key={`link_${id}`} className={classes(flag)}>
                      <img width={16} height={16} className='sidebar-logo mx-[16px]' src={li.logo} alt={li.link} />
                      <div className='text-[16px] font-bold'>
                          {li.link}
                      </div>
                  </div>
              )
          })}
      </div>
  )
}

export default LinkBox