import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { getAdminAnalyticalCount, getMerchantAnalyticalCount, listAdminAnalyticalCounts } from '../graphql/queries'

const initialState = {
    adminAnalytics: [],
    merchantAnalytics: [],
    analyticsLoading: false,
    analyticsError: ""
}

export const getAdminAnalyticsAPI = createAsyncThunk("analytics/admin", async ({ id }, thunkAPI) => {
    try {
        const res = await API.graphql({
            query: listAdminAnalyticalCounts
        })

        if(res.errors && res.errors.length>0){
            return thunkAPI.rejectWithValue(res.errors[0].message)
        } else {
            return res.data;
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
})

export const getMerchantAnalyticsAPI = createAsyncThunk("analytics/merchant", async ({ id }, thunkAPI) => {
    try {
        const res = await API.graphql({
            query: getMerchantAnalyticalCount,
            variables: {
                id: id
            }
        });

        if(res.errors && res.errors.length>0){
            return thunkAPI.rejectWithValue(res.errors[0].message)
        } else {
            return res.data;
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
})

const analyticsSlice = createSlice({
    name:"Analytics",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getAdminAnalyticsAPI.pending, (state, action) => {
            state.analyticsLoading = true;
            state.analyticsError = "";
        })

        builder.addCase(getAdminAnalyticsAPI.fulfilled, (state, action) => {
            state.analyticsLoading = false;
            state.adminAnalytics = action.payload?.listAdminAnalyticalCounts?.items;
            state.analyticsError = "";
        })

        builder.addCase(getAdminAnalyticsAPI.rejected, (state, action) => {
            state.analyticsLoading = false;
            state.analyticsError = action.payload;
        })

        builder.addCase(getMerchantAnalyticsAPI.pending, (state, action) => {
            state.analyticsLoading = true;
            state.analyticsError = "";
        })

        builder.addCase(getMerchantAnalyticsAPI.fulfilled, (state, action) => {
            state.analyticsLoading = false;
            state.merchantAnalytics = action.payload;
            state.analyticsError = "";
        })

        builder.addCase(getMerchantAnalyticsAPI.rejected, (state, action) => {
            state.analyticsLoading = false;
            state.analyticsError = action.payload;
        })
    }
})

export default analyticsSlice.reducer;

