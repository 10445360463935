import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import { sendBroadcastMessageAPI } from '../../../api/userSlice';

function SelectIcon({ flag = false, identifier, setCount }) {
    const [selectFlag, setSelectFlag] = useState(false);
    useEffect(() => {
      if(flag) {
        setSelectFlag(true);
      } else {
        setSelectFlag(false);
      }
    }, [flag])

    const handleClick = () => {
        if (!selectFlag) {
            setCount(prev => prev + 1);
        } else {
            setCount(prev => {
                if(prev>0){
                    prev--;
                }
                return prev;
            });
        }

        setSelectFlag(prev => !prev);
    }
    
  return (
      <svg onClick={handleClick} className={(selectFlag) ? `selected_usr ${identifier}` : identifier} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM5.832 11.432L2.96 8.56C2.648 8.248 2.648 7.744 2.96 7.432C3.272 7.12 3.776 7.12 4.088 7.432L6.4 9.736L11.904 4.232C12.216 3.92 12.72 3.92 13.032 4.232C13.344 4.544 13.344 5.048 13.032 5.36L6.96 11.432C6.656 11.744 6.144 11.744 5.832 11.432Z" fill={(selectFlag) ? "#F81CA2" : "#D8D8D8"} />
      </svg>
  )
}


function SendBroadcastTable({ title, setTitle, msg, setMsg, setOpenBroadcastModal }) {
    const dispatch = useDispatch();
    const { userList, loading } = useSelector(state => state.users);
    const [selectAll, setSelectAll] = useState(false);
    const [count, setCount] = useState(0);

    const callback = (data) => {
        if (data.errors && Object.keys(data.errors).length > 0) {
            try {
                toast.error(data.errors[0].message)
            } catch (error) {
                toast.error("Something went Wrong, try again!");
            }
        }else {
            setTitle("");
            setMsg("");
            toast.success("Message sent successfully!");
            setOpenBroadcastModal(false);
        }
    }

    const sendMessage = () => {
        const usrs = document.querySelectorAll(".selected_usr");
        let ids = [];
        usrs.forEach((ele) => {
            try {
                const it = ele.classList[1];
                ids.push(userList[it].id);
            } catch (error) {
                return ele;
            }
        })
        if(ids.length>0) {
            const body = {
                title: title,
                text: msg,
                userIds: ids
            };
            dispatch(sendBroadcastMessageAPI({ body, callback }));
        }
    }

    const handleSelectAll = () => {
        if(!selectAll) {
            setCount(userList.length);
        } else {
            setCount(0);
        }
        setSelectAll(prev => !prev);
    }
    
    return (
        <div className='px-12 py-6 overflow-y-auto'>
            <div className='w-full flex justify-between'>
                <div>
                    {/* <h2 className='text-3xl'></h2> */}
                    <button onClick={sendMessage} className='text-xl px-5 py-3 outline-none rounded-xl bg-black text-white'>
                        Send To
                    </button>
                </div>
                <div className='flex items-center'>
                    <div className='cursor-pointer' onClick={handleSelectAll}>
                        <SelectIcon setCount={setCount} flag={selectAll} identifier={"select_all_btn"} />
                    </div>
                    <p className='ml-[6px] select-none'>Select All Users</p>
                </div>
            </div>

            <div className='w-full mt-6 overflow-x-auto'>
                <table className='min-w-full'>
                    <tr className='h-10 bg-tableHeading select-none'>
                        <th className='pl-4 min-w-[150px]'>
                            {count} Selected
                        </th>
                        <th>Id</th>
                        <th className='min-w-[200px]'>Name</th>
                    </tr>
                    {loading ? 
                    <>
                        <TailSpin
                            height="60"
                            width="60"
                            color="#62A6FF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            visible={true}
                        />
                    </>:
                    <>
                        {userList && userList.map((ele, it) => {
                            const { id, UsrGivenName, UsrFamilyName } = ele;

                            return (
                                <tr style={{ borderWidth: "0px 0.5px 0.3px 0.5px"}} className='h-10 border-inputBorder select-none'>
                                    <td>
                                        <div className='cursor-pointer w-fit mx-auto'>
                                            <SelectIcon setCount={setCount} flag={selectAll} identifier={it}/> 
                                        </div>
                                         
                                    </td>

                                    <td className='max-w-[90%] truncate'>
                                        {id}
                                    </td>

                                    <td>
                                        {`${UsrGivenName} ${UsrFamilyName}`}
                                    </td>
                                </tr>
                            )
                        })}
                    </>}
                </table>
            </div>
        </div>
    )
}

export default SendBroadcastTable