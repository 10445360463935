import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import AuthenticationPage from './pages/AuthenticationPage/AuthenticationPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import Upload from './fileupload/Upload';
import prodEnv from './aws-exports';
import devEnv from './aws-exports-dev';
import { Amplify } from 'aws-amplify';
import './App.scss';

//x
const env = process.env.REACT_APP_ENVIRONMENT;

if (env == 'production') {
  Amplify.configure(prodEnv);
} else {
  Amplify.configure(devEnv);
}

function App() {
  useEffect(() => {
    const saathiAccessToken = sessionStorage.getItem('saathi_accessToken');
    const saathiIdToken = sessionStorage.getItem('saathi_idToken');
    const path = window.location.pathname;
    if ((!saathiAccessToken || !saathiIdToken) && path !== '/') {
      window.location.href = '/';
    }
  }, []);

  return (
    <div className='App'>
      <Routes>
        <Route path='/dashboard' element={<DashboardPage />} />
        {/* <Route path="/upload" element={<Upload />} /> */}
        <Route path='/' element={<AuthenticationPage />} />
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
