import { API } from "aws-amplify";

export const checkStatusCode = (data) => {
    try {
        const { error } = data;
        if(error.code){
            return false;
        }
        return true;
    } catch (error) {
        return false;
    }
}

export const handleAPICall = async (apiName, pathName, options, method) => {
    try {
        let res = {};
        switch (method) {
            case "GET":
                res = await API.get(apiName, pathName);
                return res;
            case "POST":
                res = await API.post(apiName, pathName, options);
                return res;
            case "UPDATE":
                res = await API.patch(apiName, pathName, options);
                return res;
            case "DELETE":
                res = await API.post(apiName, pathName, {});
                return res;
            default:
                break;
        }
    } catch (error) {
        return {
            error: {
                code: 500,
                error: error
            }
        }
    }
}