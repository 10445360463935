import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'

import searchIcon from '../../assets/images/searchIcon.svg'

function FilterDropdown({ showFilterMenu, filterItems, setFilterItems, type }) {
    const { loading, error, merchantList } = useSelector(state => state.merchant)

    const { productListLoading, productList, productListError } = useSelector(state => state.product);

    const { articleLoading, articleError, articles } = useSelector(state => state.articles);

    const { merchantProductCategories, productCategoryLoading, productCategoryError } = useSelector(state => state.merchantProductCat);

    const { userList, userListLoading } = useSelector(state => state.users);

    const [searchRes, setSearchRes] = useState("");
    const [listItems, setListItems] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        switch (type) {
            case 'Merchant':
                let s2 = new Set();
                merchantList.map((ele) => {
                    s2.add(ele.merchant_name);
                })
                setListItems([...s2]);
                setPageLoading(loading);
                break;
            case 'Product':
                let s1 = new Set();
                productList.map((ele) => {
                    s1.add(ele.productName);
                })
                setListItems([...s1]);
                setPageLoading(productListLoading);
                break;
            case 'Articles':
                let s = new Set();
                articles.map((ele) => {
                    s.add(ele.articleType);
                })
                setListItems([...s]);
                setPageLoading(articleLoading);
                break;
            case 'Categories':
                setListItems(merchantProductCategories);
                setPageLoading(productCategoryLoading);
                break;
            case 'Users':
                setListItems(userList);
                setPageLoading(userListLoading);
                break;
            default:
                break;
        }
    }, [type])

    useEffect(() => {
        if (showFilterMenu) {
            if (filterItems && filterItems.length > 0) {
                filterItems.map((ele) => {
                    setTimeout(() => {
                        const label = document.getElementById(`radio_${ele}`);
                        if (label) {
                            try {
                                label.children[0].classList.remove("hidden")
                            } catch (error) {
                            }
                        } else {
                        }
                    }, 200);
                })
            }
        }
    }, [showFilterMenu, pageLoading])

    const handleSearchInput = (e) => {
        setSearchRes(e.target.value);
    }

    const handleSearchFilter = (item) => {
        if (searchRes.length === 0) {
            return true;
        }
        try {
            const { categoryName, UsrFamilyName } = item;
            if (type === 'Merchant') {
                return item.toLowerCase().includes(searchRes.toLowerCase());
            } else if (type === 'Product') {
                return item.toLowerCase().includes(searchRes.toLowerCase());
            } else if (type === 'Articles') {
                return item.toLowerCase().includes(searchRes.toLocaleLowerCase());
            } else if (type === 'Categories') {
                return categoryName.toLocaleLowerCase().includes(searchRes.toLowerCase());
            } else if (type === 'Users') {
                return UsrFamilyName.toLowerCase().includes(searchRes.toLowerCase());
            }
        } catch (error) {
            return true;
        }
    }

    const handleSelectFilter = (id) => {
        const label = document.getElementById(`radio_${id}`);
        if (label.children[0].classList.contains("hidden")) {
            const oldFilter = [...filterItems];
            oldFilter.push(id);
            sessionStorage.setItem(`filter_${type}`, JSON.stringify(oldFilter));
            setFilterItems([...oldFilter]);
        } else {
            const oldFilter = [...filterItems];
            const idx = oldFilter.findIndex((e) => e === id);
            if (idx !== -1) {
                oldFilter.splice(idx, 1);
                sessionStorage.setItem(`filter_${type}`, JSON.stringify(oldFilter));
                setFilterItems([...oldFilter]);
            }
        }
        label.children[0].classList.toggle("hidden")
    }

    const labelClassName = "border-2 flex items-center justify-center cursor-pointer border-radioBtn h-[20px] w-[20px] rounded-full"
    return (
        <>
            <Transition
                show={showFilterMenu}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <div className="w-[245px] h-[291px] z-50 absolute mt-3 right-2 bg-dropdown rounded-lg shadow-sidebarActive flex flex-col items-center">

                    <div className='mt-5 flex items-center w-[185px] h-10 p-3 rounded-md bg-btnGray'>
                        <img width={16} height={16} src={searchIcon} alt="" />
                        <input type="text" onChange={handleSearchInput}
                            className='ml-2 h-[19px] bg-btnGray outline-none w-[125px] text-sm' placeholder='Search...' />
                    </div>
                    <div className='flex flex-col w-[185px] mt-4 overflow-y-auto h-[200px]'>
                        {!pageLoading && (
                            <>
                                {listItems?.filter(handleSearchFilter).map((ele, id) => {
                                    let name = "";
                                    if (type === 'Merchant') {
                                        name = ele;
                                    } else if (type === 'Product') {
                                        name = ele;
                                    } else if (type === 'Articles') {
                                        name = ele;
                                    } else if (type === 'Categories') {
                                        name = ele.categoryName
                                    } else if (type === 'Users') {
                                        name = ele.UsrFamilyName;
                                    }
                                    // const { merchant_name } = ele
                                    return (
                                        <div key={`radio_${id}`} className='flex items-center h-6 w-full my-2'>
                                            <label
                                                className={labelClassName}
                                                id={`radio_${name}`}
                                                onClick={() => handleSelectFilter(name)}
                                            >
                                                <svg className='hidden' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#F81CA2" />
                                                </svg>

                                            </label>
                                            <p className="ml-3 truncate max-w-[120px]">{name}</p>
                                        </div>
                                    )
                                })}
                            </>
                        )}
                    </div>
                </div>
            </Transition>
        </>
    )
}

export default FilterDropdown