import React, { useState, useEffect, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import SelectMenu from '../SelectMenu/SelectMenu';
import prevIcon from '../../assets/images/prevIcon.svg'
import nextIcon from '../../assets/images/nextIcon.svg'
import upIcon from '../../assets/images/upIcon.svg'

function Pagination({ type = "Merchants", size, setCurrentPage, currentPage, recordsPerPage = 10, setRecordsPerPage }) {
    const [numPages, setNumPages] = useState(1);
    const [flag, setFlag] = useState(false);
    const [indexNo, setIndexNo] = useState(0);
    const [paginationArray, setPaginationArray] = useState([[]]);

    const options = [
        {
            label: "10",
            value: 10
        },
        {
            label: "20",
            value: 20
        },
        {
            label: "50",
            value: 50
        },
        {
            label: "100",
            value: 100
        },
        {
            label: "200",
            value: 200
        }
    ]

    const handleCurrentPage = (num) => {
        if(num>numPages || num<1){
            return;
        }
        setCurrentPage(num);
    }

    const nextPage = () => {
        if (currentPage === numPages) {
            return;
        }
        if ((currentPage) % 3 === 0) {
            setIndexNo((currentPage) / 3);
        }
        setCurrentPage(prev => prev + 1);
    }

    const prevPage = () => {
        if (currentPage === 1) {
            return;
        }
        if ((currentPage-1) % 3 === 0) {
            setIndexNo(Math.max(((currentPage - 1) / 3) - 1, 0));
        }
        setCurrentPage(prev => prev - 1);
    }

    const toFirstPage = () => {
        setCurrentPage(1);
        setIndexNo(0);
    }

    const toLastPage = () => {
        setCurrentPage(numPages);
        setIndexNo(paginationArray.length-1);
    }

    const convertTo2DArray = (len) => {
        const arr = Array.from({ length: len }, (_, i) => i + 1);
        const result = [];
        for (let i = 0; i < arr.length; i += 3) {
            result.push(arr.slice(i, i + 3));
        }
        return result;
    }

    useEffect(() => {
        let entriesPerPage = size / recordsPerPage;
        if (parseInt(entriesPerPage) === 0) {
            setNumPages(1);
            setPaginationArray(convertTo2DArray(1));
        } else if (entriesPerPage === parseInt(entriesPerPage)) {
            setNumPages(entriesPerPage);
            setPaginationArray(convertTo2DArray(entriesPerPage))
        } else {
            setNumPages(Math.ceil(size / recordsPerPage))
            setPaginationArray(convertTo2DArray(Math.ceil(size / recordsPerPage)))
        }
    }, [size, recordsPerPage])

    const classes = "mx-auto my-3 mt-12 border border-pagination h-10 rounded-lg bg-white flex justify-between items-center px-1 sm:px-5 fixed left-3 lg:left-[268px] lg:w-[calc(100%_-_300px)] w-[calc(100%_-_30px)] bottom-3"

    const btnClasses = "text-[12px] md:text-[16px] cursor-pointer mx-3 h-4 flex items-center justify-center transition-all"

    return (
        <div className={classes}>
            <div className='text-[12px] md:text-[16px]'>
                {size} {type}
            </div>
            <div className='flex h-full justify-center items-center'>
                <div onClick={toFirstPage} className={`flex justify-center items-center mr-2 cursor-pointer ${currentPage === 1 && "opacity-40"}`}>
                    <img src={prevIcon} alt="" height={14} width={14} />
                    <img src={prevIcon} alt="" height={14} width={14} />
                </div>

                <img onClick={prevPage} height={14} width={14} src={prevIcon} className="cursor-pointer" alt="" />

                <>
                    {paginationArray[indexNo].map((e, id) => {
                        let key = false;

                        if (e === currentPage) {
                            key = true;
                        }
                        if(numPages<3) {
                            return (
                                <div onClick={() => handleCurrentPage(e)} className={`${btnClasses} ${key && " rounded-full bg-gray-300 h-6 w-6"}`}>
                                    {e}
                                </div>
                            )
                        }
                        if (e <= (numPages - 3)) {
                            return (
                                <div onClick={() => handleCurrentPage(e)} className={`${btnClasses} ${key && " rounded-full bg-gray-300 h-6 w-6"}`}>
                                    {e}
                                </div>
                            )
                        } else {
                            return <></>
                        }
                    })}
                    {numPages>4 && currentPage<(numPages-3) && (
                        <div className='text-[12px] md:text-[16px] tracking-widest'>
                            ...
                        </div>
                    )}
                    {numPages>=3 && (
                        <>
                            <div onClick={() => handleCurrentPage(numPages-2)} className={`${btnClasses} ${currentPage === (numPages - 2) && " rounded-full bg-gray-300 h-6 w-6"}`}>{numPages - 2}</div>

                            <div onClick={() => handleCurrentPage(numPages - 1)} className={`${btnClasses} ${currentPage === (numPages - 1) && " rounded-full bg-gray-300 h-6 w-6"}`}>{numPages - 1}</div>  

                            <div onClick={() => handleCurrentPage(numPages)} className={`${btnClasses} ${currentPage === (numPages) && " rounded-full bg-gray-300 h-6 w-6"}`}>{numPages}</div>  
                        </>
                    )}
                </>
                <img onClick={nextPage} src={nextIcon} className="cursor-pointer" alt="" height={14} width={14} />

                <div onClick={toLastPage} className={`flex justify-center items-center ml-2 cursor-pointer ${currentPage === numPages && "opacity-40"}`}>
                    <img src={nextIcon} alt="" height={14} width={14} />
                    <img src={nextIcon} alt="" height={14} width={14} />
                </div>
            </div>

            <div className='flex h-full justify-center items-center'>
                <Listbox value={recordsPerPage} onChange={setRecordsPerPage}>
                    <div className="relative mt-1">
                        <Listbox.Button className="flex items-center">
                            <span
                                className="block font-normal text-[12px] md:text-[16px]"
                            >
                                {recordsPerPage} {type}/Page
                            </span>
                            <span className="pointer-events-none">
                                <img className='h-[14px] w-[14px] ml-2' src={upIcon} alt="" />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute bottom-7 right-5 z-50 mt-1 max-h-60 w-3/4 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {options.map((opt) => (
                                    <Listbox.Option
                                        key={opt.label}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 text-center ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                            }`
                                        }
                                        value={opt.value}
                                    >
                                        <span
                                            className={`block truncate`}
                                        >
                                            {opt.label}
                                        </span>
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
        </div>
    )
}

export default Pagination