import { useContext } from 'react';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { DataContext } from '../Context/DataContext';

export const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
    }).format(value);

export const formatDecimal = (num, decimals) =>
    num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

export const getOrdinalNum = (number) => {
    let selector;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

export const formatedTimestamp = (d) => {
    const date = d.split('T')[0];
    const time = d.split('T')[1].substring(0, 5);
    return `${date} ${time}`;
};

export const formatedEmpDasboardTimestamp = (d) => {
    const date = new Date(d.split('T')[0]);
    const time = d.split('T')[1].substring(0, 5);
    return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        }/${date.getFullYear()} ${time}`;
};

export const formatedDate = (d) => {
    const date = new Date(d.split('T')[0]);
    const time = d.split('T')[1].substring(0, 5);
    return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        }/${date.getFullYear()}`;
};

export const formatedDateTimestamp = (d) => {
    var dt = new Date(d);
    return `${dt.toLocaleString('en-US', {
        weekday: 'short',
    })}, ${dt.toLocaleString('en-US', { month: 'short' })} ${getOrdinalNum(
        dt.getDate()
    )}, ${dt.getFullYear()}`;
};

export const formatedDatePicker = (d) => {
    return `${d.getFullYear()}-${d.getUTCMonth() + 1 < 10 ? '0' + (d.getUTCMonth() + 1) : d.getUTCMonth() + 1
        }-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`;
};

export async function digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join(''); // convert bytes to hex string
    return hashHex;
}

export const monthAbreviationList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const formattingRupeesSpaceBetween = (value) => {
    let formattedFigure = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        currencyDisplay: 'narrowSymbol',
    }).format(value);
    return '₹ ' + formattedFigure.split('₹')[1];
};

export const fetchAllDataFromGraphQLQuery = async ({
    query,
    variables,
    queryName,
}) => {
    let dataFetch = [];
    let potentialNextToken;
    let runListQuery = async () => {
        await API.graphql({
            query,
            variables: {
                ...variables,
                nextToken: potentialNextToken,
            },
        }).then((res) => {
            dataFetch.push(...res.data[queryName].items);
            potentialNextToken = res.data[queryName].nextToken;
        });
    };
    //Do the initial query:
    await runListQuery();
    //If a nextToken is returned: keep running the query until all data is fetched:
    while (potentialNextToken) {
        await runListQuery();
    }
    return dataFetch;
};

export const runCreateOrUpdateEmpCurrentCycleCalcs = async (
    employees,
    orgData
) => {
    console.log('hitting API to update emps calcs');
    //employees is an array of employee datasets:
    await API.post('OrgAndEmpWithdrawalCalcsAPI', '/runEmpCalcs', {
        body: { employees: employees, orgData: orgData },
    });
    // .catch((err) =>
    //   console.log('Error creating Cycle Calcs for Employee: ', err)
    // );
};

export const runCreateOrUpdateOrgCurrentCycleCalcs = async (
    orgData,
    employeeList
) => {
    //org data is singular
    await API.post('OrgAndEmpWithdrawalCalcsAPI', '/runOrgCalcs', {
        body: { orgData, employees: employeeList },
    }).then((res) => { });
    // .catch((err) => console.log('Error creating Cycle Calcs for Org: ', err));
};

export const validateEmailAddressByRegex = (email) => {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
};

export const runLogout = (
    setAppDataLoadedBoolean,
    graphQLSubscriptionLinks
) => {
    setAppDataLoadedBoolean(false);
    sessionStorage.removeItem('userId');
    // Unsubscribe from graphQL:
    if (graphQLSubscriptionLinks.length > 0) {
        console.log('unsubscribing from graphql');
        graphQLSubscriptionLinks.forEach((sub) => sub.unsubscribe());
    }
};
