import AWS from 'aws-sdk';
import devEnv from "./aws-exports-dev";

const API_KEY ="AKIA3NB2P2S7KXM224OR";
const ACCESS_KEY = "ib73lmytf5QCG4cwCJfGLPGYQWIa+SPEGpatsIqn";

AWS.config.update({
    region: devEnv["aws_project_region"],
    credentials: {
        accessKeyId: API_KEY,
        secretAccessKey: ACCESS_KEY,
    },
});

const docClient = new AWS.DynamoDB.DocumentClient();

export {docClient};
