import React, { useState } from 'react'
import SendBroadcastTable from './SendBroadcastTable';

import flag from '../../../assets/images/flag.svg';
import flagUnselected from '../../../assets/images/flag_unselected.svg';

function BroadcastModal({ openBroadcastModal, setOpenBroadcastModal }) {
    const [priority, setPriority] = useState(false);
    const [showSendTable, setShowSendTable] = useState(false);
    const [title, setTitle] = useState("");
    const [msg, setMsg] = useState("");

    const handlePriority = () => setPriority(prev => !prev);

    const handleTitleChange = (e) => {
        if (e.target.value.length <= 100) {
            setTitle(e.target.value);
        }
    }

    const handleMsgChange = (e) => {
        if (e.target.value.length <= 255) {
            setMsg(e.target.value);
        }

    }

    const inputClasses = "w-full h-[50px] rounded-lg border border-inputBorder outline-none text-base px-3";
    const textAreaClasses = "w-full rounded-lg border border-inputBorder outline-none text-base p-3";
    const sendBtnClasses = "w-[175px] h-[40px] bg-black flex justify-center items-center text-white shadow-sidebarActive rounded-lg mt-[40px] absolute md:right-[50px] right-6";

    return (
        <div className='absolute top-1/2 left-1/2 w-[90vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[8px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'>
            {showSendTable ?
                <SendBroadcastTable
                    title={title}
                    setTitle={setTitle}
                    msg={msg}
                    setMsg={setMsg}
                    setOpenBroadcastModal={setOpenBroadcastModal}
                /> : (
                    <div className='px-12 py-6'>
                        <h2 className='text-3xl'>New Broadcast</h2>

                        <div className='my-[30px]'>
                            <input onChange={handleTitleChange} id='broadcast-title' name='title' type="text" className={inputClasses} placeholder='Title' />
                            <span style={{ color: title.length <= 100 ? "#808080" : "red" }} className='text-[14px] float-right mb-4'>({title.length}/100 Characters)</span>
                        </div>

                        {/* <div className='my-[30px]'>
                        <input id='broadcast-subtitle' name='subtitle' type="text" className={inputClasses} placeholder='Subtitle' />
                        <span style={{ color: "#808080" }} className='text-[14px] float-right mb-4'>(100/100 Characters)</span>
                    </div> */}

                        <div>
                            <img onClick={handlePriority} className='absolute right-14 mt-3 z-40 cursor-pointer' src={priority ? flag : flagUnselected} alt="" />

                            <textarea onChange={handleMsgChange} placeholder='Enter Text Here' name="message" id="message" rows="6" className={textAreaClasses}></textarea>

                            <span style={{ color: msg.length <= 255 ? "#808080" : "red" }} className='text-[14px] float-right mb-4'>({msg.length}/255 Characters)</span>
                        </div>

                        <div>
                            <button onClick={() => setShowSendTable(true)} className={sendBtnClasses}>Send To</button>
                        </div>
                    </div>
                )}

        </div>
    )
}

export default BroadcastModal