import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Compact from '@uiw/react-color-compact';
import { API } from 'aws-amplify';
// import imageToBase64 from 'image-to-base64';
import InputField from '../../../sharedComponents/InputField/InputField';
import SelectMenu from '../../../sharedComponents/SelectMenu/SelectMenu';
import MerchantCategoryDropdown from './MerchantCategoryDropdown';
import { defaultBackground } from '../../../constants/DefaultBackground';
import {
  createMerchantAPI,
  updateMerchantAPI,
} from '../../../api/merchantSlice';
import FileUploadInput from '../../../sharedComponents/FileUploadInput/FileUploadInput';

function CreateMerchantModal({
  setOpenCreateModal,
  editFlag,
  setEditFlag,
  openCreateModal,
}) {
  const [merchantCat, setMerchantCat] = useState([]);
  const [logoBase64, setLogoBase64] = useState('');
  const [hexColor, setHexColor] = useState('#000');
  const dispatch = useDispatch();
  const { merchantProductCategories, currentMerchant } = useSelector(
    (state) => state.merchant
  );
  

  useEffect(() => {
    if (editFlag) {
      setHexColor(currentMerchant.backgroundCircleHexColour || '#FFFFFF');
    }
    let s = new Set();
    let temp = [];
    merchantProductCategories.map((ele) => {
      s.add(ele.categoryName);
    });
    for (const it of s) {
      temp.push({
        label: it,
        value: it,
      });
    }
    setMerchantCat(temp);
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onSubmitForm = (e) => {
    if (!openCreateModal) {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    const formElements = e.target.elements;
    let formData = {};
    let logoBase64Element = null;
    for (let i = 0; i < formElements.length; i++) {
      const ele = formElements[i];
      const name = ele.name;
      if (name.length > 0) {
        if (name === 'merchant_logo_Base64') {
          const file = ele.files[0];
          logoBase64Element = file;
        } else if (name === 'SNPLAllowedBySaathi') {
          formData[name] = ele.checked;
        } else {
          formData[name] = ele.value;
        }
      }
    }
    formData['backgroundCircleHexColour'] = hexColor;
    formData['tenant'] = 'd2c';
    if (!logoBase64Element || logoBase64Element.length === 0) {
      if (editFlag) {
        formData['ID'] = currentMerchant.id;
        if(!currentMerchant.merchant_logo_Base64 || currentMerchant.merchant_logo_Base64.length === 0) {
          formData['merchant_logo_Base64'] = defaultBackground;
        } else {
          formData['merchant_logo_Base64'] = currentMerchant.merchant_logo_Base64;
        }
        dispatch(updateMerchantAPI(formData));
      } else {
        dispatch(createMerchantAPI(formData));
      }

      setOpenCreateModal(false);

      return;
    }

    const base64File = getBase64(logoBase64Element)
      .then((result) => {
        formData['merchant_logo_Base64'] = result;
        if (editFlag) {
          formData['ID'] = currentMerchant.id;
          dispatch(updateMerchantAPI(formData));
        } else {
          dispatch(createMerchantAPI(formData));
        }
        return result;
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });

    setOpenCreateModal(false);
  };
  const typeMenuItem = [
    {
      label: 'Affiliate',
      value: 'affiliate',
    },
    {
      label: 'Merchant',
      value: 'merchant',
    },
  ];

  const offerTypeItem = [
    {
      label: 'Amount',
      value: 'amount',
    },
    {
      label: 'Percentage',
      value: 'percentage',
    },
  ];

  return (
    <div
      id='create-merchant-modal'
      className='absolute top-1/2 left-1/2 w-[80vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'
    >
      <div className='container p-3 py-6 flex flex-col items-center md:p-10'>
        <h1 className='text-[24px] mb-4'>
          {editFlag ? 'Edit Merchant' : 'Create Merchant'}
        </h1>
        <form
          onSubmit={onSubmitForm}
          className='w-full flex flex-col items-center'
        >
          <div className='grid grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4'>
            <div className='w-full'>
              <InputField
                req
                name='id'
                value={!editFlag ? '' : currentMerchant?.id}
                label='Merchant ID'
              />
            </div>
            <div className='w-full'>
              <InputField
                req
                name='merchant_name'
                value={!editFlag ? '' : currentMerchant?.merchant_name}
                label='Merchant Name'
                placeholder='Ex: Flipkart'
              />
            </div>
          </div>
          <div className='grid grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4'>
            <div className='w-full md:w-full'>
              <SelectMenu
                req
                value={editFlag && currentMerchant?.merchantType}
                name='merchantType'
                label='Type'
                options={typeMenuItem}
              />
            </div>
            <div className='w-full md:w-full'>
              <InputField
                name='platform'
                value={!editFlag ? '' : currentMerchant?.platform}
                placeholder='Ex: Cashkaro'
                label='Platform'
              />
            </div>
          </div>

          <div className='grid grid-cols-2 mt-4 w-full md:w-4/5 gap-4'>
            <div className='w-full md:w-full'>
              <FileUploadInput
                req
                inputName='merchant_logo_Base64'
                label='Logo'
              />
            </div>
            <div className='w-full md:w-full'>
              <InputField
                req
                value={!editFlag ? '' : currentMerchant?.merchant_logo_url}
                label='Logo URL'
                placeholder='https://....'
                name='merchant_logo_url'
              />
            </div>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              value={
                !editFlag ? '' : currentMerchant?.merchantBackgroundImageURL
              }
              label='Background Image'
              placeholder='https://....'
              name='merchantBackgroundImageURL'
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <label className='block mb-1 text-sm font-medium text-gray-900'>
              Merchant Description
              <span className='text-red-700'>*</span>
            </label>
            <textarea
              placeholder='Add Something nice...'
              name='merchantDescription'
              id='merchantDesc'
              cols='auto'
              rows='10'
              className='bg-white w-full border border-inputBorder shadow-sidebarActive rounded-md outline-none p-3 text-sm'
            >
              {!editFlag ? '' : currentMerchant?.merchantDescription}
            </textarea>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <MerchantCategoryDropdown
              req
              value={editFlag && currentMerchant?.merchantCategory}
              label='Merchant Category'
              options={merchantCat}
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              value={!editFlag ? '' : currentMerchant?.merchantWebsiteURL}
              name='merchantWebsiteURL'
              label='Merchant Website'
              placeholder='https://....'
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              value={!editFlag ? '' : currentMerchant?.merchant_checkout_url}
              name='merchant_checkout_url'
              label='Checkout URL'
              placeholder='https://....'
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              value={
                !editFlag
                  ? ''
                  : currentMerchant?.merchant_add_id_to_checkout_url
              }
              name='merchant_add_id_to_checkout_url'
              label='Add ID to Checkout URL'
              placeholder='https://....'
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              value={!editFlag ? '' : currentMerchant?.afiliateUrl}
              name='afiliateUrl'
              label='Afiliate URL'
              placeholder='https://....'
            />
          </div>

          <div className='grid grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4'>
            <div className='w-full md:w-full'>
              <SelectMenu
                req
                value={editFlag && currentMerchant?.merchantOfferType}
                name='merchantOfferType'
                label='Offer Type'
                options={offerTypeItem}
              />
            </div>
            <div className='w-full md:w-full'>
              <InputField
                req
                value={!editFlag ? '' : currentMerchant?.merchantOfferAmount}
                type='number'
                name='merchantOfferAmount'
                label='Offer Amount'
              />
            </div>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              name='merchantOfferTitle'
              value={
                !editFlag
                  ? currentMerchant?.merchantOfferTitle != '' ||
                    currentMerchant?.merchantOfferTitle != null
                    ? currentMerchant?.merchant_name + ' Offer'
                    : currentMerchant?.merchantOfferTitle
                  : currentMerchant?.merchant_name + ' Offer'
              }
              label='Merchant Offer Title'
              placeholder='Offer Title'
            />
          </div>

          <div className='grid grid-cols-1 mt-4 w-full md:w-4/5 gap-4'>
            <div className='w-full'>
              <InputField
                name='shopifyStoreID'
                value={!editFlag ? '' : currentMerchant?.shopifyStoreID}
                label='Shopify Store ID'
              />
            </div>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <label
              htmlFor='backgroundColor'
              className='block text-sm font-medium text-gray-700'
            >
              Background Color
              <span className='text-red-700'>*</span>
            </label>
            <div className='mt-1 w-full flex justify-center md:justify-start'>
              <Compact
                color={hexColor}
                onChange={(color) => setHexColor(color.hex)}
              />
            </div>
          </div>

          <div className='flex w-4/5 items-center h-10 mt-4'>
            <input
              className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none'
              type='checkbox'
              name='SNPLAllowedBySaathi'
              defaultChecked={editFlag && currentMerchant?.SNPLAllowedBySaathi}
              id='snblOpt'
            />
            <div className='text-sm ml-3'>Save Now Pay later enabled</div>
          </div>

          <div className='grid grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4'>
            <div className='w-full md:w-full'>
              <button
                onClick={() => setOpenCreateModal((prev) => !prev)}
                className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full font-bold flex justify-center items-center'
              >
                Cancel
              </button>
            </div>
            <div className='w-full md:w-full'>
              <button
                type='submit'
                className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full flex justify-center items-center'
              >
                {editFlag ? 'Save' : 'Add'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateMerchantModal;
