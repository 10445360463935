import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { listMerchantData } from '../../api/merchantSlice'
import Sidebar from '../../sharedComponents/Sidebar/Sidebar'
import SearchInput from '../../sharedComponents/SearchInput/SearchInput'
import AkbarTravelLogo from '../../assets/images/merchantLogo/akbarTravel.svg';
import ResponsiveNavLogo from '../../assets/images/responsiveNavLogo.svg';

import { getUserData } from '../../api/userSlice';
import { listMerchantProductCategoryAPI } from '../../api/productCategorySlice';

import Dashboard from './Dashboard/Dashboard';
import Merchant from './Merchant/Merchant';
import Product from './Product/Product';
import Articles from './Articles/Articles';
import Categories from './Categories/Categories';
import Settings from './Settings/Settings';
import Upload from '../../fileupload/Upload';
import Users from './Users/Users';
import FAQ from './FAQ/FAQ';

import './DashboardPage.scss'

function DashboardPage() {
  const dispatch = useDispatch();
  const { merchantList } = useSelector(state => state.merchant)
  const menuRef = useRef(null);
  const sidebarRef = useRef(null);
  const [active, setActive] = useState('Dashboard')
  const [content, setContent] = useState(<Dashboard />)

  useEffect(()=> {
    dispatch(getUserData("saathiadmin"));
    dispatch(listMerchantProductCategoryAPI());
    if(!merchantList || merchantList.length === 0){
      dispatch(listMerchantData());
    }
  }, [])
  
  useEffect(() => {
    switch (active) {
      case 'Dashboard':
        setContent(<Dashboard />)
        return;
      case 'Merchants':
        setContent(<Merchant />)
        return;
      case 'FAQ':
        setContent(<FAQ />)
        return;
      case 'Products':
        setContent(<Product />)
        return;
      case 'Categories':
        setContent(<Categories />)
        return;
      case 'Settings':
        setContent(<Settings />)
        return;
      case 'Articles':
        setContent(<Articles />)
        return;
      case 'Uploads':
        setContent(<Upload />)
        return;
      case 'Users':
        setContent(<Users />)
        return;
      default:
        setContent(<>Hello World</>)
        return;
    }
  }, [active])

  const handleMenuBar = () => {
    menuRef.current.classList.toggle("change");
    sidebarRef.current.classList.toggle("left-[-300px]");
  }

  const sidebarClasses = "border border-1 h-full absolute z-40 left-[-300px] w-[248px] lg:left-0 lg:w-[17vw]";

  const dashboardLayoutClasses = "border overflow-y-auto border-1 h-full absolute left-0 lg:left-[17vw] lg:w-[calc(100%_-_17vw)] w-full";

  const searchBarClasses = "w-full h-[56px] flex items-center justify-between border-b border-sidebarActive";

  return (
    <div id='admin-dashboard' className='min-h-[100vh]'>
      <div ref={sidebarRef} className={sidebarClasses}>
        <Sidebar handleMenuBar={handleMenuBar} active={active} setActive={setActive} />
      </div>
      <div id='dashboard-layout' className={dashboardLayoutClasses}>
        <div id="searchbar" className={`${searchBarClasses} ${active === 'Merchants' && " min-w-[1200px]"}`}>
          <div className="lg:hidden z-50 inline-block ml-2 cursor-pointer" onClick={handleMenuBar} ref={menuRef}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
          <SearchInput />
          {/* <img src={AkbarTravelLogo} className="mr-[42px]" alt="" /> */}
        </div>
        <div id="layout" className='w-full h-full mb-14'>
          {content}
        </div>
      </div>
    </div>
  )
}

export default DashboardPage