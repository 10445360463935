import React, { useState, Fragment, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import UpDownIcon from '../../../assets/images/updownIcon.svg'

function MerchantCategoryDropdown({ label = "", value = "", options = [], req }) {
    const [selectedOpt, setSelectedOpt] = useState("Select an Option..");
    const [activeIndex, setActiveIndex] = useState(0);
    const [showDropDown, setShowDropDown] = useState(false);

    useEffect(() => {
        if (value && value.length > 0) {
            setSelectedOpt(value);
        }
    }, [])

    const handleSelectOption = (val) => {
        setSelectedOpt(val);
        setShowDropDown(false);
    }
    return (
        <div onClick={(e)=>e.preventDefault()}>
            <label className="block mb-1 text-sm font-medium text-gray-900">
                {label}
                {req && (
                    <span className="text-red-700">
                        *
                    </span>
                )}
            </label>
            <input type="hidden" hidden="" readonly="" name="merchantCategory" value={selectedOpt} className='fixed top-[1px] left-[1px] w-[1px] h-0 p-0 -m-[1px] overflow-hidden whitespace-nowrap hidden' style={{ clip: "rect(0px, 0px, 0px, 0px)", borderWidth: "0px" }} />
            <div className='relative mt-1'>
                <button
                    onClick={() => setShowDropDown(prev => !prev)}
                    className='relative w-full cursor-default flex justify-between rounded-lg bg-white py-2 pl-3 pr-3 text-left shadow-sidebarActive focus:outline-none border border-inputBorder sm:text-sm'

                >
                    <span
                        className={`block truncate ${selectedOpt === 'Select an Option..' && "text-xs text-gray-400"}`}
                    >
                        {selectedOpt.toUpperCase()}
                    </span>
                    <span className="pointer-events-none flex items-center pr-2">
                        <img src={UpDownIcon} alt="" />
                    </span>
                </button>
                <Transition
                    show={showDropDown}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className='flex flex-col w-full mt-1 border border-inputBorder overflow-y-auto h-60 rounded-md text-base capitalize'
                    >
                        <div
                            onMouseEnter={() => setActiveIndex(0)}
                            onClick={() => handleSelectOption("Select an Option..")}
                            className={
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${activeIndex === 0 ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                }`
                            }
                        >
                            <span
                                className={`block truncate`}
                            >
                                Select an Option..
                            </span>
                        </div>
                        {options.map((opt, id) => (
                            <div
                                onMouseEnter={() => setActiveIndex(id + 1)}
                                onClick={() => handleSelectOption(opt.value)}
                                className={
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${activeIndex === id + 1 ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                    }`
                                }
                            >
                                <span
                                    className={`block truncate`}
                                >
                                    {opt.label}
                                </span>
                            </div>
                        ))}
                    </div>
                </Transition>
            </div>
        </div>
    )
}

export default MerchantCategoryDropdown