import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPICall, checkStatusCode } from './APIUtils'

const initialState = {
    merchantList: [],
    merchantProductCategories: [],
    loading: false,
    currentMerchant: {},
    error: ""
}

export const listMerchantData = createAsyncThunk('merchant/listMerchants', async (_, thunkAPI) => {
    const data = await handleAPICall("merchantApi","/merchant",{},"GET");
    if(checkStatusCode(data)){
        return data.success
    }else{
        return thunkAPI.rejectWithValue(data?.error);
    }
})

export const listMerchantProductCategoryData = createAsyncThunk('merchant/listMerchantCategory', async (_,thunkAPI) => {
    const data = await handleAPICall("merchantApi", "/merchant/product/category", {}, "GET");
    if (checkStatusCode(data)) {
        return data.success
    } else {
        return thunkAPI.rejectWithValue(data?.error);
    }
})

export const createMerchantAPI = createAsyncThunk("merchant/createMerchant", async (body, thunkAPI) => {
    try {
        const options = {
            body: body,
            isBase64Encoded: true
        }
        const data = await handleAPICall("merchantApi", "/merchant", options, "POST")
        if (checkStatusCode(data)) {
            return data.success
        } else {
            return thunkAPI.rejectWithValue(data.error || "Something went wrong!");
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateMerchantAPI = createAsyncThunk("merchant/updateMerchant", async (body, thunkAPI) => {
    try {
        const id = body["ID"];
        delete body.ID;
        const options = {
            body: body,
            isBase64Encoded: true
        }
        const data = await handleAPICall("merchantApi", `/merchant/${id}`, options, "UPDATE")

        if (data.error && Object.keys(data.error).length > 0) {
            try {
                let msg = data.error.error.response.data.error.message;
                return thunkAPI.rejectWithValue(msg);
            } catch (error) {
                return thunkAPI.rejectWithValue("Something went wrong!");
            }
        }

        return { data: data.data, ID: id };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const merchantSlice = createSlice({
    name: 'merchants',
    initialState,
    reducers: {
        saveCurrentMerchant: (state, action) => {
            state.currentMerchant = action.payload;
        },
        updateMerchantList: (state, action) => {
            state.merchantList = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(listMerchantData.pending, (state) => {
            state.loading = true;
            state.error = "";
        })
        builder.addCase(listMerchantData.fulfilled, (state, action) => {
            state.loading = false;
            state.error = "";
            state.merchantList = action.payload.data;
        })
        builder.addCase(listMerchantData.rejected, (state, action) => {
            state.loading = false;
            try {
                state.error = action.payload.error.response.data.error.message
            } catch (error) {
                state.error = "Something went Wrong"
            }
        })
        builder.addCase(listMerchantProductCategoryData.pending, (state) => {
            state.loading = true;
            state.error = "";
        })
        builder.addCase(listMerchantProductCategoryData.fulfilled, (state, action) => {
            state.loading = false;
            state.error = "";
            state.merchantProductCategories = action.payload.data;
        })
        builder.addCase(listMerchantProductCategoryData.rejected, (state, action) => {
            state.loading = false;
            try {
                state.error = action.payload.error.response.data.error.message
            } catch (error) {
                state.error = "Something went Wrong"
            }
        })
        builder.addCase(createMerchantAPI.pending, (state) => {
            state.loading = true;
            state.error = "";
        })
        builder.addCase(createMerchantAPI.fulfilled, (state, action) => {
            state.loading = false;
            state.error = "";
            state.merchantList = [...state.merchantList,action.payload?.data];
        })
        builder.addCase(createMerchantAPI.rejected, (state, action) => {
            state.loading = false;
            try {
                state.error = action.payload.error.response.data.error.message
            } catch (error) {
                state.error = "Something went Wrong"
            }
        })
        builder.addCase(updateMerchantAPI.pending, (state) => {
            state.loading = true;
            state.error = "";
        })
        builder.addCase(updateMerchantAPI.fulfilled, (state, action) => {
            state.error = "";
            let oldID = action.payload.ID;
            let data = action.payload.data;
            let oldArr = [...state.merchantList];
            for (let i = 0; i < oldArr.length; i++) {
                const element = oldArr[i];
                if (element.id === oldID){
                    oldArr[i] = data;
                    break;
                }
            }
            state.merchantList = [...oldArr];
            state.loading = false;
        })
        builder.addCase(updateMerchantAPI.rejected, (state, action) => {
            state.loading = false;
            try {
                state.error = action.payload
            } catch (error) {
                state.error = "Something went Wrong"
            }
        })
    }
})

export const { saveCurrentMerchant, updateMerchantList } = merchantSlice.actions;
export default merchantSlice.reducer