// Sidebar logo
import dashboard from '../assets/images/sidebarLogo/dashboard.svg'
import articles from '../assets/images/sidebarLogo/articles.svg'
import merchant from '../assets/images/sidebarLogo/merchant.svg'
import products from '../assets/images/sidebarLogo/products.svg'
import reports from '../assets/images/sidebarLogo/reports.svg'
import users from '../assets/images/sidebarLogo/users.svg'
import category from '../assets/images/sidebarLogo/categoryLogo.svg'
import upload from '../assets/images/sidebarLogo/upload.svg'


export const sidebarLinks = [
    {
        logo: dashboard,
        link: "Dashboard"
    },
    {
        logo: merchant,
        link: "Merchants"
    },
    {
        logo: products,
        link: "Products"
    },
    {
        logo: category,
        link: "Categories"
    },
    {
        logo: articles,
        link: "Articles"
    },
    // {
    //     logo: reports,
    //     link: "Reports"
    // },
    {
        logo: users,
        link: "Users"
    },
    {
        logo: upload,
        link: "Uploads"
    },
]